import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/app.services';
declare var idiomaEscolhido: any;

@Component({
  selector: 'app-parceiro',
  templateUrl: './parceiro.component.html',
  styleUrls: ['./parceiro.component.scss']
})
export class ParceiroComponent implements OnInit {

  dados: any = {};
  traducao: any = {};
  traducaoTermos = {
    br: {
      parceiro: 'SEJA UM PARCEIRO',
      nome: `Nome(*)`,
      name: 'Nome e sobrenome',
      escreva: 'Escreva aqui sua mensagem',
      email: 'E-Mail(*)',
      mail: 'nome@seuemail.com.br',
      tel: 'Telefone(*)',
      msg: 'Mensagem(*)',
      enviar: 'Enviar'
    },
    en: {
      parceiro: 'OMBUDSMAN',
      nome: `Name(*)`,
      name: 'Name and surname',
      escreva: 'Write your message here',
      email: 'E-Mail(*)',
      mail: 'name@youremail.com',
      tel: 'Phone(*)',
      msg: 'Message(*)',
      enviar: 'Send'
    },
    es: {
      parceiro: 'OMBUDSMAN',
      nome: `Nombre(*)`,
      name: 'Nombre y apellido',
      escreva: 'Escriba su mensaje aquí',
      email: 'E-Mail(*)',
      mail: 'nombre@suemail.com',
      tel: 'Teléfono(*)',
      msg: 'Mensaje(*)',
      enviar: 'Enviar'
    },
    all: {
      parceiro: 'SEJA UM PARCEIRO',
      nome: `Nome(*)`,
      name: 'Nome e sobrenome',
      escreva: 'Escreva aqui sua mensagem',
      email: 'E-Mail(*)',
      mail: 'nome@seuemail.com.br',
      tel: 'Telefone(*)',
      msg: 'Mensagem(*)',
      enviar: 'Enviar'
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
  }

  enviar() {
    let error = 0;
    // tslint:disable-next-line:triple-equals
    if (String(this.dados.nome || '').trim() == '') {
      error++;
    }

    // tslint:disable-next-line:triple-equals
    if (String(this.dados.email || '').trim() == '') {
      error++;
    }

    // tslint:disable-next-line:triple-equals
    if (String(this.dados.telefone || '').trim() == '') {
      error++;
    }

    // tslint:disable-next-line:triple-equals
    if (String(this.dados.mensagem || '').trim() == '') {
      error++;
    }

    if (error > 0) {
      alert('Complete todos os campos para continuar');
      return false;
    }

    let html = '';
    html += '<b>Nome:</b> ' + this.dados.nome + '<br>';
    html += '<b>E-mail:</b> ' + this.dados.email + '<br>';
    html += '<b>Telefone:</b> ' + this.dados.telefone + '<br>';
    html += '<b>Mensagem:</b><br> ' + this.dados.mensagem;

    const apiMensagem = new Api('', '');
    apiMensagem.post({
      mensagem: html,
      tipo_form: 'parceiro'
    }).finally(() => {
      alert('Mensagem enviada com sucesso!');
      this.dados = {};
    });
  }
}
