import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-comercializacao',
  templateUrl: './comercializacao.component.html',
  styleUrls: ['./comercializacao.component.scss']
})
export class ComercializacaoComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      comercializacao: '<small>COMERCIALIZAÇÃO ENERGIA</small>',
      boreal: 'Boreal Energia',
      aBoreal: `
      A BOREAL ENERGIA atua com foco em operações de trading de energia, com uma vasta gama de
      produtos e opções, buscando sempre o respeito e a reciprocidade a cada negócio realizado com
      seus parceiros.
      A empresa conta com profissionais com mais de 20 anos de experiência no mercado de
      comercialização, e tem como um de seus pilares a constante capacitação de seus
      colaboradores.
      Os profissionais da mesa de operação da BOREAL ENERGIA possuem qualificação nas áreas de
      pricing, risk, e trading, com foco em projeções diárias de preço, além de análises
      estatísticas sobre os principais dados que impactam o mercado de energia no país.


      <br>Saiba mais, acesse o site:
      <a href="http://www.borealenergia.com.br" class="link-louco" target="_blank">
          www.<span>borealenergia</span>.com.br
      </a>
      `,
      vektor: 'Vektor Energia',
      aVektor: `
      A empresa Vektor Energia faz parte do Grupo Ascensus e oferece uma linha completa de
      produtos e serviços voltados ao Mercado Livre de Energia Elétrica, integrando soluções e
      conectando-se aos clientes para oferecer as melhores oportunidades de operação e
      precificação no mercado de energia. Atua orientada sobre três vetores: Resultado,
      Transparência e Proximidade.

      <br><br>
      ● Profissionais com mais de 20 anos no mercado.
      <br>
      ● Mais de 5 milhões MWH negociados (equivalente à uma cidade com mais de 3 milhões de
      habitantes).
      <br>
      ● Possui mais de 150 clientes migrados para o mercado livre.

      <br>Saiba mais, acesse o site:

      <a href="http://www.vektorenergia.com.br" class="link-louco" target="_blank">
          www.<span>vektorenergia</span>.com.br
      </a>
      `
    },
    en: {
      comercializacao: '<small>ENERGY MARKETING</small>',
      boreal: 'Boreal Energia',
      aBoreal: `
      BOREAL ENERGIA operates with a focus on energy trading operations, with a wide range of
      products and options, always seeking respect and reciprocity for each business carried out with
      your partners.
      The company has professionals with more than 20 years of experience in the
      commercialization, and one of its pillars is the constant training of its
      collaborators.
      The professionals at the BOREAL ENERGIA operating table are qualified in the areas of
      pricing, risk, and trading, with a focus on daily price projections, in addition to analysis
      statistics on the main data that impact the energy market in the country.


      <br>Learn more, visit the website:
      <a href="http://www.borealenergia.com.br" class="link-louco" target="_blank">
          www.<span>borealenergia</span>.com.br
      </a>
      `,
      vektor: 'Vektor Energia',
      aVektor: `
      Vektor Energia is part of the Ascensus Group and offers a complete line of
       products and services aimed at the Free Electricity Market, integrating solutions and
       connecting with customers to offer the best operating opportunities and
       pricing in the energy market. It acts on three vectors: Result,
       Transparency and Proximity.

      <br><br>
      ● Professionals with more than 20 years in the market.
      <br>
      ● More than 5 million MWH negotiated (equivalent to a city with more than 3 million
        population).
      <br>
      ● It has more than 150 customers migrated to the free market.

      <br>Learn more, visit the website:

      <a href="http://www.vektorenergia.com.br" class="link-louco" target="_blank">
          www.<span>vektorenergia</span>.com.br
      </a>
      `
    },
    es: {
      comercializacao: '<small>COMERCIALIZACIÓN DE ENERGÍA</small>',
      boreal: 'Boreal Energia',
      aBoreal: `
      BOREAL ENERGIA opera con un enfoque en operaciones de comercialización de energía, con una amplia gama de
       productos y opciones, buscando siempre el respeto y la reciprocidad para cada negocio que se realiza con
       tus socios.
       La empresa cuenta con profesionales con más de 20 años de experiencia en el
       comercialización, y uno de sus pilares es la formación constante de sus
       colaboradores.
       Los profesionales de la mesa de operaciones BOREAL ENERGIA están cualificados en las áreas de
       precios, riesgo y negociación, con un enfoque en las proyecciones de precios diarias, además del análisis
       estadísticas sobre los principales datos que impactan el mercado energético en el país.

      <br>Obtenga más información, visite el sitio web:
      <a href="http://www.borealenergia.com.br" class="link-louco" target="_blank">
          www.<span>borealenergia</span>.com.br
      </a>
      `,
      vektor: 'Vektor Energia',
      aVektor: `
      Vektor Energia es parte del Ascensus Group y ofrece una línea completa de
       productos y servicios orientados al Mercado Eléctrico Libre, integrando soluciones y
       conectar con los clientes para ofrecer las mejores oportunidades operativas y
       fijación de precios en el mercado energético. Actúa sobre tres vectores: Resultado,
       Transparencia y Proximidad.

      <br><br>
      ● Profesionales con más de 20 años en el mercado.
      <br>
      ● Más de 5 millones de MWH negociados (equivalente a una ciudad con más de 3 millones
        población).
      <br>
      ● Tiene más de 150 clientes migrados al mercado libre.

      <br>Obtenga más información, visite el sitio web:

      <a href="http://www.vektorenergia.com.br" class="link-louco" target="_blank">
          www.<span>vektorenergia</span>.com.br
      </a>
      `
    },
    all: {
      comercializacao: '<small>COMERCIALIZAÇÃO ENERGIA</small>',
      boreal: 'Boreal Energia',
      aBoreal: `
      A BOREAL ENERGIA atua com foco em operações de trading de energia, com uma vasta gama de
      produtos e opções, buscando sempre o respeito e a reciprocidade a cada negócio realizado com
      seus parceiros.
      A empresa conta com profissionais com mais de 20 anos de experiência no mercado de
      comercialização, e tem como um de seus pilares a constante capacitação de seus
      colaboradores.
      Os profissionais da mesa de operação da BOREAL ENERGIA possuem qualificação nas áreas de
      pricing, risk, e trading, com foco em projeções diárias de preço, além de análises
      estatísticas sobre os principais dados que impactam o mercado de energia no país.


      <br>Saiba mais, acesse o site:
      <a href="http://www.borealenergia.com.br" class="link-louco" target="_blank">
          www.<span>borealenergia</span>.com.br
      </a>
      `,
      vektor: 'Vektor Energia',
      aVektor: `
      A empresa Vektor Energia faz parte do Grupo Ascensus e oferece uma linha completa de
      produtos e serviços voltados ao Mercado Livre de Energia Elétrica, integrando soluções e
      conectando-se aos clientes para oferecer as melhores oportunidades de operação e
      precificação no mercado de energia. Atua orientada sobre três vetores: Resultado,
      Transparência e Proximidade.

      <br><br>
      ● Profissionais com mais de 20 anos no mercado.
      <br>
      ● Mais de 5 milhões MWH negociados (equivalente à uma cidade com mais de 3 milhões de
      habitantes).
      <br>
      ● Possui mais de 150 clientes migrados para o mercado livre.

      <br>Saiba mais, acesse o site:

      <a href="http://www.vektorenergia.com.br" class="link-louco" target="_blank">
          www.<span>vektorenergia</span>.com.br
      </a>
      `
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }


  ngOnInit() {
  }

  abrirDetalhe() {
    $('.comercializacao-title').toggleClass('active-rotate');
    $('.acordeon-comercializacao').animate({height: 'toggle'});
    this.scroll('#comercializacao');
  }

  fecharDetalhe() {
    $('.comercializacao-title').removeClass('active-rotate');
    $('.acordeon-comercializacao').slideUp();
    this.scroll('#comercializacao');
  }

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }
}
