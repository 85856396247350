import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
declare var tokenAcesso, ambiente, httpGlobal, $, loadingShow, loadingGlobal, loadingObj, alertGlobal, listaUrlApi, listaUrlLoginApi: any;

export class Api {
    
    public urlApi: any = listaUrlApi;
    public urlLoginApi: any = listaUrlLoginApi;

    //Ignorar
    public urlAccountApi: any = {
        dev: 'https://homologacao.ascensus.com.br/',
        hmg: '',
        prod: ''
    }

    //Ignorar
    public urlRememberPassword: any = {
        dev: '',
        hmg: '',
        prod: ''
    }

    public ClientId = '';
    public ClientSecret = '';
    public Scope = 'ssosa';
    public filter: any = {};
    public isPagination = false;
    public token: string;
    public timeoutReq = 6000000;
    public ambiente: string;
    public http: HttpClient;
    public tokenName: string;
    public semLoading = false;
    numSolicitacao = 0;
    constructor(private apiName: string, private appName: string) {
        this.http = httpGlobal;
        this.isPagination = false;
        switch (appName) {
            case 'ascensus':
                this.tokenName = '';
                this.ClientId = '';
                break;
        }
        try {
            tokenAcesso = localStorage.getItem(this.tokenName) || null;
        } catch (e) {
        }
        if (tokenAcesso != null) {
            this.token = tokenAcesso;
            tokenAcesso = tokenAcesso;
        }
        this.ambiente = localStorage.getItem('ambiente');
        if (String(this.ambiente || '') === '') {
            this.ambiente = ambiente;
            localStorage.setItem('ambiente', ambiente);
        }
    }
    public get(): Promise<any> {
        const _this = this;
        const params = new HttpParams({ fromObject: this.filter });
        this.filter = {};

        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tokenAcesso
            }),
            params
        }
        this.mostrarLoading();
        return _this.http.get<any>(_this.urlApi[ambiente] + _this.apiName, httpOptions)
            .toPromise()
            .then((ret: any) => _this.extractData(ret, _this))
            .catch(ret => _this.handleError(ret, _this));
    }

    public post(dados: any, ehLogin?: boolean): Promise<any> {
        return this.http.post<any>('https://ascensus.com.br/sendmail.php', dados)
            .toPromise()
            .then(ret => this.extractData(ret, this))
            .catch(ret => this.handleError({
                pularErro: true
            }, this));
    }

    public put(dados: any): Promise<any> {
        return null;
    }

    public putAccount(dados: any): Promise<any> {
        return null;
    }

    public delete(): Promise<any> {
        return null;
    }

    public googleVision(content) {
        this.mostrarLoading();
        const data = {
            requests: [
                {
                    image: {
                        content: content
                    },
                    features: [
                        {
                            type: 'TEXT_DETECTION',
                            maxResults: 200
                        }
                    ]
                }
            ]
        };
        return this.http.post<any>('https://vision.googleapis.com/v1/images:annotate?key=AIzaSyAANMMwYVQqATZCz2clYzUKs270JMka-iY', data)
            .toPromise()
            .then(ret => this.extractData(ret, this))
            .catch(ret => this.handleError({
                pularErro: true
            }, this));
    }

    public viaCep(cep) {
        return this.http.get<any>('https://viacep.com.br/ws/'+ cep + '/json/')
            .toPromise()
            .then(ret => this.extractData(ret, this))
            .catch(ret => this.handleError({
                pularErro: true
            }, this));
    }

    public geometry(end) {
        // tslint:disable-next-line:max-line-length
        return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + end + '&key=AIzaSyAgUNJr95BD-YOt3tp09w_7IFHqI3nt3W8')
            .toPromise()
            .then(res => {
                let ret: any;
                ret = res;
                return ret.results[0].geometry.location;
            })
            .catch(error => {
                return error;
            });
    }

    private extractData(res: any, _this: any) {
        _this.escondeLoading();
        let body = { data: null, dataList: null };
        try {
            body = res;
        } catch (e) { }
        if (_this !== undefined && _this != null) {
            if (_this.isPagination) {
                this.isPagination = false;
                return body;
            }
        }
        const response = body.data || body.dataList || body || {};
        return response;
    }
    private handleError(error: any, _this: any, ehLogin?: boolean) {
        _this.escondeLoading();
        error = error || {};
        if (error.pularErro) {
            return null;
        } else {
            if (!error.json) {
                
            }
            if (error.status === 401) {

                localStorage.removeItem(this.tokenName);
                localStorage.removeItem('isAuth');
                window.location.reload();

            } else if (error.status === 500) {
                let errorRet = error.json ? error.json() : error;
                if(!ehLogin)
                    this.monstrarErro(error);
            } 
            error = error.json ? error.json() : error.error;
        }
        return Promise.reject(error);
    }

    async monstrarErro(error) {
        if (error.error.result && error.error.result.errors.length > 0) {

        }
    }

    async mostrarLoading() {
        if (!this.semLoading) {
            loadingShow++;

        }
    }
    async escondeLoading() {
        if (!this.semLoading) {
            loadingShow--;
            if (loadingShow <= 0) {
                loadingShow = 0;

            }
        }
    }
    pegarURL() {
        return this.urlApi[ambiente] + this.apiName;
    }

    pegarLembrarSenhaURL() {
        return this.urlRememberPassword[ambiente] + this.apiName;
    }
}
