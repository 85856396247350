import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-logistica',
  templateUrl: './logistica.component.html',
  styleUrls: ['./logistica.component.scss']
})
export class LogisticaComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      ascensus: 'ASCENSUS <small>LOGÍSTICA</small>',
      vertical: `
      A vertical Logística oferece soluções voltadas para o gerenciamento da cadeia logística, com
      serviços rápidos, precisos e de qualidade. Atua de maneira orientada em compreender o desejo
      do cliente para suprir as necessidades no segmento de operações logísticas de movimentação,
      armazenagem e transporte, com as seguintes soluções:<br><br>

      • Paletização e picking – Também conhecido como “order picking”. Consiste em receber
      a mercadoria solta, fazer a montagem do pallet através de separação por SKU e fixar a carga
      sob o pallet com filme stretch, operação interna no armazém geral.<br><br>

      • Embalagem e etiquetagem – A finalidade principal de embalar e etiquetar na
      logística é assegurar a integridade física do produto. Assim, a mercadoria chega em
      perfeitas condições ao consumidor final, e, em uma única operação, diferentes tipos,
      tamanhos e formatos de embalagem podem ser usados conforme a necessidade, seja na entrada ou
      saída da mercadoria, operação interna no armazém geral.<br><br>

      • Procedimentos Documentais Entrada e Saída Mercadoria – Controle e emissão de documentos de
      acordo com normas e procedimentos internos.<br><br>

      • Armazenagem – Serviço que promove a estocagem ordenada das mercadorias. O uso do armazém
      da Ascensus Logística permite que menos recursos sejam gastos para tentar converter
      problemas em soluções, principalmente quando o armazém do cliente não consegue mais receber
      materiais ou não conta com a especificação necessária para estocar um produto.<br><br>

      • Cross-docking – Procedimento que dispensa espaços de armazenamento de grandes quantidades
      de produtos, que ainda não foram solicitados pelo cliente.<br><br>

      • Montagem de kits – Ao contratar um operador logístico, a empresa poderá otimizar todo o
      processo de montagem de kits (separação, identificação, embalagem e encaixotamento) com o
      objetivo de reduzir o tempo em que esta tarefa é concluída.<br><br>

      • Controle de estoque – Excelente nível de serviço ao cliente, disponibilizando e oferecendo
      o suporte necessário (inventário das mercadorias) para a realização das vendas.<br><br>

      • Gestão de transporte – Atua na gestão na área de transportes o qual tem parceiros em comum
      para qualquer tipo de transporte, seja ela marítima, terrestre ou aérea. Conta com equipe
      treinada e especializada na gestão dos processos, o qual busca praticar as melhores práticas
      e tabelas de fretes junto aos parceiros/clientes.<br><br>

      • Desenvolvimento de projetos com parceiros – Projetos customizados de acordo com a
      necessidade dos clientes.`
    },
    en: {
      ascensus: 'ASCENSUS <small>LOGISTICS</small>',
      vertical: `
      Vertical Logística offers solutions focused on the management of the logistics chain, with
       fast, accurate and quality services. Acts in an oriented way to understand the desire
       to meet the needs in the segment of logistics handling operations,
       storage and transportation, with the following solutions:<br><br>

      • Palletizing and picking - Also known as “order picking”. It consists of receiving
      loose goods, assemble the pallet through SKU separation and secure the load
      under the pallet with stretch film, internal operation in the general store.<br><br>

      • Packaging and labeling - The primary purpose of packaging and labeling
      logistics is to ensure the physical integrity of the product. So, the merchandise arrives in
      perfect conditions to the final consumer, and, in a single operation, different types,
      packaging sizes and formats can be used as needed, either at the entrance or
      goods leaving, internal operation in the general warehouse.<br><br>

      • Documentary Procedures Entry and Exit of Merchandise - Control and issuance of documents of
      accordance with internal rules and procedures.<br><br>

      • Storage - Service that promotes the orderly storage of goods. The use of the warehouse
      Ascensus Logística allows less resources to be spent trying to convert
      solution problems, especially when the customer's warehouse is no longer able to receive
      materials or does not have the specification necessary to stock a product.<br><br>

      • Cross-docking - Procedure that does not require large amounts of storage space
      of products, which have not yet been requested by the customer.<br><br>

      • Kit assembly - When hiring a logistics operator, the company will be able to optimize the entire
      kit assembly process (separation, identification, packaging and boxing) with the
      aim of reducing the time this task is completed.<br><br>

      • Inventory control - Excellent level of customer service, providing and offering
      the necessary support (inventory of goods) to make sales.<br><br>

      • Transport management - Operates in the management of transport, which has common partners
      for any type of transport, be it sea, land or air. Staff
      trained and specialized in process management, which seeks to practice best practices
      and freight tables with partners / customers.<br><br>

      • Development of projects with partners - Customized projects according to the
      customers' needs.`
    },
    es: {
      ascensus: 'ASCENSUS <small>LOGÍSTICA</small>',
      vertical: `
      Vertical Logística ofrece soluciones enfocadas a la gestión de la cadena logística, con
       servicios rápidos, precisos y de calidad. Actúa de forma orientada a comprender el deseo
       para satisfacer las necesidades en el segmento de operaciones de manipulación logística,
       almacenamiento y transporte, con las siguientes soluciones:<br><br>

      • Paletizado y preparación de pedidos: también conocido como “preparación de pedidos”. Consiste en recibir
      mercancías sueltas, ensamblar el palet mediante separación de SKU y asegurar la carga
      debajo del palet con film estirable, funcionamiento interno en el almacén general.<br><br>

      • Envasado y etiquetado: el objetivo principal del envasado y etiquetado.
      La logística es garantizar la integridad física del producto. Entonces, la mercancía llega a
      perfectas condiciones para el consumidor final, y, en una sola operación, diferentes tipos,
      Los tamaños y formatos de empaque se pueden usar según sea necesario, ya sea en la entrada o
      salida de mercadería, operación interna en el almacén general.<br><br>

      • Trámites Documentales Entrada y Salida de Mercancía - Control y emisión de documentos de
      de acuerdo con las normas y procedimientos internos.<br><br>

      • Almacenamiento - Servicio que promueve el almacenamiento ordenado de mercancías. El uso del almacén
      Ascensus Logística permite gastar menos recursos tratando de convertir
      problemas de solución, especialmente cuando el almacén del cliente ya no puede recibir
      materiales o no tiene la especificación necesaria para almacenar un producto.<br><br>

      • Cross-docking: procedimiento que no requiere grandes cantidades de espacio de almacenamiento
      de productos, que aún no han sido solicitados por el cliente.<br><br>

      • Montaje del kit: al contratar un operador logístico, la empresa podrá optimizar todo el
      proceso de montaje del kit (separación, identificación, envasado y empaquetado) con el
      objetivo de reducir el tiempo de realización de esta tarea.<br><br>

      • Control de inventario: excelente nivel de servicio al cliente, proporcionando y ofreciendo
      el soporte necesario (inventario de bienes) para realizar ventas.<br><br>

      • Gestión del transporte: opera en la gestión del transporte, que tiene socios comunes
      para cualquier tipo de transporte, sea marítimo, terrestre o aéreo. Personal
      capacitado y especializado en la gestión de procesos, que busca practicar las mejores prácticas
      y tablas de fletes con socios / clientes.<br><br>

      • Desarrollo de proyectos con socios - Proyectos a medida según el
      necesidades de los clientes.`
    },
    all: {
      ascensus: 'ASCENSUS <small>LOGÍSTICA</small>',
      vertical: `
      A vertical Logística oferece soluções voltadas para o gerenciamento da cadeia logística, com
      serviços rápidos, precisos e de qualidade. Atua de maneira orientada em compreender o desejo
      do cliente para suprir as necessidades no segmento de operações logísticas de movimentação,
      armazenagem e transporte, com as seguintes soluções:<br><br>

      • Paletização e picking – Também conhecido como “order picking”. Consiste em receber
      a mercadoria solta, fazer a montagem do pallet através de separação por SKU e fixar a carga
      sob o pallet com filme stretch, operação interna no armazém geral.<br><br>

      • Embalagem e etiquetagem – A finalidade principal de embalar e etiquetar na
      logística é assegurar a integridade física do produto. Assim, a mercadoria chega em
      perfeitas condições ao consumidor final, e, em uma única operação, diferentes tipos,
      tamanhos e formatos de embalagem podem ser usados conforme a necessidade, seja na entrada ou
      saída da mercadoria, operação interna no armazém geral.<br><br>

      • Procedimentos Documentais Entrada e Saída Mercadoria – Controle e emissão de documentos de
      acordo com normas e procedimentos internos.<br><br>

      • Armazenagem – Serviço que promove a estocagem ordenada das mercadorias. O uso do armazém
      da Ascensus Logística permite que menos recursos sejam gastos para tentar converter
      problemas em soluções, principalmente quando o armazém do cliente não consegue mais receber
      materiais ou não conta com a especificação necessária para estocar um produto.<br><br>

      • Cross-docking – Procedimento que dispensa espaços de armazenamento de grandes quantidades
      de produtos, que ainda não foram solicitados pelo cliente.<br><br>

      • Montagem de kits – Ao contratar um operador logístico, a empresa poderá otimizar todo o
      processo de montagem de kits (separação, identificação, embalagem e encaixotamento) com o
      objetivo de reduzir o tempo em que esta tarefa é concluída.<br><br>

      • Controle de estoque – Excelente nível de serviço ao cliente, disponibilizando e oferecendo
      o suporte necessário (inventário das mercadorias) para a realização das vendas.<br><br>

      • Gestão de transporte – Atua na gestão na área de transportes o qual tem parceiros em comum
      para qualquer tipo de transporte, seja ela marítima, terrestre ou aérea. Conta com equipe
      treinada e especializada na gestão dos processos, o qual busca praticar as melhores práticas
      e tabelas de fretes junto aos parceiros/clientes.<br><br>

      • Desenvolvimento de projetos com parceiros – Projetos customizados de acordo com a
      necessidade dos clientes.`
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }


  ngOnInit() {
  }

  abrirDetalhe() {
    $('.logistica-title').toggleClass('active-rotate');
    $('.acordeon-logistica').animate({height: 'toggle'});
    this.scroll('#logistica');
  }

  fecharDetalhe() {
    $('.logistica-title').removeClass('active-rotate');
    $('.acordeon-logistica').slideUp();
    this.scroll('#logistica');
  }

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }
}
