import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-comex-distribution',
  templateUrl: './comex-distribution.component.html',
  styleUrls: ['./comex-distribution.component.scss']
})
export class ComexDistributionComponent implements OnInit {

  traducao: any = {};
  tituloModal = '';
  textoModal = '';

  traducaoTermos = {
    br: {
      ascensus: 'Ascensus <small>Importação e Exportação</small>',
      grupo: `
      O Grupo Ascensus se posiciona no mercado vendendo valor e não preço, priorizando sempre os
      melhores resultados para o cliente. O Grupo Ascensus gerencia toda a cadeia de comércio
      internacional por meio da prestação de serviços personalizados. O foco está no processo, e
      não no produto. Deste modo, dispõe de uma gama de serviços visando atender o cliente e
      reduzir custos.`,
      importacao: `
      • Importação por Conta e Ordem de Terceiros.
      <br><br>
      • Importação por encomenda.
      <br><br>
      • Financiamento de impostos e nacionalização.
      <br><br>
      • Distribuição – A distribuição de produtos tem como foco a redução de custos diretos para a
      empresa detentora da marca/produto. Reduzindo seus custos, a empresa pode aumentar
      significativamente a margem de lucro nas suas operações. Este produto tem como
      característica a aplicação de todos os benefícios que o Grupo pode oferecer, pois a
      modelagem do processo de importação e distribuição pode ser feita de várias formas,
      atendendo necessidades estruturais e operacionais do cliente.
      <br><br>
      • Licenças – A Ascensus Trading possui uma equipe técnica com larga experiência nas mais
      diversas anuências de importação, que podem ter como órgãos anuentes: IBAMA; ANVISA; ANP;
      MAPA; Ministério do Exército; Polícia Civil e outros.
      <br><br>
      • Habilitação/revisão de estimativa no RADAR – Contamos também com uma equipe técnica com
      experiência em habilitação e revisão de estimativa no Radar, pois antes de protocolar o
      processo na Receita Federal Brasileira, analisamos minuciosamente os documentos e capacidade
      financeira e operacional do cliente. Prestamos a consultoria com o foco de criar uma
      estratégia em como realizar a revisão/habilitação, mediante análise de prazos e documentos.`,
      links1: 'FORNECEDOR',
      links2: 'PORTOS',
      links3: 'ARMAZÉM ALFANDEGADO',
      links4: 'DESEMBARAÇO',
      links5: 'TRADING',
      links6: 'ARMAZENAGEM',
      links7: 'AGENCIAMENTO DE CARGAS',
      links8: 'DISTRIBUIÇÃO',
    },
    en: {
      ascensus: 'Ascensus <small>Import and Export</small>',
      grupo: `
      The Ascensus Group positions itself in the market selling value, not price, always prioritizing
       better results for the client. The Ascensus Group manages the entire trade chain
       through the provision of personalized services. The focus is on the process, and
       not in the product. In this way, it has a range of services aimed at serving the customer and
       reduce costs.`,
      importacao: `
      • Import on behalf of third parties.
      <br><br>
      • Custom import.
      <br><br>
      • Financing of taxes and nationalization.
      <br><br>
      • Distribution - Product distribution focuses on reducing direct costs for the
      company that owns the brand / product. By reducing its costs, the company can increase
      the profit margin in its operations significantly. This product
      feature the application of all the benefits that the Group can offer, since the
      modeling of the import and distribution process can be done in several ways,
      meeting the client's structural and operational needs.
      <br><br>
      • Licenses - Ascensus Trading has a technical team with extensive experience in the most
      various import permits, which may have the following consenting bodies: IBAMA; ANVISA; ANP;
      MAP; Army Ministry; Civil Police and others.
      <br><br>
      • Qualification / revision of estimate in RADAR - We also have a technical team with
      experience in qualifying and revising estimates on Radar, because before filing the
      process at the Brazilian Federal Revenue Service, we thoroughly analyze the documents and
      customer's financial and operational We provide consultancy with a focus on creating a
      strategy on how to carry out the review / qualification, by analyzing deadlines and documents.`,
      links1: 'PROVIDER',
      links2: 'PORTS',
      links3: 'CUSTOMS WAREHOUSE',
      links4: 'CLEARANCE',
      links5: 'TRADING',
      links6: 'STORAGE',
      links7: 'LOADING AGENCY',
      links8: 'DISTRIBUTION',
    },
    es: {
      ascensus: 'Ascensus <small>Importación y exportación</small>',
      grupo: `
      El Grupo Ascensus se posiciona en el valor de venta de mercado, no en el precio, priorizando siempre
       mejores resultados para el cliente. Ascensus Group gestiona toda la cadena comercial
       mediante la prestación de servicios personalizados. La atención se centra en el proceso y
       no en el producto. De esta forma, cuenta con una gama de servicios orientados a servir al cliente y
       reducir costos.`,
      importacao: `
      • Importar por cuenta de terceros.
      <br><br>
      • Importación personalizada.
      <br><br>
      • Financiamiento de impuestos y nacionalización.
      <br><br>
      • Distribución: la distribución de productos se centra en reducir los costos directos de la
      empresa propietaria de la marca / producto. Al reducir sus costos, la empresa puede aumentar
      el margen de beneficio en sus operaciones de manera significativa. Este producto
      caracterizar la aplicación de todos los beneficios que el Grupo puede ofrecer, ya que
      El modelado del proceso de importación y distribución se puede realizar de varias formas,
      satisfaciendo las necesidades estructurales y operativas del cliente.
      <br><br>
      • Licencias - Ascensus Trading cuenta con un equipo técnico con amplia experiencia en la mayoría
      varios permisos de importación, que pueden tener los siguientes órganos de consentimiento: IBAMA; ANVISA; ANP;
      MAPA; Ministerio del Ejército; Policía Civil y otros.
      <br><br>
      • Calificación / revisión de presupuesto en RADAR - También contamos con un equipo técnico con
      experiencia en calificar y revisar estimaciones en Radar, porque antes de presentar la
      proceso en el Servicio Federal de Ingresos de Brasil, analizamos a fondo los documentos y
      financiera y operativa del cliente Brindamos consultoría con un enfoque en la creación de un
      estrategia sobre cómo realizar la revisión / calificación, mediante el análisis de plazos y documentos.`,
      links1: 'PROVEEDOR',
      links2: 'PUERTOS',
      links3: 'DEPÓSITO ADUANERO',
      links4: 'DESPEJE',
      links5: 'TRADING',
      links6: 'ALMACENAMIENTO',
      links7: 'AGENCIA DE CARGA',
      links8: 'DISTRIBUCIÓN',
    },
    all: {
      ascensus: 'Ascensus <small>IMPORTAÇÃO E EXPORTAÇÃO</small>',
      grupo: `
      O Grupo Ascensus se posiciona no mercado vendendo valor e não preço, priorizando sempre os
      melhores resultados para o cliente. O Grupo Ascensus gerencia toda a cadeia de comércio
      internacional por meio da prestação de serviços personalizados. O foco está no processo, e
      não no produto. Deste modo, dispõe de uma gama de serviços visando atender o cliente e
      reduzir custos.`,
      importacao: `
      • Importação por Conta e Ordem de Terceiros
      <br><br>
      • Importação por encomenda.
      <br><br>
      • Financiamento de impostos e nacionalização.
      <br><br>
      • Distribuição – A distribuição de produtos tem como foco a redução de custos diretos para a
      empresa detentora da marca/produto. Reduzindo seus custos, a empresa pode aumentar
      significativamente a margem de lucro nas suas operações. Este produto tem como
      característica a aplicação de todos os benefícios que o Grupo pode oferecer, pois a
      modelagem do processo de importação e distribuição pode ser feita de várias formas,
      atendendo necessidades estruturais e operacionais do cliente.
      <br><br>
      • Licenças – A Ascensus Trading possui uma equipe técnica com larga experiência nas mais
      diversas anuências de importação, que podem ter como órgãos anuentes: IBAMA; ANVISA; ANP;
      MAPA; Ministério do Exército; Polícia Civil e outros.
      <br><br>
      • Habilitação/revisão de estimativa no RADAR – Contamos também com uma equipe técnica com
      experiência em habilitação e revisão de estimativa no Radar, pois antes de protocolar o
      processo na Receita Federal Brasileira, analisamos minuciosamente os documentos e capacidade
      financeira e operacional do cliente. Prestamos a consultoria com o foco de criar uma
      estratégia em como realizar a revisão/habilitação, mediante análise de prazos e documentos.`,
      links1: 'FORNECEDOR',
      links2: 'PORTOS',
      links3: 'ARMAZÉM ALFANDEGADO',
      links4: 'DESEMBARAÇO',
      links5: 'TRADING',
      links6: 'ARMAZENAGEM',
      links7: 'AGENCIAMENTO DE CARGAS',
      links8: 'DISTRIBUIÇÃO',
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
  }

  abrirDetalhe() {
    $('.distribuicao-title').toggleClass('active-rotate');
    $('.acordeon-comex').animate({height: 'toggle'});
    this.scroll('#comex');
  }

  fecharDetalhe() {
    $('.distribuicao-title').removeClass('active-rotate');
    $('.acordeon-comex').slideUp();
    this.scroll('#comex');
  }

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }

  abrirModalLink(tipo) {

    switch (tipo) {
      case 1:
        switch (idiomaEscolhido) {
          case 'br':
            this.tituloModal = 'FORNECEDOR';
            this.textoModal = 'Criamos uma estrutura capaz de coletar produtos em todos os fornecedores internacionais. Temos uma ótima avaliação em todo mercado internacional.';
            break;
          case 'en':
            this.tituloModal = 'PROVIDER';
            this.textoModal = 'We have created a structure capable of collecting products from all international suppliers. We have a great evaluation in every international market.';
            break;
          case 'es':
            this.tituloModal = 'PROVEDOR';
            this.textoModal = 'Hemos creado una estructura capaz de recolectar productos de todos los proveedores internacionales. Tenemos una gran evaluación en todos los mercados internacionales.';
            break;
          default:
            this.tituloModal = 'FORNECEDOR';
            this.textoModal = 'Criamos uma estrutura capaz de coletar produtos em todos os fornecedores internacionais. Temos uma ótima avaliação em todo mercado internacional.';
            break;
        }
        break;
      case 2:
        switch (idiomaEscolhido) {
          case 'br':
            this.tituloModal = 'PORTOS';
            this.textoModal = 'Parcerias com os principais portos do país, com equipe dedicada nas atividades de liberação da mercadoria.';
            break;
          case 'en':
            this.tituloModal = 'PORTS';
            this.textoModal = 'Partnerships with the main ports in the country, with a team dedicated to the activities of releasing the goods.';
            break;
          case 'es':
            this.tituloModal = 'PUERTOS';
            this.textoModal = 'Alianzas con los principales puertos del país, con un equipo dedicado a las actividades de despacho de mercancías.';
            break;
          default:
            this.tituloModal = 'PORTOS';
            this.textoModal = 'Parcerias com os principais portos do país, com equipe dedicada nas atividades de liberação da mercadoria.';
            break;
        }
        break;
      case 3:
        switch (idiomaEscolhido) {
          case 'br':
            this.tituloModal = 'AGENCIAMENTO DE CARGA';
            this.textoModal = 'Viabilizamos o transporte de cargas atuando junto aos operadores portuários.';
            break;
          case 'en':
            this.tituloModal = 'LOAD AGENCY';
            this.textoModal = 'We enable cargo transportation by working with port operators.';
            break;
          case 'es':
            this.tituloModal = 'AGENCIA DE CARGA';
            this.textoModal = 'Permitimos el transporte de carga trabajando con operadores portuarios.';
            break;
          default:
            this.tituloModal = 'AGENCIAMENTO DE CARGA';
            this.textoModal = 'Viabilizamos o transporte de cargas atuando junto aos operadores portuários.';
            break;
        }
        break;
      case 4:
        switch (idiomaEscolhido) {
          case 'br':
            this.tituloModal = 'ARMAZÉM ALFANDEGADO';
            this.textoModal = 'Possuímos estrutura própria e de parceiros nos principais portos do Brasil.';
            break;
          case 'en':
            this.tituloModal = 'CUSTOMS WAREHOUSE';
            this.textoModal = 'We have our own structure and partners in the main ports in Brazil.';
            break;
          case 'es':
            this.tituloModal = 'DEPÓSITO ADUANERO';
            this.textoModal = 'Contamos con estructura propia y socios en los principales puertos de Brasil.';
            break;
          default:
            this.tituloModal = 'ARMAZÉM ALFANDEGADO';
            this.textoModal = 'Possuímos estrutura própria e de parceiros nos principais portos do Brasil.';
            break;
        }
        break;
      case 5:
        switch (idiomaEscolhido) {
          case 'br':
            this.tituloModal = 'DESEMBARAÇO';
            this.textoModal = 'Gestão interna operacional da equipe Ascensus, despacho aduaneiro, recintos alfandegados e órgãos anuentes.';
            break;
          case 'en':
            this.tituloModal = 'Clearance';
            this.textoModal = 'Internal operational management of the Ascensus team, customs clearance, bonded premises and consenting bodies.';
            break;
          case 'es':
            this.tituloModal = 'Autorización';
            this.textoModal = 'Gestión operativa interna del equipo de Ascenso, despacho de aduanas, locales aduaneros y órganos de consentimiento.';
            break;
          default:
            this.tituloModal = 'DESEMBARAÇO';
            this.textoModal = 'Gestão interna operacional da equipe Ascensus, despacho aduaneiro, recintos alfandegados e órgãos anuentes.';
            break;
        }
        break;
      case 6:
        switch (idiomaEscolhido) {
          case 'br':
            this.tituloModal = 'ARMAZÉM';
            this.textoModal = 'Armazéns próprios e parcerias com empresas especializadas na armazenagem e distribuição, nos melhores pontos geográficos do país.';
            break;
          case 'en':
            this.tituloModal = 'STORAGE';
            this.textoModal = 'Own warehouses and partnerships with companies specialized in storage and distribution, in the best geographic points of the country.';
            break;
          case 'es':
            this.tituloModal = 'ALMACENAMIENTO';
            this.textoModal = 'Almacenes propios y alianzas con empresas especializadas en almacenamiento y distribución, en los mejores puntos geográficos del país.';
            break;
          default:
            this.tituloModal = 'ARMAZÉM';
            this.textoModal = 'Armazéns próprios e parcerias com empresas especializadas na armazenagem e distribuição, nos melhores pontos geográficos do país.';
            break;
        }
        break;
      case 7:
        switch (idiomaEscolhido) {
          case 'br':
            this.tituloModal = 'TRADING COMPANY';
            this.textoModal = 'Intermediação de comércio exterior, desenvolvendo todas as atividades de logísticas nacional e internacional. Buscamos ganhos para nossos clientes através de benefícios fiscais.';
            break;
          case 'en':
            this.tituloModal = 'TRADING COMPANY';
            this.textoModal = 'Foreign trade intermediation, developing all national and international logistics activities. We seek gains for our clients through tax benefits.';
            break;
          case 'es':
            this.tituloModal = 'TRADING COMPANY';
            this.textoModal = 'Intermediación de comercio exterior, desarrollando todas las actividades logísticas nacionales e internacionales. Buscamos ganancias para nuestros clientes a través de beneficios fiscales.';
            break;
          default:
            this.tituloModal = 'TRADING COMPANY';
            this.textoModal = 'Intermediação de comércio exterior, desenvolvendo todas as atividades de logísticas nacional e internacional. Buscamos ganhos para nossos clientes através de benefícios fiscais.';
            break;
        }
        break;
      case 8:
        switch (idiomaEscolhido) {
          case 'br':
            this.tituloModal = 'DISTRIBUIÇÃO';
            this.textoModal = 'Temos uma gestão especializada, onde com a otimização de recursos alcançamos bons resultados para operação.';
            break;
          case 'en':
            this.tituloModal = 'DISTRIBUTION';
            this.textoModal = 'We have a specialized management, where with the optimization of resources we achieve good results for operation.';
            break;
          case 'es':
            this.tituloModal = 'DISTRIBUCIÓN';
            this.textoModal = 'Contamos con una gestión especializada, donde con la optimización de recursos logramos buenos resultados para la operación.';
            break;
          default:
            this.tituloModal = 'DISTRIBUIÇÃO';
            this.textoModal = 'Temos uma gestão especializada, onde com a otimização de recursos alcançamos bons resultados para operação.';
            break;
        }
        break;
    }
    $('#exampleModal').modal('show');
  }
}
