import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/app.services';
declare var idiomaEscolhido, $: any;

@Component({
  selector: 'app-trabalhe-conosco',
  templateUrl: './trabalhe-conosco.component.html',
  styleUrls: ['./trabalhe-conosco.component.scss']
})
export class TrabalheConoscoComponent implements OnInit {

  dados: any = {};
  traducao: any = {};
  traducaoTermos = {
    br: {
      trabalhe: 'TRABALHE CONOSCO',
      nome: `Nome(*)`,
      name: 'Nome e sobrenome',
      mail: 'nome@seuemail.com.br',
      tel: 'Telefone(*)',
      cep: 'Cep',
      rua: 'Rua',
      nomerua: 'Nome completo da rua, avenida ou travavessa',
      num: 'Número',
      comple: 'Complemento',
      complemento: 'Complemento',
      bai: 'Bairro',
      bairro: 'Bairro',
      city: 'Cidade',
      es: 'Estado',
      estado: 'Estado',
      interesse: 'Interesse na filial da cidade',
      cidad: 'Cidade',
      cidade: 'Cidade',
      area0: 'Área de interesse 1',
      area1: 'Área de interesse',
      area2: 'Área de interesse 2',
      area02: 'Área de interesse',
      area3: 'Area de interesse 3',
      area03: 'Area de interesse',
      observacoes: 'Observações(*)',
      observa: 'Escreva aqui sua mensagem',
      anexar: 'ANEXAR CURRÍCULO',
      arquivo: 'Arquivo',
      extensoes: `* Extensões permitidas: pdf, doc e docx<br>
      * Tamanho total dos arquivos: 20MB.`,
      enviar: 'Enviar',
      vagas: 'TEMOS VAGAS PARA PORTADORES DE NECESSIDADES ESPECIAIS',
      curriculo: 'Envie seu curriculo para',
      servico: 'SERVIÇO DE INFORMAÇÃO AO CONSUMIDOR',
      horario: 'HORÁRIO DE ATENDIMENTO',
      semanas: 'Segunda à sexta-feira',
      hora: `08:00 às 12:00 <br>
             13:30 às 18:00`
    },
    en: {
      trabalhe: 'WORK WITH US',
      nome: `Name(*)`,
      name: 'Name and surname',
      mail: 'name@youremail.com',
      tel: 'Phone(*)',
      cep: 'Zip code',
      rua: 'Street',
      nomerua: 'Full name of the street, avenue or lane',
      num: 'Number',
      comple: 'Complement',
      complemento: 'Complement',
      bai: 'Neighborhood',
      bairro: 'Neighborhood',
      city: 'City',
      es: 'State',
      estado: 'State',
      interesse: 'Interest in the city branch',
      cidad: 'City',
      cidade: 'City',
      area0: 'Area of interest 1',
      area1: 'Area of interest',
      area2: 'Area of interest 2',
      area02: 'Area of interest',
      area3: 'Area of interest 3',
      area03: 'Area of interest',
      observacoes: 'Observation(*)',
      observa: 'Write your message here',
      anexar: 'ATTACH CURRICULUM',
      arquivo: 'Archive',
      extensoes: `* Allowed extensions: pdf, doc and docx<br>
      * Total file size: 20MB.`,
      enviar: 'Send',
      vagas: 'WE HAVE SPACES FOR PEOPLE WITH SPECIAL NEEDS',
      curriculo: 'Send your resume to',
      servico: 'CONSUMER INFORMATION SERVICE',
      horario: 'OPENING HOURS',
      semanas: 'Monday to Friday',
      hora: `08:00 às 12:00 <br>
             13:30 às 18:00`
    },
    es: {
      trabalhe: 'TRABAJA CON NOSOTROS',
      nome: `Nombre(*)`,
      name: 'Nombre y apellido',
      mail: 'nombre@suemail.com',
      tel: 'Teléfono(*)',
      cep: 'Código postal',
      rua: 'Calle',
      nomerua: 'Nombre completo de la calle, avenida o carril',
      num: 'Número',
      comple: 'Complemento',
      complemento: 'Complemento',
      bai: 'Barrio',
      bairro: 'Barrio',
      city: 'Ciudad',
      es: 'Estado',
      estado: 'Estado',
      interesse: 'Interés en la sucursal de la ciudad',
      cidad: 'Ciudad',
      cidade: 'Ciudad',
      area0: 'Area de interes 1',
      area1: 'Area de interes',
      area2: 'Area de interes 2',
      area02: 'Area de interes',
      area3: 'Area de interes 3',
      area03: 'Area de interes',
      observacoes: 'Comentarios(*)',
      observa: 'Escriba su mensaje aquí',
      anexar: 'ADJUNTE CURRICULUM',
      arquivo: 'Archivo',
      extensoes: `* Extensiones permitidas: pdf, doc y docx<br>
      * Tamaño total del archivo: 20MB.`,
      enviar: 'Enviar',
      vagas: 'TENEMOS ESPACIOS PARA PERSONAS CON NECESIDADES ESPECIALES',
      curriculo: 'Envíe su currículum a',
      servico: 'SERVICIO DE INFORMACIÓN AL CONSUMIDOR',
      horario: 'HORARIO DE ATENCIÓN',
      semanas: 'Ee lunes a viernes',
      hora: `08:00 às 12:00 <br>
             13:30 às 18:00`
    },
    all: {
      trabalhe: 'TRABALHE CONOSCO',
      nome: `Nome(*)`,
      name: 'Nome e sobrenome',
      mail: 'nome@seuemail.com.br',
      tel: 'Telefone(*)',
      cep: 'Cep',
      rua: 'Rua',
      nomerua: 'Nome completo da rua, avenida ou travavessa',
      num: 'Número',
      comple: 'Complemento',
      complemento: 'Complemento',
      bai: 'Bairro',
      bairro: 'Bairro',
      city: 'Cidade',
      es: 'Estado',
      estado: 'Estado',
      interesse: 'Interesse na filial da cidade',
      cidad: 'Cidade',
      cidade: 'Cidade',
      area0: 'Área de interesse 1',
      area1: 'Área de interesse',
      area2: 'Área de interesse 2',
      area02: 'Área de interesse',
      area3: 'Area de interesse 3',
      area03: 'Area de interesse',
      observacoes: 'Observações(*)',
      observa: 'Escreva aqui sua mensagem',
      anexar: 'ANEXAR CURRÍCULO',
      arquivo: 'Arquivo',
      extensoes: `* Extensões permitidas: pdf, doc e docx<br>
      * Tamanho total dos arquivos: 20MB.`,
      enviar: 'Enviar',
      vagas: 'TEMOS VAGAS PARA PORTADORES DE NECESSIDADES ESPECIAIS',
      curriculo: 'Envie seu curriculo para',
      servico: 'SERVIÇO DE INFORMAÇÃO AO CONSUMIDOR',
      horario: 'HORÁRIO DE ATENDIMENTO',
      semanas: 'Segunda à sexta-feira',
      hora: `08:00 às 12:00 <br>
             13:30 às 18:00`
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
    setTimeout(() => {

      const readURL = (input) => {
        if (input.files && input.files[0]) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const res: any = e;

            this.dados.base64 = res.target.result;
            this.dados.ext = $('#arquivo-asc').val().split('.')[$('#arquivo-asc').val().split('.').length - 1];
            // $('#modalFoto').modal('show');
            // this.subirFoto = true;
            // setTimeout(() => {
            //   $('#imagem-foto').attr('src', res.target.result);
            //   const image: any = document.getElementById('imagem-foto');
            //   this.cropper = new Cropper(image, {
            //     aspectRatio: 1 / 1,
            //     crop(event) {
            //     },
            //   });
            // }, 200);
          };

          reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
      };

      // tslint:disable-next-line:variable-name
      const _self: any = this;
      $('#arquivo-asc').change(function () {
        readURL(this);
      });
    }, 1500);
  }

  buscarCep() {
    const api = new Api('', '');
    const cep = String(this.dados.cep || '').replace(/\D/g, '');
    api.viaCep(cep).then(ret => {
      if (!ret) {
        this.dados.rua = '';
        this.dados.bairro = '';
        this.dados.cidade = '';
        this.dados.estado = '';
      
      } else {
        this.dados.rua = ret.logradouro;
        this.dados.bairro = ret.bairro;
        this.dados.cidade = ret.localidade;
        this.dados.estado = ret.uf;
      }
    });
  }

  enviar() {
    let error = 0;
    // tslint:disable-next-line:triple-equals
    if (String(this.dados.nome || '').trim() == '') {
      error++;
    }

    // tslint:disable-next-line:triple-equals
    if (String(this.dados.email || '').trim() == '') {
      error++;
    }

    // tslint:disable-next-line:triple-equals
    if (String(this.dados.telefone || '').trim() == '') {
      error++;
    }

    // tslint:disable-next-line:triple-equals
    if (String(this.dados.mensagem || '').trim() == '') {
      error++;
    }

    if (error > 0) {
      alert('Complete todos os campos para continuar');
      return false;
    }

    let html = '';
    html += '<b>Nome:</b> ' + String(this.dados.nome || '') + '<br>';
    html += '<b>E-mail:</b> ' + String(this.dados.email || '') + '<br>';
    html += '<b>Telefone:</b> ' + String(this.dados.telefone || '') + '<br>';

    html += '<b>CEP:</b> ' + String(this.dados.cep || '') + '<br>';
    html += '<b>Rua:</b> ' + String(this.dados.rua || '') + '<br>';
    html += '<b>Número:</b> ' + String(this.dados.numero || '') + '<br>';
    html += '<b>Complemento:</b> ' + String(this.dados.complemento || '') + '<br>';
    html += '<b>Bairro:</b> ' + String(this.dados.bairro || '') + '<br>';
    html += '<b>Cidade:</b> ' + String(this.dados.cidade || '') + '<br>';
    html += '<b>Estado:</b> ' + String(this.dados.estado || '') + '<br>';
    html += '<b>Cidade Interesse:</b> ' + String(this.dados.cidadeInteresse || '') + '<br>';
    html += '<b>Area Interesse 1:</b> ' + String(this.dados.areaInteresse1 || '') + '<br>';
    html += '<b>Area Interesse 2:</b> ' + String(this.dados.areaInteresse2 || '') + '<br>';
    html += '<b>Area Interesse 3:</b> ' + String(this.dados.areaInteresse3 || '') + '<br>';
    html += '<b>Mensagem:</b><br> ' + String(this.dados.mensagem || '');

    let data: any = {
      mensagem: html,
      nome: this.dados.nome,
      email: this.dados.email,
      tipo_form: 'trabalhe-conosco'
    };

    if (String(this.dados.base64 || '').trim() != '') {
      data.base64 = this.dados.base64;
      data.ext = this.dados.ext;
    }
    
    const apiMensagem = new Api('', '');
    apiMensagem.post(data).finally(() => {
      alert('Mensagem enviada com sucesso!');
      this.dados = {};
    });
  }


}
