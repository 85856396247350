import { Component, OnInit, ElementRef } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      servicos: 'SERVIÇOS',
      tributario: 'Do tributário ao logístico e até aduaneiro',
      dinamismo: `
          Dinamismo, alma empreendedora e foco na excelência tornam o serviço de Comex do Ascensus Group
          um dos mais respeitados e confiáveis no mercado da América Latina. Resolvemos desafios
          relacionados ao transporte de bens e serviços a nível global, não importa se pelo ar, mar ou
          terra, tudo pela nossa expertise de 20 anos de mercado, aliada a nossa rede de parceiros
          estratégicos e líderes no segmento, sediados em Miami, Shangai e Hong Kong. Nossos serviços são
          planejados e personalizados de ponta a ponta, conforme a necessidade de cada cliente, garantindo
          o controle de todo o processo e fazendo com que, infraestrutura, processos, tecnologia e atenção
          em todos os detalhes, alcancem o melhor desempenho.
      `,
      expertise: 'Conheça nossa expertise:',
      importacao: 'IMPORTAÇÃO',
      assessoria: `
          • Assessoria técnica no processo de importação e logística especializada em todo o
          território
          nacional;
          <br>
          • Análise documental em conformidade com a legislação aduaneira;
          <br>
          • Registro de produtos junto aos órgãos anuentes (INMETRO, ANVISA, MAPA e outros);
          <br>
          • Gestão de Desembaraço Aduaneiro;
          <br>
          • Regimes aduaneiros especiais: Drawback, Admissão Temporária e Entreposto
          Aduaneiro;
          <br>
          • Assessoria aduaneira para importação direta de pessoa física: barcos, automóveis e
          aeronaves;
          <br>
          • Projetos Especiais: máquinas e equipamentos usados, linhas de produção e energia
          eólica.`,
      exportacao: 'EXPORTAÇÃO',
      abertura: `
          • Abertura e desenvolvimento de novos mercados;
          <br>
          • Participação em feiras, rodadas de negócios/missões empresariais;
          <br>
          • Desenvolvimento de parcerias com representantes de vendas e tradings estrangeiras;
          <br>
          • Gestão financeira das operações;
          <br>
          • Operações back-to-back.`,
      distLogistica: 'DISTRIBUIÇÃO E LOGÍSTICA',
      gestao: `
          ● Gestão da Cadeia Logística;
          <br>
          ● Estruturação de projetos “door to door”;
          <br>
          ● Armazenagem temporária ou permanente;
          <br>
          ● Distribuição – Varejo e Atacado;
          <br>
          ● Serviços específicos de armazenagem.
      `,
      planejamento: 'PLANEJAMENTO TRIBUTÁRIO',
      importacaoConta: `
      ● Importação por conta e ordem;
      <br>
      ● Importação por encomenda;
      <br>
      ● Tratamento tributário diferenciado do ICMS;
      <br>
      ● Ex Tarifários;
      <br>
      ● Assessoria para classificação fiscal.
      `,
      planejamentoFinanceiro: 'PLANEJAMENTO FINANCEIRO',
      aberturaCarta: `
          ● Abertura de carta de crédito;
          <br>
          ● Assessoria em contratos de câmbio;
          <br>
          ● Linhas de créditos próprias;
          <br>
          ● Operações financeiras estruturadas.
      `
    },
    en: {
      servicos: 'SERVICES',
      tributario: 'From tax to logistics and even customs',
      dinamismo: `
      Dynamism, entrepreneurial spirit and a focus on excellence make Ascex Group's Comex service
      one of the most respected and trusted in the Latin American market. We solve challenges
      related to the transport of goods and services globally, whether by air, sea or
      land, all because of our 20 years of market expertise, combined with our network of partners
      strategic and industry leaders, based in Miami, Shanghai and Hong Kong. Our services are
      planned and customized from end to end, according to the needs of each client, ensuring
      control of the entire process and making infrastructure, processes, technology and attention
      in every detail, achieve the best performance.
      `,
      expertise: 'Know our expertise:',
      importacao: 'IMPORT',
      assessoria: `
          • Technical assistance in the import and logistics process specialized throughout the
          territory
          national;
          <br>
          • Document analysis in accordance with customs legislation;
          <br>
          • Product registration with the consenting bodies (INMETRO, ANVISA, MAPA and others);
          <br>
          • Customs Clearance Management;
          <br>
          • Special customs regimes: Drawback, Temporary Admission and Warehouse
          Customs;
          <br>
          • Customs assistance for direct import of individuals: boats, cars and
          aircraft;
          <br>
          • Special Projects: used machines and equipment, production lines and energy
          wind.`,
      exportacao: 'EXPORT',
      abertura: `
          • Opening and developing new markets;
          <br>
          • Participation in fairs, business rounds / business missions;
          <br>
          • Development of partnerships with sales representatives and foreign trading companies;
          <br>
          • Financial management of operations;
          <br>
          • Operações back-to-back.`,
      distLogistica: 'DISTRIBUTION AND LOGISTICS',
      gestao: `
          ● Supply Chain Management;
          <br>
          ● Structuring of “door to door” projects”;
          <br>
          ● Temporary or permanent storage;
          <br>
          ● Distribuição – Varejo e Atacado;
          <br>
          ● Specific storage services.
      `,
      planejamento: 'TAX PLANNING',
      importacaoConta: `
      ● Import on account and order;
      <br>
      ● Import by order;
      <br>
      ● Differentiated tax treatment of ICMS;
      <br>
      ● Ex Tariffs;
      <br>
      ● Tax classification advice.
      `,
      planejamentoFinanceiro: 'FINANCIAL PLANNING',
      aberturaCarta: `
          ● Opening letter of credit;
          <br>
          ● Advice on foreign exchange contracts;
          <br>
          ● Own credit lines;
          <br>
          ● Structured financial operations.
      `
    },
    es: {
      servicos: 'SERVICIOS',
      tributario: 'Desde los impuestos hasta la logística e incluso las aduanas',
      dinamismo: `
      Dinamismo, espíritu emprendedor y un enfoque en la excelencia hacen que el servicio Comex de Ascex Group
      uno de los más respetados y confiables del mercado latinoamericano. Resolvemos retos
      relacionados con el transporte de bienes y servicios a nivel mundial, ya sea por aire, mar o
      tierra, todo gracias a nuestros 20 años de experiencia en el mercado, combinados con nuestra red de socios
      líderes estratégicos y de la industria, con sede en Miami, Shanghai y Hong Kong. Nuestros servicios son
      planeado y personalizado de punta a punta, de acuerdo a las necesidades de cada cliente, asegurando
      control de todo el proceso y haciendo infraestructura, procesos, tecnología y atención
      en cada detalle, consiga el mejor rendimiento.
      `,
      expertise: 'Conozca nuestra experiencia:',
      importacao: 'IMPORTAR',
      assessoria: `
          • Asistencia técnica en el proceso de importación y logística especializada en todo el
          territorio
          nacional;
          <br>
          • Análisis de documentos de acuerdo con la legislación aduanera;
          <br>
          • Registro del producto ante los organismos que lo consienten (INMETRO, ANVISA, MAPA y otros);
          <br>
          • Gestión de despacho de aduanas;
          <br>
          • Regímenes aduaneros especiales: Drawback, Admisión Temporal y Depósito
          Aduana;
          <br>
          • Asistencia aduanera para importación directa de particulares: embarcaciones, automóviles y
          aeronave;
          <br>
          • Proyectos Especiales: máquinas y equipos usados, líneas de producción y energía
          energía eólica.`,
      exportacao: 'EXPORTAR',
      abertura: `
          • Abrir y desarrollar nuevos mercados;
          <br>
          • Participación en ferias, rondas de negocios / misiones comerciales;
          <br>
          • Desarrollo de alianzas con representantes de ventas y empresas comerciales extranjeras;
          <br>
          • Gestión financiera de operaciones;
          <br>
          • Operaciones consecutivas.`,
      distLogistica: 'DISTRIBUCION Y LOGISTICA',
      gestao: `
          ● Gestión de la cadena de suministro;
          <br>
          ● Estructuración de proyectos “puerta a puerta”;
          <br>
          ● Almacenamiento temporal o permanente;
          <br>
          ● Distribución: minorista y mayorista;
          <br>
          ● Servicios de almacenamiento específicos.
      `,
      planejamento: 'PLANIFICACIÓN TRIBUTARIA',
      importacaoConta: `
      ● Importación a cuenta y pedido;
      <br>
      ● Importar por pedido;
      <br>
      ● Tratamiento fiscal diferenciado del ICMS;
      <br>
      ● Tarifas Ex;
      <br>
      ● Asesoramiento en clasificación fiscal.
      `,
      planejamentoFinanceiro: 'PLANEAMIENTO FINANCIERO',
      aberturaCarta: `
          ● Apertura de carta de crédito;
          <br>
          ● Asesoramiento en contratos de cambio de divisas;
          <br>
          ● Líneas de crédito propias;
          <br>
          ● Operaciones financieras estructuradas.
      `
    },
    all: {
      servicos: 'SERVIÇOS',
      tributario: 'Do tributário ao logístico e até aduaneiro',
      dinamismo: `
          Dinamismo, alma empreendedora e foco na excelência tornam o serviço de Comex do Ascensus Group
          um dos mais respeitados e confiáveis no mercado da América Latina. Resolvemos desafios
          relacionados ao transporte de bens e serviços a nível global, não importa se pelo ar, mar ou
          terra, tudo pela nossa expertise de 20 anos de mercado, aliada a nossa rede de parceiros
          estratégicos e líderes no segmento, sediados em Miami, Shangai e Hong Kong. Nossos serviços são
          planejados e personalizados de ponta a ponta, conforme a necessidade de cada cliente, garantindo
          o controle de todo o processo e fazendo com que, infraestrutura, processos, tecnologia e atenção
          em todos os detalhes, alcancem o melhor desempenho.
      `,
      expertise: 'Conheça nossa expertise:',
      importacao: 'IMPORTAÇÃO',
      assessoria: `
          • Assessoria técnica no processo de importação e logística especializada em todo o
          território
          nacional;
          <br>
          • Análise documental em conformidade com a legislação aduaneira;
          <br>
          • Registro de produtos junto aos órgãos anuentes (INMETRO, ANVISA, MAPA e outros);
          <br>
          • Gestão de Desembaraço Aduaneiro;
          <br>
          • Regimes aduaneiros especiais: Drawback, Admissão Temporária e Entreposto
          Aduaneiro;
          <br>
          • Assessoria aduaneira para importação direta de pessoa física: barcos, automóveis e
          aeronaves;
          <br>
          • Projetos Especiais: máquinas e equipamentos usados, linhas de produção e energia
          eólica.`,
      exportacao: 'EXPORTAÇÃO',
      abertura: `
          • Abertura e desenvolvimento de novos mercados;
          <br>
          • Participação em feiras, rodadas de negócios/missões empresariais;
          <br>
          • Desenvolvimento de parcerias com representantes de vendas e tradings estrangeiras;
          <br>
          • Gestão financeira das operações;
          <br>
          • Operações back-to-back.`,
      distLogistica: 'DISTRIBUIÇÃO E LOGÍSTICA',
      gestao: `
          ● Gestão da Cadeia Logística;
          <br>
          ● Estruturação de projetos “door to door”;
          <br>
          ● Armazenagem temporária ou permanente;
          <br>
          ● Distribuição – Varejo e Atacado;
          <br>
          ● Serviços específicos de armazenagem.
      `,
      planejamento: 'PLANEJAMENTO TRIBUTÁRIO',
      importacaoConta: `
      ● Importação por conta e ordem;
      <br>
      ● Importação por encomenda;
      <br>
      ● Tratamento tributário diferenciado do ICMS;
      <br>
      ● Ex Tarifários;
      <br>
      ● Assessoria para classificação fiscal.
      `,
      planejamentoFinanceiro: 'PLANEJAMENTO FINANCEIRO',
      aberturaCarta: `
          ● Abertura de carta de crédito;
          <br>
          ● Assessoria em contratos de câmbio;
          <br>
          ● Linhas de créditos próprias;
          <br>
          ● Operações financeiras estruturadas.
      `
    },
  };
  constructor(private elementRef: ElementRef) {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {

    $(this.elementRef.nativeElement).find('.scrollbar-external').scrollbar({
      "autoScrollSize": false,
      "scrollx": $(this.elementRef.nativeElement).find('.external-scroll_x'),
      "scrolly": $(this.elementRef.nativeElement).find('.external-scroll_y')
    });
  }

}
