import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-comex-distribution-fato',
  templateUrl: './comex-distribution-fato.component.html',
  styleUrls: ['./comex-distribution-fato.component.scss']
})
export class ComexDistributionFatoComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      ascensus: 'ASCENSUS <small>DISTRIBUIÇÃO</small>',
      estaVertical: `
      Esta vertical oferece gestão de distribuição que se traduz em um one-stop-solution, para
      aqueles que desejam distribuir produtos importados para o atacado do mercado nacional
      através de canais tradicionais ou e-commerce. Desta forma, atuamos através da formação de
      joint-ventures com parceiros que tenham a parte comercial estruturada e planejada e/ou
      tenham um plano de investimento para tal, além de capitalizados para as demandas de capital
      de giro (estoque) e todos os demais componentes para que a distribuição seja gerida por nós.<br><br><br>

      Soluções:<br><br>
      • Gestão de importação e distribuição: administração e acompanhamento de todo o processo até
      a chegada em território nacional.<br><br>

      • Gestão fiscal e tributária: avaliação de cenários e opções tributárias para melhor
      aproveitamento das condições fiscais vigentes.<br><br>

      • Gestão de armazenagem e handling: parceria com armazéns para acomodação e manipulação de
      cargas, de produtos comoditizados até itens de mercado de luxo e tecnologia.<br><br>

      • Gestão de todo o fluxo outbound de mercadorias até a efetiva entrega ao destino através de
      rede de parceiros logísticos para qualquer porte de produtos.<br><br>

      • Full-Commerce: solução integrada de distribuição com multi-plataformas de comércio
      eletrônico e logística. Escalável para operações de pequeno a grande porte nas modalidades
      B2B, B2C ou B2B2C.<br><br>

      • Gestão financeira incluindo análise de crédito, contas a receber e de fluxo de caixa,
      administrando de forma individualizada os ativos de cada unidade de negócio.<br><br>

      A gestão das soluções é compartilhada com os parceiros de negócio através de plataforma
      eletrônica (Management Platform) onde estão disponíveis as informações operacionais e
      financeiras do projeto.`
    },
    en: {
      ascensus: 'ASCENSUS <small>DISTRIBUTION/<small>',
      estaVertical: `
      This vertical offers distribution management that translates into a one-stop-solution, for
       those who wish to distribute imported products to the wholesale market
       through traditional channels or e-commerce. In this way, we act through the formation of
       joint ventures with partners that have a structured and planned commercial part and / or
       have an investment plan for this, in addition to capitalized for capital demands
       turnover (stock) and all other components so that the distribution is managed by us.<br><br><br>

       Solutions:<br><br>
      • Import and distribution management: administration and monitoring of the entire process until
      arrival in national territory.<br><br>

      • Fiscal and tax management: assessment of tax scenarios and options for better
      use of current tax conditions.<br><br>

      • Storage and handling management: partnership with warehouses for accommodation and handling of
      loads, from commoditized products to luxury and technology market items.<br><br>

      • Management of the entire outbound flow of goods until the effective delivery to the destination through
      network of logistic partners for any size of products.<br><br>

      • Full-Commerce: integrated distribution solution with cross-platform commerce
      electronic and logistics. Scalable for small to large scale operations
      B2B, B2C or B2B2C.<br><br>

      • Financial management including credit analysis, accounts receivable and cash flow,
      individually managing the assets of each business unit.<br><br>

      Solution management is shared with business partners through a platform
       (Management Platform) where operational and operational information is available
       financial resources of the project.`
    },
    es: {
      ascensus: 'ASCENSUS <small>DISTRIBUCIÓN</small>',
      estaVertical: `
      Este vertical ofrece una gestión de distribución que se traduce en una solución integral, para
       quienes deseen distribuir productos importados al mercado mayorista
       a través de canales tradicionales o comercio electrónico. De esta forma, actuamos a través de la formación de
       empresas conjuntas con socios que tengan una parte comercial estructurada y planificada y / o
       Tener un plan de inversión para esto, además de capitalizarlo para demandas de capital.
       facturación (stock) y todos los demás componentes para que la distribución sea gestionada por nosotros.<br><br><br>

       Soluciones:<br><br>
      • Gestión de importación y distribución: administración y seguimiento de todo el proceso hasta
      llegada al territorio nacional.<br><br>

      • Gestión fiscal y tributaria: evaluación de escenarios tributarios y opciones para mejorar
      uso de las condiciones fiscales vigentes.<br><br>

      • Gestión de almacenamiento y manipulación: asociación con almacenes para el alojamiento y manipulación de
      cargas, desde productos básicos hasta artículos de mercado de lujo y tecnología.<br><br>

      • Gestión de todo el flujo de salida de mercancías hasta la entrega efectiva a destino mediante
      red de socios logísticos para cualquier tamaño de productos.<br><br>

      • Full-Commerce: solución de distribución integrada con comercio multiplataforma
      electrónica y logística. Escalable para operaciones de pequeña a gran escala
      B2B, B2C o B2B2C.<br><br>

      • Gestión financiera que incluye análisis crediticio, cuentas por cobrar y flujo de caja,
      gestionando individualmente los activos de cada unidad de negocio.<br><br>

      La gestión de la solución se comparte con los socios comerciales a través de una plataforma
       (Plataforma de gestión) donde la información operativa y operativa está disponible
       recursos financieros del proyecto.`
    },
    all: {
      ascensus: 'ASCENSUS <small>DISTRIBUIÇÃO<small>',
      estaVertical: `
      Esta vertical oferece gestão de distribuição que se traduz em um one-stop-solution, para
      aqueles que desejam distribuir produtos importados para o atacado do mercado nacional
      através de canais tradicionais ou e-commerce. Desta forma, atuamos através da formação de
      joint-ventures com parceiros que tenham a parte comercial estruturada e planejada e/ou
      tenham um plano de investimento para tal, além de capitalizados para as demandas de capital
      de giro (estoque) e todos os demais componentes para que a distribuição seja gerida por nós.<br><br><br>

      Soluções:<br><br>
      • Gestão de importação e distribuição: administração e acompanhamento de todo o processo até
      a chegada em território nacional.<br><br>

      • Gestão fiscal e tributária: avaliação de cenários e opções tributárias para melhor
      aproveitamento das condições fiscais vigentes.<br><br>

      • Gestão de armazenagem e handling: parceria com armazéns para acomodação e manipulação de
      cargas, de produtos comoditizados até itens de mercado de luxo e tecnologia.<br><br>

      • Gestão de todo o fluxo outbound de mercadorias até a efetiva entrega ao destino através de
      rede de parceiros logísticos para qualquer porte de produtos.<br><br>

      • Full-Commerce: solução integrada de distribuição com multi-plataformas de comércio
      eletrônico e logística. Escalável para operações de pequeno a grande porte nas modalidades
      B2B, B2C ou B2B2C.<br><br>

      • Gestão financeira incluindo análise de crédito, contas a receber e de fluxo de caixa,
      administrando de forma individualizada os ativos de cada unidade de negócio.<br><br>

      A gestão das soluções é compartilhada com os parceiros de negócio através de plataforma
      eletrônica (Management Platform) onde estão disponíveis as informações operacionais e
      financeiras do projeto.`
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }


  ngOnInit() {
  }

  abrirDetalhe() {
    $('.distribuicao-fato-title').toggleClass('active-rotate');
    $('.acordeon-comex-fato').animate({height: 'toggle'});
    this.scroll('#comex-fato');
  }

  fecharDetalhe() {
    $('.distribuicao-fato-title').removeClass('active-rotate');
    $('.acordeon-comex-fato').slideUp();
    this.scroll('#comex-fato');
  }

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }
}
