import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from './app.services';
import { AppFun } from './app.functions.sevices';
import { Subscription } from 'rxjs';



declare var httpGlobal, $, appId, sysParameters, idiomaEscolhido: any, idioma_video: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent{
  title = 'ascensus';
  menuAberto = 0;
  listaGanhadores: any = [];
  recSenha: any = {};
  ehLogado = false;
  alfabeto = String('ABCDEFGHIJKLMNOPQRSTUVXYZ').split('');
  idioma = idiomaEscolhido;
  idioma_video = 'br';

  private wowSubscription: Subscription;

  constructor(public http: HttpClient) {
    if(this.idioma=='en'){
      this.idioma_video = 'en';
    }else if(this.idioma=='es'){
      this.idioma_video = 'es';
    }
    
    httpGlobal = this.http;

    let appFun = new AppFun();

    this.ehLogado = appFun.ehLogado();

    const sysParametersApi = new Api('promotion/sysParameters', 'ascensus');
    sysParametersApi.get().then(ret => {
      sysParameters = ret;
    });

    setInterval(() => {
      let height = $('#rodape-site-resize').height();
      $('#rodape-site-resize').css('margin-top', '-' + (height - 5) + 'px');
      $('.compensar-espaco-footer').css('padding-bottom', height + 'px');
    }, 200);    

  }
  

  sair() {
    localStorage.clear();
    window.location.href = '#home';

  }

  carregarGanhadores() {


  }

  recuperarSenha() {


  }

}
