import { Component, OnInit, Input, ElementRef } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-evolucao',
  templateUrl: './evolucao.component.html',
  styleUrls: ['./evolucao.component.scss']
})
export class EvolucaoComponent implements OnInit {
  @Input() titulo: string = "";
  @Input() descricao: string = "";
  @Input() background: string = "";
  @Input() bgColor: string = "";

  passo = 290;
  traducao: any = {};
  traducaoTermos = {
    br: {
      evolucao: 'EVOLUÇÃO',
      descricao: 'Ascensus Group',
      timeline: [
        {
          ano: '2001',
          descricao: 'Fundação<br> &nbsp;',
          texto: 'O Ascensus Group inicia seus trabalhos como especialista em gestão tributária e financeira. Rapidamente se destaca no mercado com resultados bastante positivos.'
        },
        {
          ano: '2005',
          descricao: 'Operações<br> Trading',
          texto: 'Por meio das operações trading, passa a atuar como integrador de negócios entre empresas fabricantes e empresas compradoras em operações de exportação ou de importação.',
          ehDestaque: true
        },
        {
          ano: '2010',
          descricao: 'Integrador<br> Logístico',
          texto: 'Gerencia todas as estruturas logísticas. Potencializa estratégias desde o planejamento, até a implementação, o controle e a garantia da eficácia de todo o fluxo de materiais e mercadorias. '
        },
        {
          ano: '2011',
          descricao: 'Mudança<br> Nova Sede',
          texto: 'Em Joinville, maior cidade do Estado de Santa Catarina, o Ascensus Group inaugura sua nova sede. Localizada no distrito industrial da cidade, conta com 10 mil metros quadrados de área construída e agrega as principais atividades do Ascensus Group.'
        },
        {
          ano: '2012',
          descricao: 'Importação<br> Hubsul',
          texto: 'Início dos trabalhos da Hubsul, empresa especializada na gestão de importação e distribuição de produtos, que otimiza o tempo e reduz custos de seus clientes. A Hubsul oferece armazenagem, faturamento, logística e todo o processo de entrega aos canais de comercialização.'
        },
        {
          ano: '2013',
          descricao: 'Importação<br> Logística',
          texto: 'Com 98% de canal verde somos referência nos processos de importação.'
        },
        {
          ano: '2014',
          descricao: 'Importação<br> Exportação ',
          texto: 'O grupo investe em tecnologias e desenvolve forte capacitação de seus profissionais com as mais variadas certificações internacionais, tornando-se referência no mercado.'
        },
        {
          ano: '2015',
          descricao: 'Importação<br> Exportação <br> Quanthus',
          texto: 'Lançamento da Quanthus, empresa do Grupo que concentra toda a cadeia de importação e distribuição. Se destaca pela redução dos custos das operações de importação com seus serviços para o comércio internacional, planejamento tributário e logística estratégica.'
        },
        {
          ano: '2016',
          descricao: 'Integração<br> Distribuição <br> Ascensus Usa',
          texto: 'O Ascensus Group tem escritórios localizados nos principais centros econômicos do país e também nos Estados Unidos, em Miami, garantindo assim a integração e a presença global do Grupo.'
        },
        {
          ano: '2018',
          descricao: 'Internacionalização<br> EUA',
          texto: 'O Ascensus Group se fortalece por integrar toda a cadeia internacional de suprimentos, gerenciar todas as estruturas logísticas e otimizar custos e tributos. É garantia de segurança e economia para o cliente.'
        },
        {
          ano: '2019',
          descricao: 'Fusão das<br> Distribuidoras',
          texto: 'Unificamos expertises para sermos mais velozes na distribuição.'
        },
        {
          ano: '2020',
          descricao: 'Internacionalização <br>Europa',
          texto: 'A abertura de escritórios nos Estados Unidos e Europa fortalece a visão de negócio do Ascensus Group, que é ser reconhecido mundialmente como integrador de negócios na cadeia de importação, distribuição e exportação de produtos no Brasil.'
        }
      ]
    },
    en: {
      evolucao: 'EVOLUTION',
      descricao: 'Ascensus Group',
      timeline: [
        {
          ano: '2001',
          descricao: 'Foundation<br> &nbsp;',
          texto: 'Ascensus Group starts its work as a specialist in tax and financial management. It quickly stands out in the market with very positive results.'
        },
        {
          ano: '2005',
          descricao: 'Operations<br> Trading',
          texto: 'Through trading operations, he starts to act as a business integrator between manufacturing companies and purchasing companies in export or import operations.',
          ehDestaque: true
        },
        {
          ano: '2010',
          descricao: 'Integrator<br> Logistic',
          texto: 'Manages all logistical structures. It leverages strategies from planning, to implementation, control and ensuring the effectiveness of the entire flow of materials and goods.'
        },
        {
          ano: '2011',
          descricao: 'Change<br> New place',
          texto: 'In Joinville, the largest city in the state of Santa Catarina, Ascensus Group opens its new headquarters. Located in the industrial district of the city, it has 10,000 square meters of built area and aggregates the main activities of the Ascensus Group.'
        },
        {
          ano: '2012',
          descricao: 'Import<br> Hubsul',
          texto: 'Beginning of the work of Hubsul, a company specialized in the management of import and distribution of products, which optimizes time and reduces costs for its customers. Hubsul offers storage, billing, logistics and the entire delivery process to the sales channels.'
        },
        {
          ano: '2013',
          descricao: 'Import<br> Logistics',
          texto: 'With 98% green channel, we are a reference in import processes.'
        },
        {
          ano: '2014',
          descricao: 'Import<br> Export ',
          texto: 'The group invests in technologies and develops strong training of its professionals with the most varied international certifications, becoming a reference in the market.'
        },
        {
          ano: '2015',
          descricao: 'Import<br> Export <br> Quanthus',
          texto: 'Launch of Quanthus, a Group company that concentrates the entire import and distribution chain. It stands out for reducing the costs of import operations with its services for international trade, tax planning and strategic logistics.'
        },
        {
          ano: '2016',
          descricao: 'Integration<br> Distribution <br> Ascensus Usa',
          texto: 'The Ascensus Group has offices located in the main economic centers of the country and also in the United States, in Miami, thus ensuring the integration and the global presence of the Group.'
        },
        {
          ano: '2018',
          descricao: 'USA<br> Internationalization',
          texto: 'Ascensus Group is strengthened by integrating the entire international supply chain, managing all logistics structures and optimizing costs and taxes. It is a guarantee of security and savings for the customer.'
        },
        {
          ano: '2019',
          descricao: 'Merger of<br>Distribution Companies',
          texto: 'We unify expertise to be faster in distribution.'
        },
        {
          ano: '2020',
          descricao: 'Europe <br> Internationalization',
          texto: 'The opening of offices in the United States and Europe strengthens the business vision of the Ascensus Group, which is to be recognized worldwide as a business integrator in the chain of import, distribution and export of products in Brazil.'
        }
      ]
    },
    es: {
      evolucao: 'EVOLUCIÓN',
      descricao: 'Ascensus Group',
      timeline: [
        {
          ano: '2001',
          descricao: 'Fundación<br> &nbsp;',
          texto: 'Ascensus Group inicia su labor como especialista en gestión fiscal y financiera. Rápidamente se destaca en el mercado con resultados muy positivos.'
        },
        {
          ano: '2005',
          descricao: 'Operaciones<br> Trading',
          texto: 'A través de las operaciones comerciales, comienza a actuar como un integrador comercial entre las empresas manufactureras y las empresas compradoras en operaciones de exportación o importación.',
          ehDestaque: true
        },
        {
          ano: '2010',
          descricao: 'Integrador<br> Logístico',
          texto: 'Gestiona todas las estructuras logísticas. Aprovecha estrategias desde la planificación hasta la implementación, el control y la garantía de la eficacia de todo el flujo de materiales y mercancías.'
        },
        {
          ano: '2011',
          descricao: 'Cambio<br> Nuevo lugar',
          texto: 'Em Joinville, maior cidade do Estado de Santa Catarina, o Ascensus Group inaugura sua nova sede. Localizada no distrito industrial da cidade, conta com 10 mil metros quadrados de área construída e agrega as principais atividades do Ascensus Group.'
        },
        {
          ano: '2012',
          descricao: 'Importar<br> Hubsul',
          texto: 'Inicio de la labor de Hubsul, empresa especializada en la gestión de importación y distribución de productos, que optimiza tiempos y reduce costos para sus clientes. Hubsul ofrece almacenamiento, facturación, logística y todo el proceso de entrega a los canales de venta.'
        },
        {
          ano: '2013',
          descricao: 'Importar<br> Logística',
          texto: 'Con un canal 98% verde, somos referencia en procesos de importación.'
        },
        {
          ano: '2014',
          descricao: 'Importar<br> Exportar ',
          texto: 'El grupo invierte en tecnologías y desarrolla una sólida formación de sus profesionales con las más variadas certificaciones internacionales, convirtiéndose en un referente en el mercado.'
        },
        {
          ano: '2015',
          descricao: 'Importar<br> Exportar <br> Quanthus',
          texto: 'Lanzamiento de Quanthus, empresa del Grupo que concentra toda la cadena de importación y distribución. Se destaca por reducir los costos de las operaciones de importación con sus servicios de comercio internacional, planificación fiscal y logística estratégica.'
        },
        {
          ano: '2016',
          descricao: 'Integración<br> Distribución <br> Ascensus Usa',
          texto: 'Ascensus Group cuenta con oficinas ubicadas en los principales centros económicos del país y también en Estados Unidos, en Miami, garantizando así la integración y presencia global del Grupo.'
        },
        {
          ano: '2018',
          descricao: 'Internacionalización<br> EUA',
          texto: 'Ascensus Group se fortalece integrando toda la cadena de suministro internacional, gestionando todas las estructuras logísticas y optimizando costes e impuestos. Es una garantía de seguridad y ahorro para el cliente.'
        },
        {
          ano: '2019',
          descricao: 'Fusión de<br> Distribuidores',
          texto: 'Unificamos la experiencia para agilizar la distribución.'
        },
        {
          ano: '2020',
          descricao: 'Internacionalización <br> Europa',
          texto: 'La apertura de oficinas en Estados Unidos y Europa refuerza la visión empresarial del Grupo Ascensus, que es ser reconocido mundialmente como un integrador empresarial en la cadena de importación, distribución y exportación de productos en Brasil.'
        }
      ]
    },
    all: {
      evolucao: 'EVOLUÇÃO',
      descricao: 'Ascensus Group',
      timeline: [
        {
          ano: '2001',
          descricao: 'Fundação<br> &nbsp;',
          texto: 'O Ascensus Group inicia seus trabalhos como especialista em gestão tributária e financeira. Rapidamente se destaca no mercado com resultados bastante positivos.'
        },
        {
          ano: '2005',
          descricao: 'Operações<br> Trading',
          texto: 'Por meio das operações trading, passa a atuar como integrador de negócios entre empresas fabricantes e empresas compradoras em operações de exportação ou de importação.',
          ehDestaque: true
        },
        {
          ano: '2010',
          descricao: 'Integrador<br> Logístico',
          texto: 'Gerencia todas as estruturas logísticas. Potencializa estratégias desde o planejamento, até a implementação, o controle e a garantia da eficácia de todo o fluxo de materiais e mercadorias. '
        },
        {
          ano: '2011',
          descricao: 'Mudança<br> Nova Sede',
          texto: 'Em Joinville, maior cidade do Estado de Santa Catarina, o Ascensus Group inaugura sua nova sede. Localizada no distrito industrial da cidade, conta com 10 mil metros quadrados de área construída e agrega as principais atividades do Ascensus Group.'
        },
        {
          ano: '2012',
          descricao: 'Importação<br> Hubsul',
          texto: 'Início dos trabalhos da Hubsul, empresa especializada na gestão de importação e distribuição de produtos, que otimiza o tempo e reduz custos de seus clientes. A Hubsul oferece armazenagem, faturamento, logística e todo o processo de entrega aos canais de comercialização.'
        },
        {
          ano: '2013',
          descricao: 'Importação<br> Logística',
          texto: 'Com 98% de canal verde somos referência nos processos de importação.'
        },
        {
          ano: '2014',
          descricao: 'Importação<br> Exportação ',
          texto: 'O grupo investe em tecnologias e desenvolve forte capacitação de seus profissionais com as mais variadas certificações internacionais, tornando-se referência no mercado.'
        },
        {
          ano: '2015',
          descricao: 'Importação<br> Exportação <br> Quanthus',
          texto: 'Lançamento da Quanthus, empresa do Grupo que concentra toda a cadeia de importação e distribuição. Se destaca pela redução dos custos das operações de importação com seus serviços para o comércio internacional, planejamento tributário e logística estratégica.'
        },
        {
          ano: '2016',
          descricao: 'Integração<br> Distribuição <br> Ascensus Usa',
          texto: 'O Ascensus Group tem escritórios localizados nos principais centros econômicos do país e também nos Estados Unidos, em Miami, garantindo assim a integração e a presença global do Grupo.'
        },
        {
          ano: '2018',
          descricao: 'Internacionalização<br> EUA',
          texto: 'O Ascensus Group se fortalece por integrar toda a cadeia internacional de suprimentos, gerenciar todas as estruturas logísticas e otimizar custos e tributos. É garantia de segurança e economia para o cliente.'
        },
        {
          ano: '2019',
          descricao: 'Fusão das<br> Distribuidoras',
          texto: 'Unificamos expertises para sermos mais velozes na distribuição.'
        },
        {
          ano: '2020',
          descricao: 'Internacionalização <br>Europa',
          texto: 'A abertura de escritórios nos Estados Unidos e Europa fortalece a visão de negócio do Ascensus Group, que é ser reconhecido mundialmente como integrador de negócios na cadeia de importação, distribuição e exportação de produtos no Brasil.'
        }
      ]
    },
  };


  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
    $(this.elementRef.nativeElement).find('.scrollbar-external').scrollbar({
      "autoScrollSize": false,
      "scrollx": $(this.elementRef.nativeElement).find('.external-scroll_x'),
      "scrolly": $(this.elementRef.nativeElement).find('.external-scroll_y')
    });

    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
    }, 300);


  }

  avancaScroll() {
    let maxScroll = $(this.elementRef.nativeElement).find('.scroll-content table').width();
    let scrollAtual = $(this.elementRef.nativeElement).find('.scroll-content').scrollLeft();

    let scrollTo = 0;
    if (maxScroll < scrollAtual + this.passo) {
      scrollTo = maxScroll;
    } else {
      scrollTo = scrollAtual + this.passo;
    }

    $(this.elementRef.nativeElement).find('.scroll-content').animate({
      scrollLeft: scrollTo
    });
  }

  voltaScroll() {
    let maxScroll = $(this.elementRef.nativeElement).find('.scroll-content table').width();
    let scrollAtual = $(this.elementRef.nativeElement).find('.scroll-content').scrollLeft();

    let scrollTo = 0;
    if (0 > scrollAtual - this.passo) {
      scrollTo = 0;
    } else {
      scrollTo = scrollAtual - this.passo;
    }

    $(this.elementRef.nativeElement).find('.scroll-content').animate({
      scrollLeft: scrollTo
    });
  }

}
