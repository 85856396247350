import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-portos',
  templateUrl: './portos.component.html',
  styleUrls: ['./portos.component.scss?v=1.0.1']
})
export class PortosComponent implements OnInit {

  traducao: any = {};
  tituloModal = '';
  textoModal = '';

  traducaoTermos = {
    br: {
      portos_title: 'Ascensus <small>Portos</small>',
      portos_subheading: `Sobre`,
      portos_text: `Com início em 2021, a partir da aquisição do arrendamento do 
      terminal portuário PAR12, junto à APPA – Administração dos Portos de
      Paranaguá e Antonina. O Ascensus Portos nasceu com o objetivo 
      principal de modernizar o mercado logístico portuário de cargas Roll-
      On e Roll-Off, visando sempre a excelência operacional. 
      <br><br>
      A atuação da empresa garante primor em atendimento aos seus clientes a partir de sua presença nacional em grandes portos do Brasil, em complemento da sua expertise em comércio exterior e de estruturas altamente qualificadas com foco em tecnologia, segurança e agilidade.
      <br><br>
      Hoje, operando importação e exportação em toda a cadeia logística 
      portuária de cargas rolantes, o Ascensus Portos atua em parceria com
      transportadoras, armadores e clientes, além de realizar serviços de 
      carga e descarga das cegonhas, gestão de pátio e o embarque e 
      desembarque de navios. Dispondo seus trabalhos por todo o Brasil, o 
      Ascensus Portos possui atuação vigente nos portos de Paranaguá-PR, 
      Itajaí-SC e Suape-PE.`,
      portos_video_link: `assets/videos/video-portos.mp4?v1.0.1`,

      servicos_title: `SERVIÇOS`,
      servicos_subheading: `O Ascensus Portos possibilita operar cargas rolantes em qualquer lugar do Brasil!`,
      servicos_operacoes_logisticas_title: `Operações Logísticas Portuárias`,
      servicos_operacoes_logisticas_text: `
        Serviços relacionados à todas as movimentações e armazenamento nos portos do país, tais como:
        <br><br>
        <ul>
          <li>Coordenação de estiva - Embarque e Desembarque;</li>
          <li>Operações de costado;</li>
          <li>Coordenação de Capatazia – Área alfandegada e Não alfandegada;</li>
          <li>Carregamento de carretas no costado;</li>
          <li>Movimentação entre pátio e rampa do navio;</li>
          <li>Coordenação de fluxos e processos;</li>
          <li>Acompanhamento de operações em tempo real;</li>
          <li>Vistoria de Qualidade;</li>
          <li>Preparação de veículos para transporte final – PDI;</li>
          <li>Concordância e conexão com RFB, ERP e totais demandas dos clientes.</li>
        </ul>
      `,

      servicos_gestao_title: `Gestão de Pátios e Plantas`,
      servicos_gestao_text: `
        Serviços relacionados à todas as movimentações e armazenamento em pátios de cargas rolantes do país, tais como:
        <br><br>
        <ul>
          <li>Movimentação e Armazenagem de carga:</li>
          <li>Gestão de estoque;</li>
          <li>Coordenação de Carga e Descarga;</li>
          <li>Preparação de veículos para transporte final:</li>
          <li>PDI e PPO;</li>
          <li>Acessorização e Reparos rápidos;</li>
          <li>Visibilidade de estoque em tempo real;</li>
          <li>Relatórios de Perfomance de acordo com o cliente;</li>
          <li>Concordância e conexão com ERP e totais demandas dos clientes.</li>
        </ul>
      `,

      diferenciais_title: `DIFERENCIAIS`,
      diferenciais_1_title: `Presença Nacional`,
      diferenciais_1_text: `Estruturas estrategicamente posicionadas cobrindo todo o território nacional – mais de R$ 60 milhões de investimentos junto aos Portos de Paranaguá, Itajaí e Suape.`,
      diferenciais_2_title: `Expertise em Comércio Exterior`,
      diferenciais_2_text: `Mais de 20 anos de operação em Comércio Exterior, com operações em todo Brasil.`,
      diferenciais_3_title: `Estrutura Operacional`,
      diferenciais_3_text: `Conselho e Equipe com forte competência e experiência em comércio exterior e logística de veículos e cargas rolantes, com mais de 40 anos de experiência atendendo indústrias automotivas.`,
      diferenciais_4_title: `Tecnologia`,
      diferenciais_4_text: `Software customizado e dedicado para veículos e cargas rolantes, possuindo versão web e mobile. Dispondo informações em tempo real e conexão com RFB, ERP e toda a cadeia logística.`,


      mapa_title: `Onde estamos`,

      mapa_pontos_link_text: `Ver mais`,

      mapa_ponto_1_text: `Suape - PE`,
        mapa_1_image_1: `assets/images/portos-galeria/mapa-1.jpg`,
        mapa_1_subheading: `Suape - PE`,
        mapa_1_about: `SOBRE`,
        mapa_1_text: `
        O Complexo Industrial Portuário de Suape é um dos maiores projetos de desenvolvimento da economia do país. 
        Distante apenas 40 quilômetros de Recife, capital do estado de Pernambuco, Suape é interligado a mais de 160 portos em todos os continentes e se apresenta como o porto público mais estratégico do nordeste brasileiro.
        <br><br>
        Status reforçado a partir da geração de 90% do PIB da região estar concentrado num raio de 800 quilômetros do porto. Confirmando seu posicionamento como um hub port natural, ou seja, porto concentrador e distribuidor de cargas para as regiões nordeste e norte do país.
        <br><br>
        No complexo, o Ascensus Portos dispõe de uma área de 50.000m² e 3.000 vagas estáticas, alcançando um giro de 108.000 veículos anuais. Tendo capacidade para operar qualquer tipo de carga rolante.
        `,

      mapa_ponto_2_text: `Paranaguá - PR`,
        mapa_2_image_1: `assets/images/portos-galeria/mapa-2.jpg`,
        mapa_2_subheading: `Paranaguá - PR`,
        mapa_2_about: `SOBRE`,
        mapa_2_text: `
        Os Portos do Paraná são um complexo portuário formado pelos portos de Paranaguá e Antonina, responsáveis pela movimentação marítima do estado.
        A administração funciona como empresa pública estadual, subordinada à Secretaria de Estado de Infraestrutura e Logística, com convênio de delegação junto ao Governo Federal. 
        <br><br>
        Através do Leilão Nº 01/2020 APPA – PAR12, o Ascensus Portos, já operadora portuária, conquistou o direito de arrendamento da área PAR12, e, através do investimento de mais de R$55 milhões de reais, criou sua principal unidade operacional de terminal de cargas rolantes, com uma área total de 74.149m² e 4.000 vagas estáticas, o terminal possui capacidade de giro de 144.000 veículos anuais.
        <br><br>
        Previsto para iniciar as operações no primeiro semestre de 2023, o terminal se destaca pela sua modernidade, alfandegamento, galpão coberto para carregamento e descarregamento de caminhões e com acesso exclusivo ao cais, tornando suas operações seguras, céleres e eficientes.
        `,

      mapa_ponto_3_text: `Itajaí - SC`,
        mapa_3_image_1: `assets/images/portos-galeria/mapa-3.jpg`,
        mapa_3_image_2: `assets/images/portos-galeria/galeria-1.jpg`,
        mapa_3_image_4: `assets/images/portos-galeria/galeria-3.jpg`,
        mapa_3_image_5: `assets/images/portos-galeria/galeria-4.jpg`,
        mapa_3_image_6: `assets/images/portos-galeria/galeria-5.jpg`,
        mapa_3_subheading: `Itajaí - SC`,
        mapa_3_about: `SOBRE`,
        mapa_3_text: `
        O Porto Público de Itajaí, instalado à direita da Foz do Rio Itajaí, dispõe de infraestrutura completa para a realização de serviços de importação e exportação de cargas rolantes para a região.
        <br><br>
        O Ascensus Portos atua em conjunto com as autoridades e empresas locais na zona alfandegada portuária e não alfandegada (RAC), fazendo a movimentação e armazenagem de mercadorias rolantes.
        <br><br>
        Disponde de 35.000m² para realizar a movimentação de até 2.400 vagas estáticas para veículos parqueados, alcançando um giro de 86.400 veículos ao ano.
        `,
    },
    en: {
      portos_title: 'Ascensus <small>Portos</small>',
      portos_subheading: `Sobre`,
      portos_text: `Com início em 2021, a partir da aquisição do arrendamento do 
      terminal portuário PAR12, junto à APPA – Administração dos Portos de
      Paranaguá e Antonina. O Ascensus Portos nasceu com o objetivo 
      principal de modernizar o mercado logístico portuário de cargas Roll-
      On e Roll-Off, visando sempre a excelência operacional. 
      <br><br>
      A atuação da empresa garante primor em atendimento aos seus clientes a partir de sua presença nacional em grandes portos do Brasil, em complemento da sua expertise em comércio exterior e de estruturas altamente qualificadas com foco em tecnologia, segurança e agilidade.
      <br><br>
      Hoje, operando importação e exportação em toda a cadeia logística 
      portuária de cargas rolantes, o Ascensus Portos atua em parceria com
      transportadoras, armadores e clientes, além de realizar serviços de 
      carga e descarga das cegonhas, gestão de pátio e o embarque e 
      desembarque de navios. Dispondo seus trabalhos por todo o Brasil, o 
      Ascensus Portos possui atuação vigente nos portos de Paranaguá-PR, 
      Itajaí-SC e Suape-PE.`,
      portos_video_link: `assets/videos/video-portos.mp4?v1.0.1`,

      servicos_title: `SERVIÇOS`,
      servicos_subheading: `O Ascensus Portos possibilita operar cargas rolantes em qualquer lugar do Brasil!`,
      servicos_operacoes_logisticas_title: `Operações Logísticas Portuárias`,
      servicos_operacoes_logisticas_text: `
        Serviços relacionados à todas as movimentações e armazenamento nos portos do país, tais como:
        <br><br>
        <ul>
          <li>Coordenação de estiva - Embarque e Desembarque;</li>
          <li>Operações de costado;</li>
          <li>Coordenação de Capatazia – Área alfandegada e Não alfandegada;</li>
          <li>Carregamento de carretas no costado;</li>
          <li>Movimentação entre pátio e rampa do navio;</li>
          <li>Coordenação de fluxos e processos;</li>
          <li>Acompanhamento de operações em tempo real;</li>
          <li>Vistoria de Qualidade;</li>
          <li>Preparação de veículos para transporte final – PDI;</li>
          <li>Concordância e conexão com RFB, ERP e totais demandas dos clientes.</li>
        </ul>
      `,

      servicos_gestao_title: `Gestão de Pátios e Plantas`,
      servicos_gestao_text: `
        Serviços relacionados à todas as movimentações e armazenamento em pátios de cargas rolantes do país, tais como:
        <br><br>
        <ul>
          <li>Movimentação e Armazenagem de carga:</li>
          <li>Gestão de estoque;</li>
          <li>Coordenação de Carga e Descarga;</li>
          <li>Preparação de veículos para transporte final:</li>
          <li>PDI e PPO;</li>
          <li>Acessorização e Reparos rápidos;</li>
          <li>Visibilidade de estoque em tempo real;</li>
          <li>Relatórios de Perfomance de acordo com o cliente;</li>
          <li>Concordância e conexão com ERP e totais demandas dos clientes.</li>
        </ul>
      `,

      diferenciais_title: `DIFERENCIAIS`,
      diferenciais_1_title: `Presença Nacional`,
      diferenciais_1_text: `Estruturas estrategicamente posicionadas cobrindo todo o território nacional – mais de R$ 60 milhões de investimentos junto aos Portos de Paranaguá, Itajaí e Suape.`,
      diferenciais_2_title: `Expertise em Comércio Exterior`,
      diferenciais_2_text: `Mais de 20 anos de operação em Comércio Exterior, com operações em todo Brasil.`,
      diferenciais_3_title: `Estrutura Operacional`,
      diferenciais_3_text: `Conselho e Equipe com forte competência e experiência em comércio exterior e logística de veículos e cargas rolantes, com mais de 40 anos de experiência atendendo indústrias automotivas.`,
      diferenciais_4_title: `Tecnologia`,
      diferenciais_4_text: `Software customizado e dedicado para veículos e cargas rolantes, possuindo versão web e mobile. Dispondo informações em tempo real e conexão com RFB, ERP e toda a cadeia logística.`,


      mapa_title: `Onde estamos`,

      mapa_pontos_link_text: `Ver mais`,

      mapa_ponto_1_text: `Suape - PE`,
        mapa_1_image_1: `assets/images/portos-galeria/mapa-1.jpg`,
        mapa_1_subheading: `Suape - PE`,
        mapa_1_about: `SOBRE`,
        mapa_1_text: `
        O Complexo Industrial Portuário de Suape é um dos maiores projetos de desenvolvimento da economia do país. 
        Distante apenas 40 quilômetros de Recife, capital do estado de Pernambuco, Suape é interligado a mais de 160 portos em todos os continentes e se apresenta como o porto público mais estratégico do nordeste brasileiro.
        <br><br>
        Status reforçado a partir da geração de 90% do PIB da região estar concentrado num raio de 800 quilômetros do porto. Confirmando seu posicionamento como um hub port natural, ou seja, porto concentrador e distribuidor de cargas para as regiões nordeste e norte do país.
        <br><br>
        No complexo, o Ascensus Portos dispõe de uma área de 50.000m² e 3.000 vagas estáticas, alcançando um giro de 108.000 veículos anuais. Tendo capacidade para operar qualquer tipo de carga rolante.
        `,

      mapa_ponto_2_text: `Paranaguá - PR`,
        mapa_2_image_1: `assets/images/portos-galeria/mapa-2.jpg`,
        mapa_2_subheading: `Paranaguá - PR`,
        mapa_2_about: `SOBRE`,
        mapa_2_text: `
        Os Portos do Paraná são um complexo portuário formado pelos portos de Paranaguá e Antonina, responsáveis pela movimentação marítima do estado.
        A administração funciona como empresa pública estadual, subordinada à Secretaria de Estado de Infraestrutura e Logística, com convênio de delegação junto ao Governo Federal. 
        <br><br>
        Através do Leilão Nº 01/2020 APPA – PAR12, o Ascensus Portos, já operadora portuária, conquistou o direito de arrendamento da área PAR12, e, através do investimento de mais de R$55 milhões de reais, criou sua principal unidade operacional de terminal de cargas rolantes, com uma área total de 74.149m² e 4.000 vagas estáticas, o terminal possui capacidade de giro de 144.000 veículos anuais.
        <br><br>
        Previsto para iniciar as operações no primeiro semestre de 2023, o terminal se destaca pela sua modernidade, alfandegamento, galpão coberto para carregamento e descarregamento de caminhões e com acesso exclusivo ao cais, tornando suas operações seguras, céleres e eficientes.
        `,

      mapa_ponto_3_text: `Itajaí - SC`,
        mapa_3_image_1: `assets/images/portos-galeria/mapa-3.jpg`,
        mapa_3_image_2: `assets/images/portos-galeria/galeria-1.jpg`,
        mapa_3_image_4: `assets/images/portos-galeria/galeria-3.jpg`,
        mapa_3_image_5: `assets/images/portos-galeria/galeria-4.jpg`,
        mapa_3_image_6: `assets/images/portos-galeria/galeria-5.jpg`,
        mapa_3_subheading: `Itajaí - SC`,
        mapa_3_about: `SOBRE`,
        mapa_3_text: `
        O Porto Público de Itajaí, instalado à direita da Foz do Rio Itajaí, dispõe de infraestrutura completa para a realização de serviços de importação e exportação de cargas rolantes para a região.
        <br><br>
        O Ascensus Portos atua em conjunto com as autoridades e empresas locais na zona alfandegada portuária e não alfandegada (RAC), fazendo a movimentação e armazenagem de mercadorias rolantes.
        <br><br>
        Disponde de 35.000m² para realizar a movimentação de até 2.400 vagas estáticas para veículos parqueados, alcançando um giro de 86.400 veículos ao ano.
        `,
    },
    es: {
      portos_title: 'Ascensus <small>Portos</small>',
      portos_subheading: `Sobre`,
      portos_text: `Com início em 2021, a partir da aquisição do arrendamento do 
      terminal portuário PAR12, junto à APPA – Administração dos Portos de
      Paranaguá e Antonina. O Ascensus Portos nasceu com o objetivo 
      principal de modernizar o mercado logístico portuário de cargas Roll-
      On e Roll-Off, visando sempre a excelência operacional. 
      <br><br>
      A atuação da empresa garante primor em atendimento aos seus clientes a partir de sua presença nacional em grandes portos do Brasil, em complemento da sua expertise em comércio exterior e de estruturas altamente qualificadas com foco em tecnologia, segurança e agilidade.
      <br><br>
      Hoje, operando importação e exportação em toda a cadeia logística 
      portuária de cargas rolantes, o Ascensus Portos atua em parceria com
      transportadoras, armadores e clientes, além de realizar serviços de 
      carga e descarga das cegonhas, gestão de pátio e o embarque e 
      desembarque de navios. Dispondo seus trabalhos por todo o Brasil, o 
      Ascensus Portos possui atuação vigente nos portos de Paranaguá-PR, 
      Itajaí-SC e Suape-PE.`,
      portos_video_link: `assets/videos/video-portos.mp4?v1.0.1`,

      servicos_title: `SERVIÇOS`,
      servicos_subheading: `O Ascensus Portos possibilita operar cargas rolantes em qualquer lugar do Brasil!`,
      servicos_operacoes_logisticas_title: `Operações Logísticas Portuárias`,
      servicos_operacoes_logisticas_text: `
        Serviços relacionados à todas as movimentações e armazenamento nos portos do país, tais como:
        <br><br>
        <ul>
          <li>Coordenação de estiva - Embarque e Desembarque;</li>
          <li>Operações de costado;</li>
          <li>Coordenação de Capatazia – Área alfandegada e Não alfandegada;</li>
          <li>Carregamento de carretas no costado;</li>
          <li>Movimentação entre pátio e rampa do navio;</li>
          <li>Coordenação de fluxos e processos;</li>
          <li>Acompanhamento de operações em tempo real;</li>
          <li>Vistoria de Qualidade;</li>
          <li>Preparação de veículos para transporte final – PDI;</li>
          <li>Concordância e conexão com RFB, ERP e totais demandas dos clientes.</li>
        </ul>
      `,

      servicos_gestao_title: `Gestão de Pátios e Plantas`,
      servicos_gestao_text: `
        Serviços relacionados à todas as movimentações e armazenamento em pátios de cargas rolantes do país, tais como:
        <br><br>
        <ul>
          <li>Movimentação e Armazenagem de carga:</li>
          <li>Gestão de estoque;</li>
          <li>Coordenação de Carga e Descarga;</li>
          <li>Preparação de veículos para transporte final:</li>
          <li>PDI e PPO;</li>
          <li>Acessorização e Reparos rápidos;</li>
          <li>Visibilidade de estoque em tempo real;</li>
          <li>Relatórios de Perfomance de acordo com o cliente;</li>
          <li>Concordância e conexão com ERP e totais demandas dos clientes.</li>
        </ul>
      `,

      diferenciais_title: `DIFERENCIAIS`,
      diferenciais_1_title: `Presença Nacional`,
      diferenciais_1_text: `Estruturas estrategicamente posicionadas cobrindo todo o território nacional – mais de R$ 60 milhões de investimentos junto aos Portos de Paranaguá, Itajaí e Suape.`,
      diferenciais_2_title: `Expertise em Comércio Exterior`,
      diferenciais_2_text: `Mais de 20 anos de operação em Comércio Exterior, com operações em todo Brasil.`,
      diferenciais_3_title: `Estrutura Operacional`,
      diferenciais_3_text: `Conselho e Equipe com forte competência e experiência em comércio exterior e logística de veículos e cargas rolantes, com mais de 40 anos de experiência atendendo indústrias automotivas.`,
      diferenciais_4_title: `Tecnologia`,
      diferenciais_4_text: `Software customizado e dedicado para veículos e cargas rolantes, possuindo versão web e mobile. Dispondo informações em tempo real e conexão com RFB, ERP e toda a cadeia logística.`,


      mapa_title: `Onde estamos`,

      mapa_pontos_link_text: `Ver mais`,

      mapa_ponto_1_text: `Suape - PE`,
        mapa_1_image_1: `assets/images/portos-galeria/mapa-1.jpg`,
        mapa_1_subheading: `Suape - PE`,
        mapa_1_about: `SOBRE`,
        mapa_1_text: `
        O Complexo Industrial Portuário de Suape é um dos maiores projetos de desenvolvimento da economia do país. 
        Distante apenas 40 quilômetros de Recife, capital do estado de Pernambuco, Suape é interligado a mais de 160 portos em todos os continentes e se apresenta como o porto público mais estratégico do nordeste brasileiro.
        <br><br>
        Status reforçado a partir da geração de 90% do PIB da região estar concentrado num raio de 800 quilômetros do porto. Confirmando seu posicionamento como um hub port natural, ou seja, porto concentrador e distribuidor de cargas para as regiões nordeste e norte do país.
        <br><br>
        No complexo, o Ascensus Portos dispõe de uma área de 50.000m² e 3.000 vagas estáticas, alcançando um giro de 108.000 veículos anuais. Tendo capacidade para operar qualquer tipo de carga rolante.
        `,

      mapa_ponto_2_text: `Paranaguá - PR`,
        mapa_2_image_1: `assets/images/portos-galeria/mapa-2.jpg`,
        mapa_2_subheading: `Paranaguá - PR`,
        mapa_2_about: `SOBRE`,
        mapa_2_text: `
        Os Portos do Paraná são um complexo portuário formado pelos portos de Paranaguá e Antonina, responsáveis pela movimentação marítima do estado.
        A administração funciona como empresa pública estadual, subordinada à Secretaria de Estado de Infraestrutura e Logística, com convênio de delegação junto ao Governo Federal. 
        <br><br>
        Através do Leilão Nº 01/2020 APPA – PAR12, o Ascensus Portos, já operadora portuária, conquistou o direito de arrendamento da área PAR12, e, através do investimento de mais de R$55 milhões de reais, criou sua principal unidade operacional de terminal de cargas rolantes, com uma área total de 74.149m² e 4.000 vagas estáticas, o terminal possui capacidade de giro de 144.000 veículos anuais.
        <br><br>
        Previsto para iniciar as operações no primeiro semestre de 2023, o terminal se destaca pela sua modernidade, alfandegamento, galpão coberto para carregamento e descarregamento de caminhões e com acesso exclusivo ao cais, tornando suas operações seguras, céleres e eficientes.
        `,

      mapa_ponto_3_text: `Itajaí - SC`,
        mapa_3_image_1: `assets/images/portos-galeria/mapa-3.jpg`,
        mapa_3_image_2: `assets/images/portos-galeria/galeria-1.jpg`,
        mapa_3_image_4: `assets/images/portos-galeria/galeria-3.jpg`,
        mapa_3_image_5: `assets/images/portos-galeria/galeria-4.jpg`,
        mapa_3_image_6: `assets/images/portos-galeria/galeria-5.jpg`,
        mapa_3_subheading: `Itajaí - SC`,
        mapa_3_about: `SOBRE`,
        mapa_3_text: `
        O Porto Público de Itajaí, instalado à direita da Foz do Rio Itajaí, dispõe de infraestrutura completa para a realização de serviços de importação e exportação de cargas rolantes para a região.
        <br><br>
        O Ascensus Portos atua em conjunto com as autoridades e empresas locais na zona alfandegada portuária e não alfandegada (RAC), fazendo a movimentação e armazenagem de mercadorias rolantes.
        <br><br>
        Disponde de 35.000m² para realizar a movimentação de até 2.400 vagas estáticas para veículos parqueados, alcançando um giro de 86.400 veículos ao ano.
        `,
    },
    all: {
      portos_title: 'Ascensus <small>Portos</small>',
      portos_subheading: `Sobre`,
      portos_text: `Com início em 2021, a partir da aquisição do arrendamento do 
      terminal portuário PAR12, junto à APPA – Administração dos Portos de
      Paranaguá e Antonina. O Ascensus Portos nasceu com o objetivo 
      principal de modernizar o mercado logístico portuário de cargas Roll-
      On e Roll-Off, visando sempre a excelência operacional. 
      <br><br>
      A atuação da empresa garante primor em atendimento aos seus clientes a partir de sua presença nacional em grandes portos do Brasil, em complemento da sua expertise em comércio exterior e de estruturas altamente qualificadas com foco em tecnologia, segurança e agilidade.
      <br><br>
      Hoje, operando importação e exportação em toda a cadeia logística 
      portuária de cargas rolantes, o Ascensus Portos atua em parceria com
      transportadoras, armadores e clientes, além de realizar serviços de 
      carga e descarga das cegonhas, gestão de pátio e o embarque e 
      desembarque de navios. Dispondo seus trabalhos por todo o Brasil, o 
      Ascensus Portos possui atuação vigente nos portos de Paranaguá-PR, 
      Itajaí-SC e Suape-PE.`,
      portos_video_link: `assets/videos/video-portos.mp4?v1.0.1`,

      servicos_title: `SERVIÇOS`,
      servicos_subheading: `O Ascensus Portos possibilita operar cargas rolantes em qualquer lugar do Brasil!`,
      servicos_operacoes_logisticas_title: `Operações Logísticas Portuárias`,
      servicos_operacoes_logisticas_text: `
        Serviços relacionados à todas as movimentações e armazenamento nos portos do país, tais como:
        <br><br>
        <ul>
          <li>Coordenação de estiva - Embarque e Desembarque;</li>
          <li>Operações de costado;</li>
          <li>Coordenação de Capatazia – Área alfandegada e Não alfandegada;</li>
          <li>Carregamento de carretas no costado;</li>
          <li>Movimentação entre pátio e rampa do navio;</li>
          <li>Coordenação de fluxos e processos;</li>
          <li>Acompanhamento de operações em tempo real;</li>
          <li>Vistoria de Qualidade;</li>
          <li>Preparação de veículos para transporte final – PDI;</li>
          <li>Concordância e conexão com RFB, ERP e totais demandas dos clientes.</li>
        </ul>
      `,

      servicos_gestao_title: `Gestão de Pátios e Plantas`,
      servicos_gestao_text: `
        Serviços relacionados à todas as movimentações e armazenamento em pátios de cargas rolantes do país, tais como:
        <br><br>
        <ul>
          <li>Movimentação e Armazenagem de carga:</li>
          <li>Gestão de estoque;</li>
          <li>Coordenação de Carga e Descarga;</li>
          <li>Preparação de veículos para transporte final:</li>
          <li>PDI e PPO;</li>
          <li>Acessorização e Reparos rápidos;</li>
          <li>Visibilidade de estoque em tempo real;</li>
          <li>Relatórios de Perfomance de acordo com o cliente;</li>
          <li>Concordância e conexão com ERP e totais demandas dos clientes.</li>
        </ul>
      `,

      diferenciais_title: `DIFERENCIAIS`,
      diferenciais_1_title: `Presença Nacional`,
      diferenciais_1_text: `Estruturas estrategicamente posicionadas cobrindo todo o território nacional – mais de R$ 60 milhões de investimentos junto aos Portos de Paranaguá, Itajaí e Suape.`,
      diferenciais_2_title: `Expertise em Comércio Exterior`,
      diferenciais_2_text: `Mais de 20 anos de operação em Comércio Exterior, com operações em todo Brasil.`,
      diferenciais_3_title: `Estrutura Operacional`,
      diferenciais_3_text: `Conselho e Equipe com forte competência e experiência em comércio exterior e logística de veículos e cargas rolantes, com mais de 40 anos de experiência atendendo indústrias automotivas.`,
      diferenciais_4_title: `Tecnologia`,
      diferenciais_4_text: `Software customizado e dedicado para veículos e cargas rolantes, possuindo versão web e mobile. Dispondo informações em tempo real e conexão com RFB, ERP e toda a cadeia logística.`,


      mapa_title: `Onde estamos`,

      mapa_pontos_link_text: `Ver mais`,

      mapa_ponto_1_text: `Suape - PE`,
        mapa_1_image_1: `assets/images/portos-galeria/mapa-1.jpg`,
        mapa_1_subheading: `Suape - PE`,
        mapa_1_about: `SOBRE`,
        mapa_1_text: `
        O Complexo Industrial Portuário de Suape é um dos maiores projetos de desenvolvimento da economia do país. 
        Distante apenas 40 quilômetros de Recife, capital do estado de Pernambuco, Suape é interligado a mais de 160 portos em todos os continentes e se apresenta como o porto público mais estratégico do nordeste brasileiro.
        <br><br>
        Status reforçado a partir da geração de 90% do PIB da região estar concentrado num raio de 800 quilômetros do porto. Confirmando seu posicionamento como um hub port natural, ou seja, porto concentrador e distribuidor de cargas para as regiões nordeste e norte do país.
        <br><br>
        No complexo, o Ascensus Portos dispõe de uma área de 50.000m² e 3.000 vagas estáticas, alcançando um giro de 108.000 veículos anuais. Tendo capacidade para operar qualquer tipo de carga rolante.
        `,

      mapa_ponto_2_text: `Paranaguá - PR`,
        mapa_2_image_1: `assets/images/portos-galeria/mapa-2.jpg`,
        mapa_2_subheading: `Paranaguá - PR`,
        mapa_2_about: `SOBRE`,
        mapa_2_text: `
        Os Portos do Paraná são um complexo portuário formado pelos portos de Paranaguá e Antonina, responsáveis pela movimentação marítima do estado.
        A administração funciona como empresa pública estadual, subordinada à Secretaria de Estado de Infraestrutura e Logística, com convênio de delegação junto ao Governo Federal. 
        <br><br>
        Através do Leilão Nº 01/2020 APPA – PAR12, o Ascensus Portos, já operadora portuária, conquistou o direito de arrendamento da área PAR12, e, através do investimento de mais de R$55 milhões de reais, criou sua principal unidade operacional de terminal de cargas rolantes, com uma área total de 74.149m² e 4.000 vagas estáticas, o terminal possui capacidade de giro de 144.000 veículos anuais.
        <br><br>
        Previsto para iniciar as operações no primeiro semestre de 2023, o terminal se destaca pela sua modernidade, alfandegamento, galpão coberto para carregamento e descarregamento de caminhões e com acesso exclusivo ao cais, tornando suas operações seguras, céleres e eficientes.
        `,

      mapa_ponto_3_text: `Itajaí - SC`,
        mapa_3_image_1: `assets/images/portos-galeria/mapa-3.jpg`,
        mapa_3_image_2: `assets/images/portos-galeria/galeria-1.jpg`,
        mapa_3_image_4: `assets/images/portos-galeria/galeria-3.jpg`,
        mapa_3_image_5: `assets/images/portos-galeria/galeria-4.jpg`,
        mapa_3_image_6: `assets/images/portos-galeria/galeria-5.jpg`,
        mapa_3_subheading: `Itajaí - SC`,
        mapa_3_about: `SOBRE`,
        mapa_3_text: `
        O Porto Público de Itajaí, instalado à direita da Foz do Rio Itajaí, dispõe de infraestrutura completa para a realização de serviços de importação e exportação de cargas rolantes para a região.
        <br><br>
        O Ascensus Portos atua em conjunto com as autoridades e empresas locais na zona alfandegada portuária e não alfandegada (RAC), fazendo a movimentação e armazenagem de mercadorias rolantes.
        <br><br>
        Disponde de 35.000m² para realizar a movimentação de até 2.400 vagas estáticas para veículos parqueados, alcançando um giro de 86.400 veículos ao ano.
        `,
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
    setTimeout(() => {
      $('.modal-video video source:nth-child(1)').attr('src', $('.video video source:nth-child(1)').attr('src'));
      $('.modal-video video source:nth-child(2)').attr('src', $('.video video source:nth-child(2)').attr('src'));
      $('.modal-video video')[0].load();
      $('.modal-video .close').attr('style', `
        outline: none`
      );
      $('.modal-mapa .close i').attr('style', `
        font-size: 32px`
      );


      $('.mapa-3 .mapa-carousel-1').owlCarousel({
        loop: true,
        nav: true,
        items: 1,
        dots: false,
        navText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"],
        rewindNav: true,
        slideBy: 2,
      });
      
      $('.mapa-3 .mapa-carousel-2').owlCarousel({
        margin:16,
        loop: true,
        nav: false,
        items: 6,
        dots: false,
        rewindNav: true,
        touchDrag: false,
        mouseDrag: false,
        slideBy: 2,
      });
      $('.mapa-number .mapa-carousel-1').on('translate.owl.carousel', function(e) {
        $('.mapa-number .mapa-carousel-2').trigger('to.owl.carousel',e.item.index + 1);
      });
      $('.owl-galeria .owl-nav').removeClass('disabled').attr('style', `
        display: flex;
        margin: 0;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);    
        height: 0;
        align-items: center;`
      );

      $('.mapa-number .mapa-carousel-1 .owl-nav').removeClass('disabled').attr('style', `
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;`
      );

      if(window.location.pathname=='/portos'){
        $('.portos-privacy-button').addClass('active');
        $('#qualityModal ul').attr('style', 'list-style: none');
      }
    }, 1000);
  }

  abrirMapa(number) {
    $('.modal-mapa .mapa-number').removeAttr('style');
    $('.modal-mapa .mapa-'+number).attr('style', 'display: block');
    // $('.modal-mapa').attr('style', 'display: block');
    // setTimeout(function(){
    //   $('.modal-mapa').attr('style', 'display: block; opacity: 1');
    // }, 1);
    // $('.modal-mapa .modal-dialog').animate({height: 'toggle'}, 500);
    $('.modal-mapa').modal('show');
    $('body').addClass('overflow-hidden');

    if(window.innerWidth<768){
      $('.modal-mapa .mapa-'+number+' .mapa-carousel-1').attr('style', 'display: flex; max-height: 27vh');
    }else{
      $('.modal-mapa .mapa-'+number+' .mapa-carousel-1').attr('style', 'display: flex; max-height: 55vh');
      $('.modal-mapa .mapa-'+number+' .mapa-carousel-2').attr('style', 'display: flex; max-height: 50px');
    }
    setTimeout(function(){
      $('.modal-mapa .mapa-'+number+' .mapa-carousel-1').removeAttr('style');
      $('.modal-mapa .mapa-'+number+' .mapa-carousel-2').removeAttr('style');
    }, 500);
  }

  fecharMapa() {
    $('.modal-mapa').modal('hide');
    $('body').removeClass('overflow-hidden');

    setTimeout(function(){
      $('.modal-mapa').removeAttr('style');
      $('.modal-mapa .mapa-carousel-1').removeAttr('style');
      $('.modal-mapa .mapa-carousel-2').removeAttr('style');
    }, 300);
  }
  

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }
}
