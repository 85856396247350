import { Component, OnInit } from '@angular/core';
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-planejamento',
  templateUrl: './planejamento.component.html',
  styleUrls: ['./planejamento.component.scss']
})
export class PlanejamentoComponent implements OnInit {
  traducao: any = {};
  traducaoTermos = {
    br: {
      planejamento: `
      Nós planejamos o crescimento escalonado,<br>
      <small>com expertise e continuidade, indo além do óbvio e gerando valor para nossos
      clientes.</small>`,
      economizamos: `
          Economizamos tempo e custos com apenas 4 dias nos processos de planejamento. Somos qualificados,
          globais e estamos preparados para escalonar com você para
          todas as partes do mundo. Crescemos na medida da sua necessidade, com governança corporativa e
          equipe especializada para gerar cada vez mais negócios.
      `,
      gerenciamos: `
          Gerenciamos todos os processos com um time de mais de 160 colaboradores, onde conseguimos tudo
          isso devido ao comprometimento das fantásticas pessoas do nosso time!
      `,
      canalVerde: `
      de canal verde. <br>
      Agilidade e rapidez nos processos de desembaraço.`,
      nossosClientes: `
      dos nossos clientes estão <b>satisfeiros ou muito satisfeitos</b> com nossas
      operações.
      `,
      nossaPontuacao: `
      é nossa pontuação do indice <b>Net Promote Score - NPS.</b>
      `
    },
    en: {
      planejamento: `
      We plan for staggered growth,<br>
      <small>with expertise and continuity, going beyond the obvious and generating value for our
      customers.</small>`,
      economizamos: `
      We save time and costs with just 4 days in the planning processes. We are qualified,
      global and we are prepared to scale with you to
      all parts of the world. We grow as needed, with corporate governance and
      specialized team to generate more and more business.
      `,
      gerenciamos: `
      We manage all processes with a team of more than 160 employees, where we get everything
      this due to the commitment of the fantastic people of our team!
      `,
      canalVerde: `
      of green channel. <br>
      Agility and speed in the clearance processes.`,
      nossosClientes: `
      of our customers are <b>satisfied or very satisfied</b> with our
      operations.
      `,
      nossaPontuacao: `
      is our index score<b>Net Promote Score - NPS.</b>
      `
    },
    es: {
      planejamento: `
      Planeamos un crecimiento escalonado,<br>
      <small>con experiencia y continuidad, yendo más allá de lo obvio y generando valor para nuestros
      clientes.</small>`,
      economizamos: `
      Ahorramos tiempo y costes con tan solo 4 días en los procesos de planificación. Estamos calificados,
      global y estamos preparados para escalar contigo para
      todas las partes del mundo. Crecemos según sea necesario, con gobierno corporativo y
      equipo especializado para generar cada vez más negocio.
      `,
      gerenciamos: `
      Gestionamos todos los procesos con un equipo de más de 160 empleados, donde conseguimos todo
      ¡Esto debido al compromiso de la fantástica gente de nuestro equipo!
      `,
      canalVerde: `
      de canal verde. <br>
      Agilidad y rapidez en los procesos de despacho.`,
      nossosClientes: `
      de nuestros clientes están <b> satisfechos o muy satisfechos </b> con nuestras operaciones.
      `,
      nossaPontuacao: `
      es nuestra puntuación de la <b> Puntuación de promoción neta - índice NPS.</b>
      `
    },
    all: {
      planejamento: `
      Nós planejamos o crescimento escalonado,<br>
      <small>com expertise e continuidade, indo além do óbvio e gerando valor para nossos
      clientes.</small>`,
      economizamos: `
          Economizamos tempo e custos com apenas 4 dias nos processos de planejamento. Somos qualificados,
          globais e estamos preparados para escalonar com você para
          todas as partes do mundo. Crescemos na medida da sua necessidade, com governança corporativa e
          equipe especializada para gerar cada vez mais negócios.
      `,
      gerenciamos: `
          Gerenciamos todos os processos com um time de mais de 160 colaboradores, onde conseguimos tudo
          isso devido ao comprometimento das fantásticas pessoas do nosso time!
      `,
      canalVerde: `
      de canal verde. <br>
      Agilidade e rapidez nos processos de desembaraço.`,
      nossosClientes: `
      dos nossos clientes estão <b>satisfeiros ou muito satisfeitos</b> com nossas
      operações.
      `,
      nossaPontuacao: `
      é nossa pontuação do indice <b>Net Promote Score - NPS.</b>
      `
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
  }

}
