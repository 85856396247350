import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-text',
  templateUrl: './section-text.component.html',
  styleUrls: ['./section-text.component.scss']
})
export class SectionTextComponent implements OnInit {

  @Input() titulo : string = "";
  @Input() descricao : string = "";
  @Input() background : string = "";
  @Input() bgColor : string = "";

  constructor() { }

  ngOnInit() {
  }

  

}
