import { Component, OnInit, ElementRef } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-governanca-compliance',
  templateUrl: './governanca-compliance.component.html',
  styleUrls: ['./governanca-compliance.component.scss']
})
export class GovernancaComplianceComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      governanca: 'GOVERNANÇA E COMPLIANCE',
      adotamos: `
      Adotamos sistemas para direcionar e monitorar, de forma a incentivar resultados efetivos, os
      quais envolvem o relacionamento entre sócios, conselho de administração, diretoria, órgãos
      de
      fiscalização, controle e demais partes interessadas.`,
      primeirosBlocos: [
        {
          titulo: 'Missão',
          descricao: `Criar e gerir processos, agregando valor com a redução dos custos tributários,
                      financeiros e logísticos dos clientes nas operações no mercado brasileiro.`
        },
        {
          titulo: 'Visão',
          descricao: `Ser reconhecido mundialmente como integrador de negócios na cadeia de importação, 
                      distribuição e exportação de produtos no Brasil até 2025.`
        },
        {
          titulo: 'Nossos valores',
          descricao: `Meritocracia
          Agilidade e velocidade <br>
          Inovação<br>
          Solidez financeira<br>
          Responsabilidade Social<br>
          Cultivar relacionamentos<br>
          Compromisso com a Ética`
        }
      ],
      blocos: [{
        titulo: 'Pilares da Governança',
        descricao: `Acionistas (Sócios)<br>
        Conselho Consultivo <br>
        Gestão <br>
        Auditoria Independente <br>
        Conselho Fiscal <br>
        Conduta E Conflito De Interesses`,
        borda: true
      },
      {
        titulo: 'Transparência',
        descricao: `
        Nosso Grupo atende e está de acordo com todas as normas, leis e regulamentos.`,
        borda: false
      },
      {
        titulo: 'Gestão Financeira',
        descricao: `Crescer com sustentabilidade.<br>
        Gestão de contatos e carteira de clientes.`,
        borda: false
      },
      {
        titulo: 'Relacionamento com o mercado',
        descricao: `
        Fortalecer a marca.<br>
        Ampliar % de share.`,
        borda: true
      },
      {
        titulo: 'Processos',
        descricao: `
        Aproximação com os clientes.<br>
        Criar ofertas que garantam escala.<br>
        Gestão de Compliance.`,
        borda: true
      },
      {
        titulo: 'Pessoas',
        descricao: `
        Desenvolver lideranças com foco em resultados.<br>
        Atrair, desenvolver e reter talentos.<br>
        ●	Selo Great Place to Work`,
        borda: false
      },
      {
        titulo: 'Inovação e Relacionamento',
        descricao: `
        Melhoria contínua dos processos.<br>
        Criatividade e novas soluções.`,
        borda: false
      },

      {
        titulo: 'Garantindo os requisitos do Sistema de Gestão Interno',
        descricao: `
        Garantir o atendimento das leis e regulamentos
        Atender as permissões, licenças e outras autorizações
        Executar ordens, regras ou diretrizes
        Decisões de tribunais de justiça ou administrativos
        Adequação em tratados, convenções e protocolos
        Atender às instruções Normativas
        `,
        borda: true
      },
      {
        titulo: 'Pilares de Comprometimento',
        descricao: `
        Suporte da AD<br>
        Avaliação de Riscos <br>
        Código e Política Compliance <br>
        Controles Internos <br>
        Treinamentos e Comunicação <br>
        Canais de Comunicação e Denúncia <br>
        Investigação Interna <br>
        Duo Diligences <br>
        Monitoramento e Auditoria `,
        borda: true
      },
      {
        titulo: 'Conduta e Ética',
        descricao: `
        Determinamos através de diversas diretrizes as condutas que devem ser executadas 
        pela nossa organização, perante as partes interessadas. 
        `,
        borda: false
      },
      {
        titulo: 'Regulamento Interno',
        descricao: `
        O nosso conjunto de regras define e gerencia a forma de agir de todos os nossos colaboradores.
        `,
        borda: false
      },
      {
        titulo: 'Contribuir faz toda a diferença',
        descricao: `
        Contribuição à comunidade<br>
        Em nosso plano de Governança Corporativa, está demonstrado o quanto
        o Grupo Ascensus se preocupa com as questões que envolvem o meio ambiente, bem-estar e com o desenvolvimento da sociedade. 
        `,
        borda: true
      },

      ]
    },
    en: {
      governanca: 'GOVERNANCE AND COMPLIANCE',
      adotamos: `
      We have adopted systems to direct and monitor, in order to encourage effective results, the
       which involve the relationship between partners, board of directors, executive officers,
       in
       inspection, control and other interested parties.`,
      primeirosBlocos: [
        {
          titulo: 'Mission',
          descricao: `Create and manage processes, adding value by reducing tax costs,
          financial and logistical aspects of operations in the Brazilian market.`
        },
        {
          titulo: 'Vision',
          descricao: `To be recognized worldwide as a business integrator in the import chain,
          distribution and export of products in Brazil until 2025.`
        },
        {
          titulo: 'Our values',
          descricao: `Meritocracy
          Agility and speed<br>
          Innovation<br>
          Financial soundness<br>
          Social responsability<br>
          Cultivate relationships<br>
          Commitment to Ethics`
        }
      ],
      blocos: [{
        titulo: 'Pillars of Governance',
        descricao: `Shareholders (Partners)<br>
        Consulting Board <br>
        Management <br>
        Independent Audit <br>
        Fiscal Council <br>
        Conduct and Conflict of Interest`,
        borda: true
      },
      {
        titulo: 'Transparency',
        descricao: `
        Our Group meets and complies with all rules, laws and regulations.`,
        borda: false
      },
      {
        titulo: 'Financial management',
        descricao: `Grow with sustainability.<br>
        Management of contacts and customer portfolio.`,
        borda: false
      },
      {
        titulo: 'Relationship with the market',
        descricao: `
        Strengthen the brand.<br>
        Increase % of share.`,
        borda: true
      },
      {
        titulo: 'Law Suit',
        descricao: `
        Approach with customers.<br>
        Criar ofertas que garantam escala.<br>
        Compliance Management.`,
        borda: true
      },
      {
        titulo: 'People',
        descricao: `
        Develop leadership with a focus on results.<br>
        Attract, develop and retain talent.<br>
        ●	Great Place to Work stamp`,
        borda: false
      },
      {
        titulo: 'Innovation and Relationship',
        descricao: `
        Continuous process improvement.<br>
        Creativity and new solutions.`,
        borda: false
      },
      {
        titulo: 'Ensuring the requirements of the Internal Management System',
        descricao: `
        Ensure compliance with laws and regulations
         Meet permissions, licenses and other authorizations
         Execute orders, rules or guidelines
         Judicial or administrative court decisions
         Adequacy in treaties, conventions and protocols
         Comply with Normative instructions
        `,
        borda: true
      },
      {
        titulo: 'Pillars of Commitment',
        descricao: `
        AD Support<br>
        Risk assessment <br>
        Compliance Code and Policy<br>
        Internal controls<br>
        Training and Communication<br>
        Communication and Reporting Channels<br>
        Internal Investigation<br>
        Duo Diligences<br>
        Monitoring and Auditing`,
        borda: true
      },
      {
        titulo: 'Conduct and Ethics',
        descricao: `
        We determine through various guidelines the conducts that must be performed
         by our organization, before interested parties. 
        `,
        borda: false
      },
      {
        titulo: 'Internal Regulation',
        descricao: `
        Our set of rules defines and manages the actions of all our employees.
        `,
        borda: false
      },
      {
        titulo: 'Contributing makes all the difference',
        descricao: `
        Contribution to the community <br>
         In our Corporate Governance plan, it is demonstrated how much
         the Ascensus Group is concerned with issues involving the environment, well-being and the development of society.
        `,
        borda: true
      },

      ]
    },
    es: {
      governanca: 'GOBIERNO Y CUMPLIMIENTO',
      adotamos: `
      Hemos adoptado sistemas para dirigir y monitorear, con el fin de incentivar resultados efectivos, la
      que involucran la relación entre socios, junta directiva, ejecutivos,
      en
      inspección, control y otras partes interesadas.`,
      primeirosBlocos: [
        {
          titulo: 'Misión',
          descricao: `Crear y administrar procesos, agregando valor mediante la reducción de costos tributarios,
          Aspectos financieros y logísticos de las operaciones en el mercado brasileño.`
        },
        {
          titulo: 'Visión',
          descricao: `Ser reconocido mundialmente como integrador empresarial en la cadena de importación,
          distribución y exportación de productos en Brasil hasta 2025.`
        },
        {
          titulo: 'Nuestros valores',
          descricao: `Meritocracia
          Agilidad y rapidez <br>
          Innovación<br>
          Solidez financiera<br>
          Responsabilidad social<br>
          Cultivar relaciones<br>
          Compromiso con la Ética`
        }
      ],
      blocos: [{
        titulo: 'Pilares de la gobernanza',
        descricao: `Accionistas (Socios)<br>
        Consejo Consultivo <br>
        Administración <br>
        Auditoría independiente <br>
        Consejo Fiscal <br>
        Conducta y conflicto de intereses`,
        borda: true
      },
      {
        titulo: 'Transparencia',
        descricao: `
        Nuestro Grupo cumple y cumple con todas las reglas, leyes y regulaciones.`,
        borda: false
      },
      {
        titulo: 'Gestión financiera',
        descricao: `Crezca con sostenibilidad.<br>
        Gestión de contactos y cartera de clientes.`,
        borda: false
      },
      {
        titulo: 'Relación con el mercado',
        descricao: `
        Fortalecer la marca.<br>
        Incrementar el % de participación.`,
        borda: true
      },
      {
        titulo: 'Demanda judicial',
        descricao: `
        Acercándonos a los clientes. <br>
         Cree ofertas que garanticen la escala. <br>
         Gestión de cumplimiento.`,
        borda: true
      },
      {
        titulo: 'Personas',
        descricao: `
        Desarrollar liderazgo con enfoque en resultados. <br>
         Atraer, desarrollar y retener talento. <br>
         ● Excelente lugar para trabajar`,
        borda: false
      },
      {
        titulo: 'Innovación y relación',
        descricao: `
        Mejora continua de procesos. <br>
        Creatividad y nuevas soluciones.`,
        borda: false
      },
      {
        titulo: 'Asegurar los requisitos del Sistema de Gestión Interna',
        descricao: `
        Asegurar el cumplimiento de leyes y regulaciones
         Cumplir con permisos, licencias y otras autorizaciones
         Ejecutar órdenes, reglas o pautas
         Decisiones de tribunales judiciales o administrativos
         Adecuación de tratados, convenciones y protocolos
         Cumplir con las instrucciones normativas
        `,
        borda: true
      },
      {
        titulo: 'Pilares de compromiso',
        descricao: `
        Soporte AD<br>
        Evaluación de riesgos<br>
        Política y código de cumplimiento <br>
        Controles internos <br>
        Capacitación y comunicación <br>
        Canales de comunicación y denuncia <br>
        Investigación interna <br>
        Diligencias de dúo <br>
        Monitoramento e Auditoria `,
        borda: true
      },
      {
        titulo: 'Conduta e Ética',
        descricao: `
        Determinamos a través de diversas pautas las conductas que se deben realizar
         por nuestra organización, ante los interesados.
        `,
        borda: false
      },
      {
        titulo: 'Regulación interna',
        descricao: `
        Nuestro conjunto de reglas define y gestiona las acciones de todos nuestros empleados.
        `,
        borda: false
      },
      {
        titulo: 'Contribuir marca la diferencia',
        descricao: `
        Contribución a la comunidad <br>
         En nuestro plan de Gobierno Corporativo se demuestra cuánto
         el Grupo Ascenso se ocupa de cuestiones relacionadas con el medio ambiente, el bienestar y el desarrollo de la sociedad.
        `,
        borda: true
      },

      ]
    },
    all: {
      governanca: 'GOVERNANÇA E COMPLIANCE',
      adotamos: `
      Adotamos sistemas para direcionar e monitorar, de forma a incentivar resultados efetivos, os
      quais envolvem o relacionamento entre sócios, conselho de administração, diretoria, órgãos
      de
      fiscalização, controle e demais partes interessadas.`,
      primeirosBlocos: [
        {
          titulo: 'Missão',
          descricao: `Criar e gerir processos, agregando valor com a redução dos custos tributários,
                      financeiros e logísticos dos clientes nas operações no mercado brasileiro.`
        },
        {
          titulo: 'Visão',
          descricao: `Ser reconhecido mundialmente como integrador de negócios na cadeia de importação, 
                      distribuição e exportação de produtos no Brasil até 2025.`
        },
        {
          titulo: 'Nossos valores',
          descricao: `Meritocracia
          Agilidade e velocidade 
          Inovação
          Solidez financeira
          Responsabilidade Social
          Cultivar relacionamentos
          Compromisso com a Ética`
        }
      ],
      blocos: [{
        titulo: 'Pilares da Governança',
        descricao: `Acionistas (Sócios)
        Conselho Consultivo 
        Gestão 
        Auditoria Independente 
        Conselho Fiscal 
        Conduta E Conflito De Interesses`,
        borda: true
      },
      {
        titulo: 'Transparência',
        descricao: `
        Nosso Grupo atende e está de acordo com todas as normas, leis e regulamentos.`,
        borda: false
      },
      {
        titulo: 'Gestão Financeira',
        descricao: `Crescer com sustentabilidade.
        Gestão de contatos e carteira de clientes.`,
        borda: false
      },
      {
        titulo: 'Relacionamento com o mercado',
        descricao: `
        Fortalecer a marca.
        Ampliar % de share.`,
        borda: true
      },
      {
        titulo: 'Processos',
        descricao: `
        Aproximação com os clientes.
        Criar ofertas que garantam escala.
        Gestão de Compliance.`,
        borda: true
      },
      {
        titulo: 'Pessoas',
        descricao: `
        Desenvolver lideranças com foco em resultados.
        Atrair, desenvolver e reter talentos.
        ●	Selo Great Place to Work`,
        borda: false
      },
      {
        titulo: 'Inovação e Relacionamento',
        descricao: `
        Melhoria contínua dos processos.
        Criatividade e novas soluções.`,
        borda: false
      },

      {
        titulo: 'Garantindo os requisitos do Sistema de Gestão Interno',
        descricao: `
        Garantir o atendimento das leis e regulamentos
        Atender as permissões, licenças e outras autorizações
        Executar ordens, regras ou diretrizes
        Decisões de tribunais de justiça ou administrativos
        Adequação em tratados, convenções e protocolos
        Atender às instruções Normativas
        `,
        borda: true
      },
      {
        titulo: 'Pilares de Comprometimento',
        descricao: `
        Suporte da AD
        Avaliação de Riscos 
        Código e Política Compliance 
        Controles Internos 
        Treinamentos e Comunicação 
        Canais de Comunicação e Denúncia 
        Investigação Interna 
        Duo Diligences 
        Monitoramento e Auditoria `,
        borda: true
      },
      {
        titulo: 'Conduta e Ética',
        descricao: `
        Determinamos através de diversas diretrizes as condutas que devem ser executadas 
        pela nossa organização, perante as partes interessadas. 
        `,
        borda: false
      },
      {
        titulo: 'Regulamento Interno',
        descricao: `
        O nosso conjunto de regras define e gerencia a forma de agir de todos os nossos colaboradores.
        `,
        borda: false
      },
      {
        titulo: 'Contribuir faz toda a diferença',
        descricao: `
        Contribuição à comunidade
        Em nosso plano de Governança Corporativa, está demonstrado o quanto
        o Grupo Ascensus se preocupa com as questões que envolvem o meio ambiente, bem-estar e com o desenvolvimento da sociedade. 
        `,
        borda: true
      },

      ]
    },
  };


  constructor(private elementRef: ElementRef) {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }






  ngOnInit() {

    $(this.elementRef.nativeElement).find('.scrollbar-external').scrollbar({
      "autoScrollSize": false,
      "scrollx": $(this.elementRef.nativeElement).find('.external-scroll_x'),
      "scrolly": $(this.elementRef.nativeElement).find('.external-scroll_y')
    });
  }
}
