import { Component, OnInit } from '@angular/core';
import { Api } from 'src/app/app.services';
declare var idiomaEscolhido: any;

@Component({
  selector: 'app-investidores',
  templateUrl: './investidores.component.html',
  styleUrls: ['./investidores.component.scss']
})
export class InvestidoresComponent implements OnInit {

  dados: any = {};
  traducao: any = {};
  traducaoTermos = {
    br: {
      investidores: 'INVESTIDORES',
      empresa: 'Ascensus Gestão e Participações S/A',
      central: 'Central de resultados',
      arquivos: 'Demonstrações financeiras, notas explicativas e relatório dos auditores independentes'
    },
    en: {
      investidores: 'INVESTIDORES',
      empresa: 'Ascensus Gestão e Participações S/A',
      central: 'Central de resultados',
      arquivos: 'Demonstrações financeiras, notas explicativas e relatório dos auditores independentes'
    },
    es: {
      investidores: 'INVESTIDORES',
      empresa: 'Ascensus Gestão e Participações S/A',
      central: 'Central de resultados',
      arquivos: 'Demonstrações financeiras, notas explicativas e relatório dos auditores independentes'
    },
    all: {
      investidores: 'INVESTIDORES',
      empresa: 'Ascensus Gestão e Participações S/A',
      central: 'Central de resultados',
      arquivos: 'Demonstrações financeiras, notas explicativas e relatório dos auditores independentes'
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
  }

}
