import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-quem-somos',
  templateUrl: './quem-somos.component.html',
  styleUrls: ['./quem-somos.component.scss']
})
export class QuemSomosComponent implements OnInit {


  traducao: any = {};
  traducaoTermos = {
    br: {
      nos: 'NÓS SOMOS ASCENSUS.',
      fundada: `
      Fundada em 2001 na cidade de Joinville - Santa Catarina, por iniciativa de Vanderlei Palhano da
      Cruz, o Ascensus Group iniciou sua atuação no mercado brasileiro. Ao longo dos anos novas
      filiais foram constituídas em vários estados, visando sempre a excelência em gestão tributária e
      financeira.`,
      atualmente: `
      Atualmente, com uma infraestrutura moderna, equipe especializada e com experiência de mais de 20
      anos de seus executivos e consultores, o Ascensus Group tem como objetivo a redução dos custos
      tributários, financeiros e logísticos das operações de comércio exterior global.`,
      emNossoDNA: `
      Em nosso DNA, está o compromisso de tornar os clientes mais competitivos em seus mercados, por
      meio de soluções construídas com foco em segurança, tecnologia, essencialidade e agilidade.
      Nossa razão de ser!`,
      expansao: `Expansão`,
      crescimento: `
      Com crescimento em ritmo acelerado, nos destacamos no mercado global atuando com excelentes
      resultados no gerenciamento de toda a cadeia do comércio internacional, através da prestação de
      serviços personalizados, de soluções direcionadas para atender as necessidades de cada empresa e
      da busca contínua por melhores performances nas importações e exportações.`,
      hojeOperando: `
      Hoje, operando em escala global, com escritórios sediados no Brasil, EUA e Europa, atendemos com
      excelência empresas de médio e grande porte nos mais diversos segmentos, como: alimentício,
      automotivo, cerâmico, eletroeletrônico, energético, fármaco, de infraestrutura, náutico,
      plástico, químico, siderúrgico e varejo.`,
      nossoProposito: `
      Nosso propósito é possibilitar, de forma sustentável, que os nossos CLIENTES e PARCEIROS sejam
      mais competitivos em seus segmentos, de forma a criar e fornecer soluções e plataformas
      inovadoras globais de negócios.`,
      visaoFuturo: `Visão de Futuro`,
      continuamosProcesso: `Continuamos o processo de consolidação como uma das principais forças globais nos mercados em que
      atuamos, em busca da liderança e do reconhecimento pelos níveis de excelência em nossa ação
      empresarial.
      Com alianças estratégicas no mercado de finanças, real estate e energia.`
    },
    en: {
      nos: 'WE ARE ASCENSUS.',
      fundada: `
      Founded in 2001 in the city of Joinville - Santa Catarina, on the initiative of Vanderlei Palhano da
       Cruz, Ascensus Group started its operations in the Brazilian market. Over the new years
       branches were established in several states, always aiming at excellence in tax management and
       financial.`,
      atualmente: `
      Currently, with a modern infrastructure, specialized team and with experience of more than 20
      years of its executives and consultants, Ascensus Group aims to reduce costs
      tax, financial and logistical aspects of global foreign trade operations.`,
      emNossoDNA: `
      Our DNA is committed to making customers more competitive in their markets, for example
       solutions built with a focus on safety, technology, essentiality and agility.
       Our reason for being!`,
      expansao: `Expansion`,
      crescimento: `
      With fast pace growth, we stand out in the global market acting with excellent
       results in the management of the entire international trade chain, through the provision of
       personalized services, targeted solutions to meet the needs of each company and
       the continuous search for better performance in imports and exports.`,
      hojeOperando: `
      Today, operating on a global scale, with offices based in Brazil, USA and Europe, we serve with
       excellence of medium and large companies in the most diverse segments, such as: food,
       automotive, ceramic, electronics, energy, pharmaceutical, infrastructure, nautical,
       plastic, chemical, steel and retail.`,
      nossoProposito: `
      Our purpose is to make it possible, in a sustainable way, for our CLIENTS and PARTNERS to be
      more competitive in their segments, in order to create and provide solutions and platforms
      global business innovations.`,
      visaoFuturo: `Future vision`,
      continuamosProcesso: `We continue the consolidation process as one of the main global forces in the markets where
      we act, in search of leadership and recognition for the levels of excellence in our action
      business.
      With strategic alliances in the finance, real estate and energy market.`
    },
    es: {
      nos: 'SOMOS ASCENSUS.',
      fundada: `
      Fundada en 2001 en la ciudad de Joinville - Santa Catarina, por iniciativa de Vanderlei Palhano da
       Cruz, Ascensus Group inició sus operaciones en el mercado brasileño. Durante los nuevos años
       se establecieron sucursales en varios estados, siempre con el objetivo de la excelencia en la gestión tributaria y
       financiero.`,
      atualmente: `
      Actualmente, con una infraestructura moderna, equipo especializado y con experiencia de más de 20
       años de sus ejecutivos y consultores, Ascensus Group tiene como objetivo reducir costos
       Aspectos fiscales, financieros y logísticos de las operaciones de comercio exterior global.`,
      emNossoDNA: `
      Nuestro ADN está comprometido con hacer que los clientes sean más competitivos en sus mercados, por ejemplo
       soluciones construidas con un enfoque en seguridad, tecnología, esencialidad y agilidad.
       ¡Nuestra razón de ser!`,
      expansao: `Expansión`,
      crescimento: `
      Con un rápido crecimiento, nos destacamos en el mercado global actuando con excelente
       resulta en la gestión de toda la cadena de comercio internacional, mediante la provisión de
       servicios personalizados, soluciones específicas para satisfacer las necesidades de cada empresa y
       la búsqueda continua de un mejor desempeño en las importaciones y exportaciones.`,
      hojeOperando: `
      Hoy, operando a escala global, con oficinas en Brasil, Estados Unidos y Europa, servimos con
       excelencia de medianas y grandes empresas en los más diversos segmentos, tales como: alimentación,
       automotor, cerámico, electrónica, energía, farmacéutica, infraestructura, náutico,
       plástico, químico, acero y retail.`,
      nossoProposito: `
      Nuestro propósito es hacer posible, de forma sostenible, que nuestros CLIENTES y SOCIOS sean
       más competitivos en sus segmentos, con el fin de crear y brindar soluciones y plataformas
       innovaciones comerciales globales.`,
      visaoFuturo: `Visión de futuro`,
      continuamosProcesso: `Continuamos el proceso de consolidación como una de las principales fuerzas globales en los mercados donde
      actuamos, en busca de liderazgo y reconocimiento por los niveles de excelencia en nuestra acción
      negocio.
      Con alianzas estratégicas en el mercado financiero, inmobiliario y energético.`
    },
    all: {
      nos: 'NÓS SOMOS ASCENSUS.',
      fundada: `
      Fundada em 2001 na cidade de Joinville - Santa Catarina, por iniciativa de Vanderlei Palhano da
      Cruz, o Ascensus Group iniciou sua atuação no mercado brasileiro. Ao longo dos anos novas
      filiais foram constituídas em vários estados, visando sempre a excelência em gestão tributária e
      financeira.`,
      atualmente: `
      Atualmente, com uma infraestrutura moderna, equipe especializada e com experiência de mais de 20
      anos de seus executivos e consultores, o Ascensus Group tem como objetivo a redução dos custos
      tributários, financeiros e logísticos das operações de comércio exterior global.`,
      emNossoDNA: `
      Em nosso DNA, está o compromisso de tornar os clientes mais competitivos em seus mercados, por
      meio de soluções construídas com foco em segurança, tecnologia, essencialidade e agilidade.
      Nossa razão de ser!`,
      expansao: `Expansão`,
      crescimento: `
      Com crescimento em ritmo acelerado, nos destacamos no mercado global atuando com excelentes
      resultados no gerenciamento de toda a cadeia do comércio internacional, através da prestação de
      serviços personalizados, de soluções direcionadas para atender as necessidades de cada empresa e
      da busca contínua por melhores performances nas importações e exportações.`,
      hojeOperando: `
      Hoje, operando em escala global, com escritórios sediados no Brasil, EUA e Europa, atendemos com
      excelência empresas de médio e grande porte nos mais diversos segmentos, como: alimentício,
      automotivo, cerâmico, eletroeletrônico, energético, fármaco, de infraestrutura, náutico,
      plástico, químico, siderúrgico e varejo.`,
      nossoProposito: `
      Nosso propósito é possibilitar, de forma sustentável, que os nossos CLIENTES e PARCEIROS sejam
      mais competitivos em seus segmentos, de forma a criar e fornecer soluções e plataformas
      inovadoras globais de negócios.`,
      visaoFuturo: `Visão de Futuro`,
      continuamosProcesso: `Continuamos o processo de consolidação como uma das principais forças globais nos mercados em que
      atuamos, em busca da liderança e do reconhecimento pelos níveis de excelência em nossa ação
      empresarial.
      Com alianças estratégicas no mercado de finanças, real estate e energia.`
    },
  };

  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {


  }

}
