declare var sysParameters: any;
export class AppFun {

    constructor() {

    }
    public validarCPF(strCPF) {

    }


    public formatar(str, mask) {
 
    }

    number_format(number, decimals, decPoint, thousandsSep) { // eslint-disable-line camelcase
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
        var n = !isFinite(+number) ? 0 : +number
        var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
        var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
        let s: any = ''
        var toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec)
            return '' + (Math.round(n * k) / k)
                .toFixed(prec)
        }
        // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || ''
            s[1] += new Array(prec - s[1].length + 1).join('0')
        }
        return s.join(dec)
    }

    public resizeImage(file, fun) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');


        var maxW = 1024;
        var maxH = 1024;

        sysParameters.forEach(el => {
            if (el.sysParametersId == 3) {
                maxW = el.value;
            } else if (el.sysParametersId == 4) {
                maxH = el.value
            }
        });
        var img = document.createElement('img');

        img.onload = function () {
            var iw = img.width;
            var ih = img.height;
            var scale = Math.min((maxW / iw), (maxH / ih));
            var iwScaled = iw * scale;
            var ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            context.drawImage(img, 0, 0, iwScaled, ihScaled);

            var imagem = canvas.toDataURL();
            fun(imagem.replace('data:image/png;base64,', ''));
        }
        img.src = file;
    }

    public ehLogado() {
        return false;
    }

    loadjscssfile(filename, filetype) {
        let fileref;
        if (filetype === 'js') { // if filename is a external JavaScript file
            fileref = document.createElement('script');
            fileref.setAttribute('type', 'text/javascript');
            fileref.setAttribute('src', filename);
        } else if (filetype === 'css') { // if filename is an external CSS file
            fileref = document.createElement('link');
            fileref.setAttribute('rel', 'stylesheet');
            fileref.setAttribute('type', 'text/css');
            fileref.setAttribute('href', filename);
        }
        if (typeof fileref !== 'undefined') {
            document.getElementsByTagName('head')[0].appendChild(fileref);
        }
    }
}