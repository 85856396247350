import { Component, OnInit } from '@angular/core';
import { AppFun } from 'src/app/app.functions.sevices';
import { Api } from 'src/app/app.services';

declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  ehLogado: boolean;
  usuario: any = {};
  opennavBar = false;
  traducao: any = {};
  traducaoTermos = {
    br: {
      navbar: [
        {
          name: 'Home',
          link: '#home'
        },
        {
          name: 'Conheça a Ascensus',
          link: '#conheca-ascensus'
        },
        {
          name: 'Importação e Exportação',
          link: '#comex'
        },
        {
          name: 'Distribuição',
          link: '#comex-fato'
        },
        {
          name: 'Logística',
          link: '#logistica'
        },
        {
          name: 'Energia e Tecnologia',
          link: '#energia'
        },
        {
          name: 'Portos',
          link: '#portos-home'
        },
        {
          name: 'Unidades',
          link: '#unidades'
        },
        {
          name: 'Governança e Compliance',
          link: '#compliance'
        },
        {
          name: 'Contato',
          link: '#contato'
        },
        {
          name: 'Ouvidoria',
          link: '#ouvidoria'
        },
        {
          name: 'Trabalhe conosco',
          link: '#trabalhe-conosco'
        },
        {
          name: 'Investidores',
          link: '#investidores'
        }
      ]
    },
    en: {
      navbar: [
        {
          name: 'Home',
          link: '#home'
        },
        {
          name: 'Meet Ascensus',
          link: '#conheca-ascensus'
        },
        {
          name: 'Import and Export',
          link: '#comex'
        },
        {
          name: 'Distribution',
          link: '#comex-fato'
        },
        {
          name: 'Logistics',
          link: '#logistica'
        },
        {
          name: 'Energy and Technology',
          link: '#energia'
        },
        {
          name: 'Portos',
          link: '#portos-home'
        },
        {
          name: 'Units',
          link: '#unidades'
        },
        {
          name: 'Governance and Compliance',
          link: '#compliance'
        },
        {
          name: 'Contact',
          link: '#contato'
        },
        {
          name: 'Ombudsman',
          link: '#ouvidoria'
        },
        {
          name: 'Work with us',
          link: '#trabalhe-conosco'
        },
        {
          name: 'Investidores',
          link: '#investidores'
        }
      ]
    },
    es: {
      navbar: [
        {
          name: 'Home',
          link: '#home'
        },
        {
          name: 'Conoce Ascenso',
          link: '#conheca-ascensus'
        },
        {
          name: 'Importación y exportación',
          link: '#comex'
        },
        {
          name: 'Distribución',
          link: '#comex-fato'
        },
        {
          name: 'Logística',
          link: '#logistica'
        },
        {
          name: 'Energía y tecnología',
          link: '#energia'
        },
        {
          name: 'Portos',
          link: '#portos-home'
        },
        {
          name: 'Unidades',
          link: '#unidades'
        },
        {
          name: 'Gobernanza y cumplimiento',
          link: '#compliance'
        },
        {
          name: 'Contato',
          link: '#contato'
        },
        {
          name: 'Defensor del Pueblo',
          link: '#ouvidoria'
        },
        {
          name: 'Trabaja con nosotros',
          link: '#trabalhe-conosco'
        },
        {
          name: 'Investidores',
          link: '#investidores'
        }
      ]
    },
    all: {
      navbar: [
        {
          name: 'Home',
          link: '#home'
        },
        {
          name: 'Conheça a Ascensus',
          link: '#conheca-ascensus'
        },
        {
          name: 'Importação e Exportação',
          link: '#comex'
        },
        {
          name: 'Distribuição',
          link: '#comex-fato'
        },
        {
          name: 'Logística',
          link: '#logistica'
        },
        {
          name: 'Energia e Tecnologia',
          link: '#energia'
        },
        {
          name: 'Portos',
          link: '#portos-home'
        },
        {
          name: 'Unidades',
          link: '#unidades'
        },
        {
          name: 'Governança e Compliance',
          link: '#compliance'
        },
        {
          name: 'Contato',
          link: '#contato'
        },
        {
          name: 'Ouvidoria',
          link: '#ouvidoria'
        },
        {
          name: 'Trabalhe conosco',
          link: '#trabalhe-conosco'
        },
        {
          name: 'Investidores',
          link: '#investidores'
        }
      ]
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }
  ngOnInit(): void {
    let appFun = new AppFun();
    this.ehLogado = appFun.ehLogado();

    if (this.ehLogado) {
      const currentUserApi = new Api('customer/CurrentUser', 'ascensus');
      currentUserApi.get().then(ret => {
        this.usuario = ret;
      });
    }
  }

  mudarIdioma(idioma) {
    localStorage.setItem('idioma', idioma);
    // window.location.reload();
    if(idioma=='br'){
      window.location.replace(window.location.origin+'/'+window.location.pathname.split('/')[1]);
    }else{
      window.location.replace(window.location.origin+'/'+window.location.pathname.split('/')[1]+"/"+idioma);
    }

  }

  sair() {
    localStorage.clear();
    window.location.reload();
  }

  toggleNavbar() {
    this.opennavBar = !this.opennavBar;
    // if (!this.opennavBar) {
    //   setTimeout(() => {

    //     $('body').trigger('click');
    //   }, 1);
    // }
  }

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
    this.opennavBar = !this.opennavBar;
  }
}
