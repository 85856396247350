import { Component, OnInit } from '@angular/core';
declare var idiomaEscolhido: any;

@Component({
  selector: 'app-descricao-historia',
  templateUrl: './descricao-historia.component.html',
  styleUrls: ['./descricao-historia.component.scss']
})
export class DescricaoHistoriaComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      desde2001: `
      <b>Desde 2001</b> em business service tributário e financeiro.<br>
      <b>Desde 2005</b> em comex.`,
      somosEspecializados: `
      Somos especializados em inteligência logística e tributária, em que planejamos a estratégia de
      importação e exportação dos nossos clientes, de ponta a ponta.`,
      crescimento: `
      Crescimento faz parte do nosso DNA e transportamos essa força para a estratégia dos serviços que
      oferecemos, seja no atendimento ou nos resultados. Nós temos o entusiasmo e a qualidade
      necessária para superar expectativas. Nossos clientes confiam na nossa capacidade em superar
      todos os obstáculos, para garantir uma entrega cada vez mais aprimorada.`,
      elevamos: `
      Elevamos o resultado
      dos nossos clientes
      com agilidade.`,
      somosEmpreendedores: `
      Somos empreendedores e nos destacamos pelos excelentes resultados no gerenciamento de toda
      cadeia de comércio internacional e na busca contínua por melhores resultados no processo de
      importação, exportação e logística integrada.`,
      nossaEquipe: `
      Nossa equipe é experiente e qualificada, formada por profissionais especializados nas áreas de
      direito tributário, contabilidade, finanças, comércio exterior, logística e despacho aduaneiro,
      o que nos permite dinamizar cada etapa da cadeia de importação e exportação.`
    },
    en: {
      desde2001: `
      <b>Since 2001</b> in tax and financial business service.<br>
      <b>Since 2005</b> in comex.`,
      somosEspecializados: `
      We are specialized in logistical and tax intelligence, in which we plan the strategy of
       import and export of our customers, from end to end.`,
      crescimento: `
      Growth is part of our DNA and we carry that strength into the service strategy that
       we offer, whether in attendance or in results. We have the enthusiasm and the quality
       necessary to exceed expectations. Our customers trust in our ability to overcome
       all obstacles, to ensure an increasingly improved delivery.`,
      elevamos: `
      We raise the result
       of our customers
       with agility.`,
      somosEmpreendedores: `
      We are entrepreneurs and we stand out for the excellent results in managing the entire
       international trade chain and in the continuous search for better results in the process of
       import, export and integrated logistics.`,
      nossaEquipe: `
      Our team is experienced and qualified, formed by professionals specialized in the areas of
       tax law, accounting, finance, foreign trade, logistics and customs clearance,
       which allows us to streamline each stage of the import and export chain.`
    },
    es: {
      desde2001: `
      <b>Desde el 2001</b> en servicio fiscal y financiero empresarial.<br>
      <b>Desde el 2005</b> en comex.`,
      somosEspecializados: `
      Estamos especializados en inteligencia logística y fiscal, en la que planificamos la estrategia de
       importación y exportación de nuestros clientes, de punta a punta.`,
      crescimento: `
      El crecimiento es parte de nuestro ADN y llevamos esa fuerza a la estrategia de servicio que
       que ofrecemos, ya sea en presencia o en resultados. Tenemos el entusiasmo y la calidad
       necesario para superar las expectativas. Nuestros clientes confían en nuestra capacidad de superación
       todos los obstáculos, para garantizar una entrega cada vez mejor.`,
      elevamos: `
      Levantamos el resultado
       de nuestros clientes
       con agilidad.`,
      somosEmpreendedores: `
      Somos emprendedores y nos destacamos por los excelentes resultados en la gestión de toda
       cadena de comercio internacional y en la búsqueda continua de mejores resultados en el proceso de
       importación, exportación y logística integral.`,
      nossaEquipe: `
      Nuestro equipo es experimentado y calificado, formado por profesionales especializados en las áreas de
       derecho tributario, contabilidad, finanzas, comercio exterior, logística y despacho de aduanas,
       lo que nos permite agilizar cada etapa de la cadena de importación y exportación.`
    },
    all: {
      desde2001: `
      <b>Desde 2001</b> em business service tributário e financeiro.<br>
      <b>Desde 2005</b> em comex.`,
      somosEspecializados: `
      Somos especializados em inteligência logística e tributária, em que planejamos a estratégia de
      importação e exportação dos nossos clientes, de ponta a ponta.`,
      crescimento: `
      Crescimento faz parte do nosso DNA e transportamos essa força para a estratégia dos serviços que
      oferecemos, seja no atendimento ou nos resultados. Nós temos o entusiasmo e a qualidade
      necessária para superar expectativas. Nossos clientes confiam na nossa capacidade em superar
      todos os obstáculos, para garantir uma entrega cada vez mais aprimorada.`,
      elevamos: `
      Elevamos o resultado
      dos nossos clientes
      com agilidade.`,
      somosEmpreendedores: `
      Somos empreendedores e nos destacamos pelos excelentes resultados no gerenciamento de toda
      cadeia de comércio internacional e na busca contínua por melhores resultados no processo de
      importação, exportação e logística integrada.`,
      nossaEquipe: `
      Nossa equipe é experiente e qualificada, formada por profissionais especializados nas áreas de
      direito tributário, contabilidade, finanças, comércio exterior, logística e despacho aduaneiro,
      o que nos permite dinamizar cada etapa da cadeia de importação e exportação.`
    },
  };
  constructor() { }

  ngOnInit() {  
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

}
