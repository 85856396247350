import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-ports-area',
  templateUrl: './ports-area.component.html',
  styleUrls: ['./ports-area.component.scss']
})
export class PortsAreaComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      PortsArea: 'ENERGIA E TECNOLOGIA',
      ascensus: `
      A Ascensus Tecnologia e Energia (ATE) é uma empresa, da Vertical de Energia do Grupo
      Ascensus, especializada no desenvolvimento e análise de negócios de geração de energia
      renovável e de tecnologia dedicada ao ramo de energia.<br><br>

      A ATE busca estruturar e viabilizar negócios rentáveis e sustentáveis, por meio da
      competência técnica, regulatória e jurídica, aliada com a experiência no ramo de energia e o
      relacionamento com empreendedores, desenvolvedores e investidores interessados no segmento
      de energia renovável.<br><br>

      A ATE auxilia o desenvolvedor na modelagem do negócio, contribuindo para a estruturação
      adequada dos principais pilares de desenvolvimento de projeto, bem como, posicionamento de
      projetos bem-estruturados no mercado de investimentos.<br><br>

      Contribuímos para:<br>
      1) O consumidor de energia, através da redução de custos com energia a partir de uma
      estratégia energética sustentável;<br>
      2) O desenvolvedor de projetos, por meio de estruturação, diligência, Project Finance e
      oferta de projetos ao mercado investidor;<br>
      3) O investidor, que busca diversificação de investimentos em projetos de energia renovável.<br><br>

      Entre em contato com um de nossos especialistas em energia renovável e encontre a melhor
      solução para o seu negócio.
      `
    },
    en: {
        PortsArea: 'ENERGY AND TECHNOLOGY',
      ascensus: `
      Ascensus Tecnologia e Energia (ATE) is a company, of the Vertical Energy Group
       Ascensus, specialized in the development and analysis of power generation businesses
       renewable energy and technology dedicated to the energy sector.<br><br>

       ATE seeks to structure and enable profitable and sustainable businesses, through the
       technical, regulatory and legal competence, combined with experience in the energy sector and the
       relationship with entrepreneurs, developers and investors interested in the segment
       renewable energy.<br><br>

       ATE assists the developer in modeling the business, contributing to the structuring
       of the main pillars of project development, as well as
       well-structured projects in the investment market.<br><br>

       We contribute to:<br>
      1) The energy consumer, through the reduction of energy costs from a
      sustainable energy strategy;<br>
      2) The project developer, through structuring, diligence, Project Finance and
      offer of projects to the investor market;<br>
      3) O investidor, que busca diversificação de investimentos em projetos de energia renovável.<br><br>

      Get in touch with one of our renewable energy experts and find the best
       solution for your business.
      `
    },
    es: {
        PortsArea: 'ENERGIA Y TECNOLOGIA',
      ascensus: `
      Ascensus Tecnologia e Energia (ATE) es una empresa del Vertical Energy Group
       Ascensus, especializada en el desarrollo y análisis de negocios de generación eléctrica
       energía renovable y tecnología dedicada al sector energético.<br><br>

       ATE busca estructurar y posibilitar negocios rentables y sostenibles, a través de la
       competencia técnica, regulatoria y legal, combinada con experiencia en el sector energético y el
       relación con emprendedores, desarrolladores e inversores interesados ​​en el segmento
       energía renovable.<br><br>

       ATE ayuda al desarrollador a modelar el negocio, contribuyendo a la estructuración
       de los principales pilares del desarrollo de proyectos, así como
       proyectos bien estructurados en el mercado de inversión.<br><br>

       Contribuimos a:<br>
      1) El consumidor de energía, mediante la reducción de los costes energéticos de un
      estrategia de energía sostenible;<br>
      2) El desarrollador del proyecto, a través de la estructuración, diligencia, Project Finance y
      oferta de proyectos al mercado inversor;<br>
      3) El inversor, que busca la diversificación de inversiones en proyectos de energías renovables.<br><br>

      Entre em contato com um de nossos especialistas em energia renovável e encontre a melhor
      solução para o seu negócio.
      `
    },
    all: {
        PortsArea: 'ENERGIA E TECNOLOGIA',
      ascensus: `
      A Ascensus Tecnologia e Energia (ATE) é uma empresa, da Vertical de Energia do Grupo
      Ascensus, especializada no desenvolvimento e análise de negócios de geração de energia
      renovável e de tecnologia dedicada ao ramo de energia.<br><br>

      A ATE busca estruturar e viabilizar negócios rentáveis e sustentáveis, por meio da
      competência técnica, regulatória e jurídica, aliada com a experiência no ramo de energia e o
      relacionamento com empreendedores, desenvolvedores e investidores interessados no segmento
      de energia renovável.<br><br>

      A ATE auxilia o desenvolvedor na modelagem do negócio, contribuindo para a estruturação
      adequada dos principais pilares de desenvolvimento de projeto, bem como, posicionamento de
      projetos bem-estruturados no mercado de investimentos.<br><br>

      Contribuímos para:<br>
      1) O consumidor de energia, através da redução de custos com energia a partir de uma
      estratégia energética sustentável;<br>
      2) O desenvolvedor de projetos, por meio de estruturação, diligência, Project Finance e
      oferta de projetos ao mercado investidor;<br>
      3) O investidor, que busca diversificação de investimentos em projetos de energia renovável.<br><br>

      Entre em contato com um de nossos especialistas em energia renovável e encontre a melhor
      solução para o seu negócio.
      `
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }


  ngOnInit() {
  }
  abrirDetalhe() {
    $('.ports-area-title').toggleClass('active-rotate');
    $('.acordeon-ports-area').animate({height: 'toggle'});
    this.scroll('#ports-area');
  }

  fecharDetalhe() {
    $('.ports-area-title').removeClass('active-rotate');
    $('.acordeon-ports-area').slideUp();
    this.scroll('#ports-area');
  }

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }
}
