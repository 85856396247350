import { Component, OnInit, Input } from '@angular/core';

declare var $, idiomaEscolhido: any;

@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss']
})
export class MainBannerComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      banners: [
        {
          srcBannerMobile: "banner/banner1-mobile.jpg",
          srcBanner: "banner1.jpg",
          textoDestaque: 'SEU NEGÓCIO É A NOSSA MISSÃO',
          texto: 'TEMOS AS MELHORES SOLUÇÕES PARA OS SEUS NEGÓCIOS!'
        },
        {
          srcBannerMobile: "banner/banner3-mobile.jpg",
          srcBanner: "banner3.jpg",
          textoDestaque: 'MERCADO GLOBAL E<br>INTERNACIONALIZAÇÃO',
          texto: 'ESCRITÓRIOS LOCALIZADOS ESTRATEGICAMENTE EM<BR>JOINVILLE, SÃO PAULO, EUA E EUROPA'
        },
        {
          srcBannerMobile: "banner/banner2-mobile.jpg",
          srcBanner: "banner2.jpg",
          textoDestaque: 'GOVERNANÇA, COMPLIANCE<BR>E COMPROMETIMENTO<BR>DA EQUIPE.',
          texto: 'DEDICAÇÃO TOTAL DA NOSSA EQUIPE COM O SEU NEGÓCIO'
        },
      ]
    },
    en: {
      banners: [
        {
          srcBannerMobile: "banner/banner1-mobile.jpg",
          srcBanner: "banner1.jpg",
          textoDestaque: 'YOUR BUSINESS IS OUR MISSION',
          texto: 'WE HAVE BEST SOLUTIONS FOR YOUR BUSINESS'
        },
        {
          srcBannerMobile: "banner/banner3-mobile.jpg",
          srcBanner: "banner3.jpg",
          textoDestaque: 'GLOBAL MARKET AND<BR>INTERNATIONALIZATION',
          texto: 'STRATEGICALLY LOCATED OFFICES BASED IN<BR>JOINVILLE, SÃO PAULO, USA AND EUROPE'
        },
        {
          srcBannerMobile: "banner/banner2-mobile.jpg",
          srcBanner: "banner2.jpg",
          textoDestaque: 'GOVERNANCE, COMPLIANCE<BR>AND COMMITMENT.',
          texto: 'TOTAL DEDICATION OF OUR TEAM WITH YOUR BUSINESS'
        },
      ]
    },
    es: {
      banners: [
        {
          srcBannerMobile: "banner/banner1-mobile.jpg",
          srcBanner: "banner1.jpg",
          textoDestaque: 'TU NEGOCIO ES NUESTRA MISSION',
          texto: 'TENEMOS LAS MEJORES SOLUCIONES PARA TU NEGOCIO'
        },
        {
          srcBannerMobile: "banner/banner3-mobile.jpg",
          srcBanner: "banner3.jpg",
          textoDestaque: 'MERCADO GLOBAL Y<BR>INTERNACIONALIZCIÓN',
          texto: 'OFICINAS ESTRATÉGICAMENTE UBICADAS EM<BR>JOINVILLE, SÃO PAULO, EUA Y EUROPA'
        },
        {
          srcBannerMobile: "banner/banner2-mobile.jpg",
          srcBanner: "banner2.jpg",
          textoDestaque: 'GOBERNANCIA, CONFORMIDAD<BR>Y COMPROMISO',
          texto: 'DEDICACIÓN TOTAL DE NUESTRO EQUIPO CON TU NEGOCIO'
        },
      ]
    },
    all: {
      banners: [
        {
          srcBannerMobile: "banner/banner1-mobile.jpg",
          srcBanner: "banner1.jpg",
          textoDestaque: 'SEU NEGÓCIO É A NOSSA MISSÃO',
          texto: 'TEMOS AS MELHORES SOLUÇÕES PARA OS SEUS NEGÓCIOS!'
        },
        {
          srcBannerMobile: "banner/banner3-mobile.jpg",
          srcBanner: "banner3.jpg",
          textoDestaque: 'MERCADO GLOBAL E INTERNACIONALIZAÇÃO',
          texto: 'ESCRITÓRIOS LOCALIZADOS ESTRATEGICAMENTE EM JOINVILLE, SÃO PAULO, EUA E EUROPA'
        },
        {
          srcBannerMobile: "banner/banner2-mobile.jpg",
          srcBanner: "banner2.jpg",
          textoDestaque: 'GOVERNANÇA, COMPLIANCE E COMPROMETIMENTO DA EQUIPE.',
          texto: 'DEDICAÇÃO TOTAL DA NOSSA EQUIPE COM O SEU NEGÓCIO'
        },
      ]
    },
  };

  constructor() { }

  ngOnInit(): void {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
    setTimeout(() => {
      $('.owl-main-banner').owlCarousel({
        loop: true,
        nav: true,
        items: 1,
        dots: false,
        navText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"],
        rewindNav: true,
      })
    }, 1000);


  }

}
