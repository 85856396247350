import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-portos-home',
  templateUrl: './portos-home.component.html',
  styleUrls: ['./portos-home.component.scss']
})
export class PortosHomeComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      title: 'ASCENSUS <small>Portos</small>',
      text: `Com início em 2021, a partir da aquisição do arrendamento do 
      terminal portuário PAR12, junto à APPA – Administração dos Portos de
      Paranaguá e Antonina. O Ascensus Portos nasceu com o objetivo 
      principal de modernizar o mercado logístico portuário de cargas Roll-
      On e Roll-Off, visando sempre a excelência operacional. 
      <br><br>
      A atuação da empresa garante primor em atendimento aos seus clientes a partir de sua presença nacional em grandes portos do Brasil, em complemento da sua expertise em comércio exterior e de estruturas altamente qualificadas com foco em tecnologia, segurança e agilidade.
      <br><br>
      Hoje, operando importação e exportação em toda a cadeia logística 
      portuária de cargas rolantes, o Ascensus Portos atua em parceria com
      transportadoras, armadores e clientes, além de realizar serviços de 
      carga e descarga das cegonhas, gestão de pátio e o embarque e 
      desembarque de navios. Dispondo seus trabalhos por todo o Brasil, o 
      Ascensus Portos possui atuação vigente nos portos de Paranaguá-PR, 
      Itajaí-SC e Suape-PE.
      `,
      button_text: `Conheça mais
      `,
      button_link: `https://ascensus.com.br/portos
      `,
    },
    en: {
      title: 'ASCENSUS <small>Portos</small>',
      text: `Com início em 2021, a partir da aquisição do arrendamento do 
      terminal portuário PAR12, junto à APPA – Administração dos Portos de
      Paranaguá e Antonina. O Ascensus Portos nasceu com o objetivo 
      principal de modernizar o mercado logístico portuário de cargas Roll-
      On e Roll-Off, visando sempre a excelência operacional. 
      <br><br>
      A atuação da empresa garante primor em atendimento aos seus clientes a partir de sua presença nacional em grandes portos do Brasil, em complemento da sua expertise em comércio exterior e de estruturas altamente qualificadas com foco em tecnologia, segurança e agilidade.
      <br><br>
      Hoje, operando importação e exportação em toda a cadeia logística 
      portuária de cargas rolantes, o Ascensus Portos atua em parceria com
      transportadoras, armadores e clientes, além de realizar serviços de 
      carga e descarga das cegonhas, gestão de pátio e o embarque e 
      desembarque de navios. Dispondo seus trabalhos por todo o Brasil, o 
      Ascensus Portos possui atuação vigente nos portos de Paranaguá-PR, 
      Itajaí-SC e Suape-PE.
      `,
      button_text: `Conheça mais
      `,
      button_link: `https://ascensus.com.br/portos/en
      `,
    },
    es: {
      title: 'ASCENSUS <small>Portos</small>',
      text: `Com início em 2021, a partir da aquisição do arrendamento do 
      terminal portuário PAR12, junto à APPA – Administração dos Portos de
      Paranaguá e Antonina. O Ascensus Portos nasceu com o objetivo 
      principal de modernizar o mercado logístico portuário de cargas Roll-
      On e Roll-Off, visando sempre a excelência operacional. 
      <br><br>
      A atuação da empresa garante primor em atendimento aos seus clientes a partir de sua presença nacional em grandes portos do Brasil, em complemento da sua expertise em comércio exterior e de estruturas altamente qualificadas com foco em tecnologia, segurança e agilidade.
      <br><br>
      Hoje, operando importação e exportação em toda a cadeia logística 
      portuária de cargas rolantes, o Ascensus Portos atua em parceria com
      transportadoras, armadores e clientes, além de realizar serviços de 
      carga e descarga das cegonhas, gestão de pátio e o embarque e 
      desembarque de navios. Dispondo seus trabalhos por todo o Brasil, o 
      Ascensus Portos possui atuação vigente nos portos de Paranaguá-PR, 
      Itajaí-SC e Suape-PE.
      `,
      button_text: `Conheça mais
      `,
      button_link: `https://ascensus.com.br/portos/es
      `,
    },
    all: {
      title: 'ASCENSUS <small>Portos</small>',
      text: `Com início em 2021, a partir da aquisição do arrendamento do 
      terminal portuário PAR12, junto à APPA – Administração dos Portos de
      Paranaguá e Antonina. O Ascensus Portos nasceu com o objetivo 
      principal de modernizar o mercado logístico portuário de cargas Roll-
      On e Roll-Off, visando sempre a excelência operacional. 
      <br><br>
      A atuação da empresa garante primor em atendimento aos seus clientes a partir de sua presença nacional em grandes portos do Brasil, em complemento da sua expertise em comércio exterior e de estruturas altamente qualificadas com foco em tecnologia, segurança e agilidade.
      <br><br>
      Hoje, operando importação e exportação em toda a cadeia logística 
      portuária de cargas rolantes, o Ascensus Portos atua em parceria com
      transportadoras, armadores e clientes, além de realizar serviços de 
      carga e descarga das cegonhas, gestão de pátio e o embarque e 
      desembarque de navios. Dispondo seus trabalhos por todo o Brasil, o 
      Ascensus Portos possui atuação vigente nos portos de Paranaguá-PR, 
      Itajaí-SC e Suape-PE.
      `,
      button_text: `Conheça mais
      `,
      button_link: `https://ascensus.com.br/portos
      `,
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
  }
  abrirDetalhe() {
    $('.portos-home-title').toggleClass('active-rotate');
    $('.acordeon-portos-home').animate({height: 'toggle'});
    this.scroll('#portos-home');
  }

  fecharDetalhe() {
    $('.portos-home-title').removeClass('active-rotate');
    $('.acordeon-portos-home').slideUp();
    this.scroll('#portos-home');
  }


  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }
}
