import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  traducao: any = {};
  tituloModal = '';
  textoModal = '';
  traducaoTermos = {
    br: {
      titulo: 'Aviso de Privacidade',
      texto: `
      <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
      150%"><span lang="PT" >As empresas do GRUPO ASCENSUS
      reconhecem a importância da privacidade no mundo contemporâneo e por isso
      adotam como missão criar e gerir suas atividades com proteção de dados pessoais
      de clientes, parceiros, colaboradores e demais membros da sociedade. Os
      esforços de segurança da informação e de privacidade dados refletem os valores
      de responsabilidade social e comprometimento ético.</span></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Nosso
      Aviso de Privacidade informa sobre nosso comprometimento com sua privacidade e
      esclarece como tratamos dados pessoais. Optamos por uma abordagem geral e objetiva
      para facilitar o entendimento e dar transparência ao tema. Informamos que nem
      todos os detalhes sobre o tratamento dos dados pessoais podem ser declarados
      por meio desse instrumento. </span></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Portanto,
      se precisar de alguma informação adicional, ou quiser saber um pouco mais,
      entre em contato nosso time de privacidade e nosso Chefe de Privacidade: </span><a href="mailto:privacidade@ascensus.com.br" target="_blank"><span lang="PT-BR" style="font-size:12.0pt;
      line-height:150%;font-family:'Calibri',sans-serif;">privacidade@ascensus.com.br</span></a><span class="MsoHyperlink"></span><a></a>.<span class="MsoCommentReference"><span lang="PT-BR" style="font-size:8.0pt;line-height:150%;font-family:'Calibri',sans-serif">&nbsp;</span></span><span class="MsoCommentReference"><span lang="PT-BR" style="font-size:8.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">&nbsp;</span></span></p>
      
      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">1.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Quais dados pessoais são coletados? </span></b></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Os
      dados pessoais que tratamos variam de acordo com o motivo pelo qual nos
      relacionamos com você.</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">I.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Se você se relaciona conosco via formulário
      de CONTATO/OUVIDORIA/SEJA UM PARCEIRO em nosso <i>website</i>, coletamos nome, e-mail, telefone,
      para que possamos prestar o atendimento ou os esclarecimentos solicitados.</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">II.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Nós podemos tratar dados pessoais como nome e
      informações de contato, quando queremos entrar em contato com potenciais
      clientes que manifestaram interesse em serviços e ou produtos que oferecemos.</span></p>
      
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">III.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Nossos clientes são cadastrados em nossas
      bases de dados para que possamos atender exigências legais e fiscalizatórias, bem
      como para prestar atendimento de suporte, orientações e garantia. Nesse
      propósito coletamos dados cadastrais como nome completo, documentos de
      identificação e dados para contato; </span></p>
      
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">IV.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Se você nos presta serviços, coletaremos
      dados que nos permitem contatá-lo e executar o contrato, tais como nome,
      documentos de identificação e dados para contato.</span></p>
      
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">V.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Também podemos coletar imagens em fotos e
      vídeos de pessoas que acessam nossos estabelecimentos, o que acontece pelos
      sistemas de monitoramento e vigilância eletrônica adotados para fins de
      segurança do local e proteção das pessoas.</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">VI.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Se você é nosso empregado, coletamos dados pessoais
      para executarmos o contrato e para cumprirmos obrigações legais, como dados
      cadastrais, documentos de identificação e informações profissionais.</span></p>
      
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">VII.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Também coletamos dados de profissionais que
      se candidatam a vagas no Grupo Ascensus. Esses dados são tratados</span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">
      para promovermos avaliação de preenchimento de cargos e para formação de bancos
      de talentos. Para esse fim coletamos dados cadastrais como nome e documento de
      identificação e informações profissionais e de contato. A coleta também ocorre
      via formulário na aba TRABALHE CONOSCO em nosso <i>website</i>.</span></p>

      <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
      8.0pt;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:
      150%"><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;font-family:
      'Calibri',sans-serif">2.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Em que hipóteses trataremos dados sensíveis?
      O que são exatamente esses dados?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Qualquer dado relacionado
      a pessoa natural identificada ou identificável, que possa gerar algum tipo de
      discriminação, constrangimento ou ameaça, é considerado como dado sensível.
      Coletamos dados sensíveis tão somente de nossos colaboradores para cumprir com
      os nossos deveres legais e obrigações contratuais, dentro das hipóteses de
      legitimidade previstas em lei:</span></p>

      <p class="MsoListParagraph" style="margin-left:2rem;margin-bottom:8.0pt;margin-top:0in;text-align:
      justify;text-indent:0;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">I.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Proteção
      da vida ou da incolumidade física do titular ou de terceiros;</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;text-indent:0;
      line-height:150%"><span lang="PT-BR" style="font-family:'Calibri',sans-serif">II.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Garantia
      da prevenção à fraude e à segurança do titular, nos processos de identificação
      e autenticação de cadastro em sistemas eletrônicos;</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;text-indent:0;
      line-height:150%"><span lang="PT-BR" style="font-family:'Calibri',sans-serif">III.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Exercício
      regular de direitos, inclusive em contrato e em processo judicial;</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;text-indent:0;
      line-height:150%"><span lang="PT-BR" style="font-family:'Calibri',sans-serif">IV.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Cumprimento
      de obrigação legal ou regulatória pelo controlador;</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;text-indent:0;
      line-height:150%"><span lang="PT-BR" style="font-family:'Calibri',sans-serif">V.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Execução
      do contrato ou procedimentos preliminares;</span></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Na
      execução de nossas obrigações contratuais com clientes ou terceiros, não
      precisamos e evitamos por todos os meios coletar ou processar dados sensíveis
      de qualquer tipo.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">3.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Quais as finalidades de coleta dos meus dados
      pessoais no Grupo Ascensus?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Ao coletarmos dados
      pessoais o respectivo tratamento ocorrerá em plena conformidade com a
      legislação brasileira de proteção de dados e no cumprimento das finalidades
      relacionadas a seguir:</span></p>

      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">I.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Permitir
      identificação de contatos e assim possibilitar a comunicação com os clientes
      pelos canais de atendimento para sanar dúvidas, prestar esclarecimentos ou
      estabelecer relação contratual.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">II.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Encaminhar
      conteúdo institucional e promocional que entendemos possa ser de interesse de
      nossos clientes ou interessados em nossos produtos.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">III.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Cumprimento
      de obrigações legais ou de ordens judiciais.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">IV.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Cumprimento
      de obrigações contratuais ou para tratativas preliminares de contrato.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">V.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Avaliar
      se perfil de um candidato atende a requisitos de vagas de emprego.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">VI.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Realizar
      a admissão dos colaboradores, com o posterior cadastramento no sistema, bem
      como permitir a execução dos contratos com colaboradores e terceiros, cumprindo
      com as devidas obrigações legais.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">VII.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'"> </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Estabelecer a segurança das
      pessoas que trabalham direta ou indiretamente para o Grupo Ascensus e aqueles
      que nos visitam.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">VIII.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Atender
      a interesses legítimos do Grupo Ascensus.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">4.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Como os dados são processados?</span></b></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Os
      dados pessoais tratados pelo Grupo Ascensus</span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif"> são
      protegidos contra acesso indevido, perda, roubo ou modificação, pela adoção de
      medidas técnicas e gerenciais de controle e segurança da informação. Estes
      controles envolvem desde medidas gerenciais como política de privacidade e
      obrigações de confidencialidade, a medidas técnicas como controle de permissão
      de acessos a sistemas, auditoria de atividades, ferramentas de detecção e
      resposta a tentativas de intrusão, dentre outros. </span></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Apesar
      dessas medidas, o Grupo Ascensus não se responsabiliza pelo mau uso que seus
      clientes, prestadores, seguidores e colaboradores possam fazer de seus próprios
      dados, ou tampouco pela má-fé de terceiros, se eximindo do dever de reparar os
      danos decorrentes de incidentes de segurança ou violação de dados provocados
      por invasões, falhas ou vícios, salvo se comprovadamente ocasionadas por
      conduta culposa ou deliberada do próprio Grupo Ascensus.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">5.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">O Armazenamento dos dados pessoais</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">O armazenamento de dados
      pessoais tratados pelo Grupo Ascensus é feito em sistemas e plataformas
      voltados à gestão das atividades internas de nossas equipes. Tais sistemas tem
      acesso controlado por regras de limitação de acesso apenas aos colaboradores que
      precisam utilizá-los para execução das finalidades declaradas. </span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><a></a><a><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;">Podemos
      utilizar serviços de processamento de dados de fornecedores localizados fora do
      País</span></a><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">. Nesse caso, o Grupo Ascensus se
      certificará que eventual transferência internacional de dados atenda nossos
      requisitos de segurança e as regras de privacidade previstas na legislação
      brasileira.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">6.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Com quem são compartilhados os dados?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Os dados tratados pelo
      Grupo Ascensus são compartilhados com colaboradores de áreas internas
      responsáveis pela execução das finalidades declaradas e amparadas por lei. <span >Todos os nossos colaboradores firmam compromisso de
      confidencialidade e devem seguir nossas políticas de privacidade e de segurança
      da informação.</span> </span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;">O Grupo
      Ascensus também poderá compartilhar dados pessoais com outros prestadores de
      serviços, em especial na área de comércio internacional, a exemplo de
      Despachantes Aduaneiros e Agentes de Carga, para fins de execução do contrato
      ou procedimentos preliminares, cumprimento de obrigações legais e ou fins de interesse
      legítimo</span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">7.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Por quanto tempo meus dados pessoais são
      armazenados?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">O tratamento de dados
      pessoais ocorrerá por prazos que variam de acordo com as finalidades e hipótese
      de tratamento. O armazenamento dos dados, em regra, perdura pelo tempo
      necessário para cumprimento de obrigações legais, para execução de contratos, e
      para o eventual uso em exercício regular de direito em processos judiciais e/ou
      administrativos. </span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Se você não é cliente, não
      possui contrato de trabalho ou de prestação de serviços, e se relaciona apenas
      para fins de interação comercial e recebimento de conteúdo, seus dados serão
      tratados por tempo indeterminado, com base na expectativa de atendimento de
      interesses legítimos. Você poderá pedir o cancelamento ou interrupção do
      tratamento a qualquer momento.</span></p>

      <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
      8.0pt;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:
      150%"><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;font-family:
      'Calibri',sans-serif">8.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Como lidamos com <i>cookies</i>?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><i><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Cookies</span></i><span lang="PT-BR" style="font-size:12.0pt;line-height:150%"> são arquivos enviados e
      instalados no navegador dos usuários. Os <i>cookies </i>permitem a
      identificação do endereço de IP, tipo de navegador, tipo de dispositivo,
      duração da visita, preferências pessoais,&nbsp; páginas visitadas, entre outras
      informações<span >. </span></span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;">Nossa
      página utiliza cookies do Google Analytics para que possamos gerar relevância
      do site em mecanismos de buscas. </span><span lang="PT-BR" style="font-size:12.0pt;
      line-height:150%">Para saber sobre essa ferramenta, acesse a Política de
      Privacidade do Google: <a href="https://policies.google.com/privacy?hl=pt#about" target="_blank"><span style="font-size:12.0pt;line-height:150%">https://policies.google.com/privacy?hl=pt#about</span></a></span></p>

      <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
      8.0pt;margin-left:14.2pt;text-indent:-14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;font-family:'Calibri',sans-serif">9.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp; </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;font-family:'Calibri',sans-serif">Redes Sociais (Facebook, Linkedin e Instagram)</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Oferecemos canais de
      comunicação e interação por meio das redes sociais. Neste caso, em relação ao
      tratamento dos seus dados pessoais, atuamos como controlador conjunto com o
      Facebook, Instagram, Linkedin e Twitter. Portanto, recomendamos que você leia o
      aviso de privacidade de cada uma delas para entender como tratam os seus dados
      pessoais:</span></p>
      
      <p class="MsoNormal" style="line-height:150%"><span lang="PT-BR" style="font-size:
      12.0pt;line-height:150%">Facebook – </span><span lang="PT-BR"><a href="https://www.facebook.com/policy.php" target="_blank"><span style="font-size:12.0pt;
      line-height:150%" target="_blank">https://www.facebook.com/policy.php</span></a></span></p>
      
      <p class="MsoNormal" style="line-height:150%"><span lang="PT-BR" style="font-size:
      12.0pt;line-height:150%">Linkedin –&nbsp; </span><span lang="PT-BR"><a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"><span style="font-size:
      12.0pt;line-height:150%">https://www.linkedin.com/legal/privacy-policy</span></a></span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Twitter – </span><span lang="PT-BR"><a href="https://twitter.com/pt/privacy" target="_blank"><span style="font-size:
      12.0pt;line-height:150%">https://twitter.com/pt/privacy</span></a></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Instagram - </span><span lang="PT-BR"><a href="https://pt-br.facebook.com/help/instagram/519522125107875" target="_blank"><span style="font-size:12.0pt;line-height:150%">https://pt-br.facebook.com/help/instagram/519522125107875</span></a></span></p>

      <p class="MsoPlainText" style="margin-left:21.3pt;text-align:justify;text-indent:
      -21.3pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">10.<span style="font:7.0pt 'Times New Roman'">&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Quais são meus direitos?</span></b></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">O
      Grupo Ascensus se compromete a atender os direitos dos titulares de dados
      pessoais. De acordo com a Lei, os titulares tem direito a:</span></p>

      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">I.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Solicitar confirma e acesso aos dados
      pessoais tratados;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">II.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Requerer a correção, complementação ou
      atualização dos dados;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">III.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Solicitar a anonimização, bloqueio ou </span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">eliminação
      de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">IV.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Pedir a portabilidade </span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">de
      seus dados pessoais a outro fornecedor de serviço ou produto, mediante
      requisição expressa, de acordo com a regulamentação da autoridade nacional,
      observados os segredos comercial e industrial;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">V.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Requerer a eliminação, a qualquer momento,
      dos dados tratados com base em consentimento do titular;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">VI.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Revogar o consentimento.</span></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Caso
      queira saber mais sobre seus direitos, ou queria exercer qualquer desses
      direitos, colocamos à disposição o contato de nosso chefe de privacidade: <a href="mailto:privacidade@ascensus.com.br" target="_blank"><span >privacidade@ascensus.com.br</span></a><span class="MsoHyperlink"></span></span></p>

      <p class="MsoPlainText" style="margin-left:21.3pt;text-align:justify;text-indent:
      -21.3pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">11.<span style="font:7.0pt 'Times New Roman'">&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Atualizações </span></b></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Informamos
      que esse Aviso de Privacidade sempre poderá passar por atualizações a visando
      refletir mudanças nas práticas de proteção de dados adotadas pelo Grupo
      Ascensus. Portanto, fica aqui nosso convite para que nossos clientes,
      parceiros, colaboradores e amigos consultem esse aviso rotineiramente. Caso
      ocorram alterações de alta relevância essas serão comunicadas por meios de
      contato direto.&nbsp; </span></p>

      <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
      8.0pt;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:
      150%"><b><span lang="pt" style="font-size:14.0pt;line-height:150%;font-family:
      'Calibri',sans-serif">12.<span style="font:7.0pt 'Times New Roman'">&nbsp; </span></span></b><b><span lang="pt" style="font-size:14.0pt;line-height:150%;font-family:'Calibri',sans-serif">Esclarecimento
      e Solicitações</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="pt" style="font-size:12.0pt;line-height:150%">Se entender necessário requerer algum
      tipo de esclarecimento, registrar dúvidas e ou fazer solicitações de qualquer
      natureza relacionadas ao tratamento de dados pessoais e aos termos de nosso
      aviso de privacidade, o Grupo Ascensus se coloca à disposição para contato por e-mail: <span ><a href="mailto:privacidade@ascensus.com.br" target="_blank">privacidade@ascensus.com.br</a></span></span><span lang="pt" style="font-size:12.0pt;line-height:150%">.</span></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Atualizado em 01 de fevereiro de 2021.</span></p>`,

      titulo_quality: 'Política de qualidade',
      texto_quality: `
      <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
      150%"><span lang="PT" >O Ascensus Portos, prestador de serviços para o segmento de veículos e/ou rolantes, tem como compromisso garantir que as atividades sejam conduzidas dentro dos requisitos aplicáveis a cada operação, priorizando a satisfação dos clientes com um time de alta performance, buscando a melhoria contínua dos serviços e sua qualidade.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Nossos Objetivos:</span></b></p>

      <ul>
        <li>✓ Atender a Expectativa de Satisfazer nossos clientes</li>
        <li>✓ Promover time de Alta performance</li>
        <li>✓ Promover a Melhoria Contínua dos serviços e sua qualidade</li>
        <li>✓ Diversificar fontes de receita</li>
      </ul>`
    },
    en: {
      titulo: 'Privacy Notice',
      texto: ``,
      titulo_quality: 'Política de qualidade',
      texto_quality: ``
    },
    es: {
      titulo: 'Aviso de privacidade',
      texto: ``,
      titulo_quality: 'Política de qualidade',
      texto_quality: ``
    },
    all: {
      titulo: 'Aviso de Privacidade',
      texto: `
      <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
      150%"><span lang="PT" >As empresas do GRUPO ASCENSUS
      reconhecem a importância da privacidade no mundo contemporâneo e por isso
      adotam como missão criar e gerir suas atividades com proteção de dados pessoais
      de clientes, parceiros, colaboradores e demais membros da sociedade. Os
      esforços de segurança da informação e de privacidade dados refletem os valores
      de responsabilidade social e comprometimento ético.</span></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Nosso
      Aviso de Privacidade informa sobre nosso comprometimento com sua privacidade e
      esclarece como tratamos dados pessoais. Optamos por uma abordagem geral e objetiva
      para facilitar o entendimento e dar transparência ao tema. Informamos que nem
      todos os detalhes sobre o tratamento dos dados pessoais podem ser declarados
      por meio desse instrumento. </span></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Portanto,
      se precisar de alguma informação adicional, ou quiser saber um pouco mais,
      entre em contato nosso time de privacidade e nosso Chefe de Privacidade: </span><a href="mailto:privacidade@ascensus.com.br" target="_blank"><span lang="PT-BR" style="font-size:12.0pt;
      line-height:150%;font-family:'Calibri',sans-serif;">privacidade@ascensus.com.br</span></a><span class="MsoHyperlink"></span><a></a>.<span class="MsoCommentReference"><span lang="PT-BR" style="font-size:8.0pt;line-height:150%;font-family:'Calibri',sans-serif">&nbsp;</span></span><span class="MsoCommentReference"><span lang="PT-BR" style="font-size:8.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">&nbsp;</span></span></p>
      
      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">1.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Quais dados pessoais são coletados? </span></b></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Os
      dados pessoais que tratamos variam de acordo com o motivo pelo qual nos
      relacionamos com você.</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">I.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Se você se relaciona conosco via formulário
      de CONTATO/OUVIDORIA/SEJA UM PARCEIRO em nosso <i>website</i>, coletamos nome, e-mail, telefone,
      para que possamos prestar o atendimento ou os esclarecimentos solicitados.</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">II.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Nós podemos tratar dados pessoais como nome e
      informações de contato, quando queremos entrar em contato com potenciais
      clientes que manifestaram interesse em serviços e ou produtos que oferecemos.</span></p>
      
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">III.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Nossos clientes são cadastrados em nossas
      bases de dados para que possamos atender exigências legais e fiscalizatórias, bem
      como para prestar atendimento de suporte, orientações e garantia. Nesse
      propósito coletamos dados cadastrais como nome completo, documentos de
      identificação e dados para contato; </span></p>
      
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">IV.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Se você nos presta serviços, coletaremos
      dados que nos permitem contatá-lo e executar o contrato, tais como nome,
      documentos de identificação e dados para contato.</span></p>
      
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">V.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Também podemos coletar imagens em fotos e
      vídeos de pessoas que acessam nossos estabelecimentos, o que acontece pelos
      sistemas de monitoramento e vigilância eletrônica adotados para fins de
      segurança do local e proteção das pessoas.</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">VI.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Se você é nosso empregado, coletamos dados pessoais
      para executarmos o contrato e para cumprirmos obrigações legais, como dados
      cadastrais, documentos de identificação e informações profissionais.</span></p>
      
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">VII.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Também coletamos dados de profissionais que
      se candidatam a vagas no Grupo Ascensus. Esses dados são tratados</span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">
      para promovermos avaliação de preenchimento de cargos e para formação de bancos
      de talentos. Para esse fim coletamos dados cadastrais como nome e documento de
      identificação e informações profissionais e de contato. A coleta também ocorre
      via formulário na aba TRABALHE CONOSCO em nosso <i>website</i>.</span></p>

      <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
      8.0pt;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:
      150%"><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;font-family:
      'Calibri',sans-serif">2.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Em que hipóteses trataremos dados sensíveis?
      O que são exatamente esses dados?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Qualquer dado relacionado
      a pessoa natural identificada ou identificável, que possa gerar algum tipo de
      discriminação, constrangimento ou ameaça, é considerado como dado sensível.
      Coletamos dados sensíveis tão somente de nossos colaboradores para cumprir com
      os nossos deveres legais e obrigações contratuais, dentro das hipóteses de
      legitimidade previstas em lei:</span></p>

      <p class="MsoListParagraph" style="margin-left:2rem;margin-bottom:8.0pt;margin-top:0in;text-align:
      justify;text-indent:0;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">I.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Proteção
      da vida ou da incolumidade física do titular ou de terceiros;</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;text-indent:0;
      line-height:150%"><span lang="PT-BR" style="font-family:'Calibri',sans-serif">II.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Garantia
      da prevenção à fraude e à segurança do titular, nos processos de identificação
      e autenticação de cadastro em sistemas eletrônicos;</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;text-indent:0;
      line-height:150%"><span lang="PT-BR" style="font-family:'Calibri',sans-serif">III.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Exercício
      regular de direitos, inclusive em contrato e em processo judicial;</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;text-indent:0;
      line-height:150%"><span lang="PT-BR" style="font-family:'Calibri',sans-serif">IV.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Cumprimento
      de obrigação legal ou regulatória pelo controlador;</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;text-indent:0;
      line-height:150%"><span lang="PT-BR" style="font-family:'Calibri',sans-serif">V.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Execução
      do contrato ou procedimentos preliminares;</span></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Na
      execução de nossas obrigações contratuais com clientes ou terceiros, não
      precisamos e evitamos por todos os meios coletar ou processar dados sensíveis
      de qualquer tipo.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">3.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Quais as finalidades de coleta dos meus dados
      pessoais no Grupo Ascensus?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Ao coletarmos dados
      pessoais o respectivo tratamento ocorrerá em plena conformidade com a
      legislação brasileira de proteção de dados e no cumprimento das finalidades
      relacionadas a seguir:</span></p>

      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">I.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Permitir
      identificação de contatos e assim possibilitar a comunicação com os clientes
      pelos canais de atendimento para sanar dúvidas, prestar esclarecimentos ou
      estabelecer relação contratual.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">II.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Encaminhar
      conteúdo institucional e promocional que entendemos possa ser de interesse de
      nossos clientes ou interessados em nossos produtos.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">III.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Cumprimento
      de obrigações legais ou de ordens judiciais.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">IV.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Cumprimento
      de obrigações contratuais ou para tratativas preliminares de contrato.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">V.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Avaliar
      se perfil de um candidato atende a requisitos de vagas de emprego.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">VI.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Realizar
      a admissão dos colaboradores, com o posterior cadastramento no sistema, bem
      como permitir a execução dos contratos com colaboradores e terceiros, cumprindo
      com as devidas obrigações legais.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">VII.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'"> </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Estabelecer a segurança das
      pessoas que trabalham direta ou indiretamente para o Grupo Ascensus e aqueles
      que nos visitam.</span></p>
      
      <p class="MsoListParagraph" style="margin-left:2rem;text-align:justify;
      text-indent:-1rem;line-height:150%"><span lang="PT-BR" style="font-family:
      'Calibri',sans-serif">VIII.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-family:'Calibri',sans-serif">Atender
      a interesses legítimos do Grupo Ascensus.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">4.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Como os dados são processados?</span></b></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Os
      dados pessoais tratados pelo Grupo Ascensus</span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif"> são
      protegidos contra acesso indevido, perda, roubo ou modificação, pela adoção de
      medidas técnicas e gerenciais de controle e segurança da informação. Estes
      controles envolvem desde medidas gerenciais como política de privacidade e
      obrigações de confidencialidade, a medidas técnicas como controle de permissão
      de acessos a sistemas, auditoria de atividades, ferramentas de detecção e
      resposta a tentativas de intrusão, dentre outros. </span></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Apesar
      dessas medidas, o Grupo Ascensus não se responsabiliza pelo mau uso que seus
      clientes, prestadores, seguidores e colaboradores possam fazer de seus próprios
      dados, ou tampouco pela má-fé de terceiros, se eximindo do dever de reparar os
      danos decorrentes de incidentes de segurança ou violação de dados provocados
      por invasões, falhas ou vícios, salvo se comprovadamente ocasionadas por
      conduta culposa ou deliberada do próprio Grupo Ascensus.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">5.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">O Armazenamento dos dados pessoais</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">O armazenamento de dados
      pessoais tratados pelo Grupo Ascensus é feito em sistemas e plataformas
      voltados à gestão das atividades internas de nossas equipes. Tais sistemas tem
      acesso controlado por regras de limitação de acesso apenas aos colaboradores que
      precisam utilizá-los para execução das finalidades declaradas. </span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><a></a><a><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;">Podemos
      utilizar serviços de processamento de dados de fornecedores localizados fora do
      País</span></a><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">. Nesse caso, o Grupo Ascensus se
      certificará que eventual transferência internacional de dados atenda nossos
      requisitos de segurança e as regras de privacidade previstas na legislação
      brasileira.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">6.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Com quem são compartilhados os dados?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Os dados tratados pelo
      Grupo Ascensus são compartilhados com colaboradores de áreas internas
      responsáveis pela execução das finalidades declaradas e amparadas por lei. <span >Todos os nossos colaboradores firmam compromisso de
      confidencialidade e devem seguir nossas políticas de privacidade e de segurança
      da informação.</span> </span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;">O Grupo
      Ascensus também poderá compartilhar dados pessoais com outros prestadores de
      serviços, em especial na área de comércio internacional, a exemplo de
      Despachantes Aduaneiros e Agentes de Carga, para fins de execução do contrato
      ou procedimentos preliminares, cumprimento de obrigações legais e ou fins de interesse
      legítimo</span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">7.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Por quanto tempo meus dados pessoais são
      armazenados?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">O tratamento de dados
      pessoais ocorrerá por prazos que variam de acordo com as finalidades e hipótese
      de tratamento. O armazenamento dos dados, em regra, perdura pelo tempo
      necessário para cumprimento de obrigações legais, para execução de contratos, e
      para o eventual uso em exercício regular de direito em processos judiciais e/ou
      administrativos. </span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Se você não é cliente, não
      possui contrato de trabalho ou de prestação de serviços, e se relaciona apenas
      para fins de interação comercial e recebimento de conteúdo, seus dados serão
      tratados por tempo indeterminado, com base na expectativa de atendimento de
      interesses legítimos. Você poderá pedir o cancelamento ou interrupção do
      tratamento a qualquer momento.</span></p>

      <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
      8.0pt;margin-left:14.2pt;text-align:justify;text-indent:-14.2pt;line-height:
      150%"><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;font-family:
      'Calibri',sans-serif">8.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Como lidamos com <i>cookies</i>?</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><i><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Cookies</span></i><span lang="PT-BR" style="font-size:12.0pt;line-height:150%"> são arquivos enviados e
      instalados no navegador dos usuários. Os <i>cookies </i>permitem a
      identificação do endereço de IP, tipo de navegador, tipo de dispositivo,
      duração da visita, preferências pessoais,&nbsp; páginas visitadas, entre outras
      informações<span >. </span></span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;">Nossa
      página utiliza cookies do Google Analytics para que possamos gerar relevância
      do site em mecanismos de buscas. </span><span lang="PT-BR" style="font-size:12.0pt;
      line-height:150%">Para saber sobre essa ferramenta, acesse a Política de
      Privacidade do Google: <a href="https://policies.google.com/privacy?hl=pt#about" target="_blank"><span style="font-size:12.0pt;line-height:150%">https://policies.google.com/privacy?hl=pt#about</span></a></span></p>

      <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
      8.0pt;margin-left:14.2pt;text-indent:-14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;font-family:'Calibri',sans-serif">9.<span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp; </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;font-family:'Calibri',sans-serif">Redes Sociais (Facebook, Linkedin e Instagram)</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Oferecemos canais de
      comunicação e interação por meio das redes sociais. Neste caso, em relação ao
      tratamento dos seus dados pessoais, atuamos como controlador conjunto com o
      Facebook, Instagram, Linkedin e Twitter. Portanto, recomendamos que você leia o
      aviso de privacidade de cada uma delas para entender como tratam os seus dados
      pessoais:</span></p>
      
      <p class="MsoNormal" style="line-height:150%"><span lang="PT-BR" style="font-size:
      12.0pt;line-height:150%">Facebook – </span><span lang="PT-BR"><a href="https://www.facebook.com/policy.php" target="_blank"><span style="font-size:12.0pt;
      line-height:150%" target="_blank">https://www.facebook.com/policy.php</span></a></span></p>
      
      <p class="MsoNormal" style="line-height:150%"><span lang="PT-BR" style="font-size:
      12.0pt;line-height:150%">Linkedin –&nbsp; </span><span lang="PT-BR"><a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"><span style="font-size:
      12.0pt;line-height:150%">https://www.linkedin.com/legal/privacy-policy</span></a></span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Twitter – </span><span lang="PT-BR"><a href="https://twitter.com/pt/privacy" target="_blank"><span style="font-size:
      12.0pt;line-height:150%">https://twitter.com/pt/privacy</span></a></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></p>
      
      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%">Instagram - </span><span lang="PT-BR"><a href="https://pt-br.facebook.com/help/instagram/519522125107875" target="_blank"><span style="font-size:12.0pt;line-height:150%">https://pt-br.facebook.com/help/instagram/519522125107875</span></a></span></p>

      <p class="MsoPlainText" style="margin-left:21.3pt;text-align:justify;text-indent:
      -21.3pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">10.<span style="font:7.0pt 'Times New Roman'">&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Quais são meus direitos?</span></b></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">O
      Grupo Ascensus se compromete a atender os direitos dos titulares de dados
      pessoais. De acordo com a Lei, os titulares tem direito a:</span></p>

      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">I.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Solicitar confirma e acesso aos dados
      pessoais tratados;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">II.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Requerer a correção, complementação ou
      atualização dos dados;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">III.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Solicitar a anonimização, bloqueio ou </span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">eliminação
      de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">IV.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Pedir a portabilidade </span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">de
      seus dados pessoais a outro fornecedor de serviço ou produto, mediante
      requisição expressa, de acordo com a regulamentação da autoridade nacional,
      observados os segredos comercial e industrial;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">V.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Requerer a eliminação, a qualquer momento,
      dos dados tratados com base em consentimento do titular;</span></p>
      
      <p class="MsoPlainText" style="margin-left:2rem;text-align:justify;text-indent:
      -1rem;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:
      150%;font-family:'Calibri',sans-serif">VI.<span style="margin-right:0.5rem; font:7.0pt 'Times New Roman'">
      </span></span><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Revogar o consentimento.</span></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Caso
      queira saber mais sobre seus direitos, ou queria exercer qualquer desses
      direitos, colocamos à disposição o contato de nosso chefe de privacidade: <a href="mailto:privacidade@ascensus.com.br" target="_blank"><span >privacidade@ascensus.com.br</span></a><span class="MsoHyperlink"></span></span></p>

      <p class="MsoPlainText" style="margin-left:21.3pt;text-align:justify;text-indent:
      -21.3pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;
      line-height:150%;font-family:'Calibri',sans-serif">11.<span style="font:7.0pt 'Times New Roman'">&nbsp;
      </span></span></b><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Atualizações </span></b></p>
      
      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Informamos
      que esse Aviso de Privacidade sempre poderá passar por atualizações a visando
      refletir mudanças nas práticas de proteção de dados adotadas pelo Grupo
      Ascensus. Portanto, fica aqui nosso convite para que nossos clientes,
      parceiros, colaboradores e amigos consultem esse aviso rotineiramente. Caso
      ocorram alterações de alta relevância essas serão comunicadas por meios de
      contato direto.&nbsp; </span></p>

      <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
      8.0pt;margin-left:21.3pt;text-align:justify;text-indent:-21.3pt;line-height:
      150%"><b><span lang="pt" style="font-size:14.0pt;line-height:150%;font-family:
      'Calibri',sans-serif">12.<span style="font:7.0pt 'Times New Roman'">&nbsp; </span></span></b><b><span lang="pt" style="font-size:14.0pt;line-height:150%;font-family:'Calibri',sans-serif">Esclarecimento
      e Solicitações</span></b></p>

      <p class="MsoNormal" style="text-align:justify;line-height:150%"><span lang="pt" style="font-size:12.0pt;line-height:150%">Se entender necessário requerer algum
      tipo de esclarecimento, registrar dúvidas e ou fazer solicitações de qualquer
      natureza relacionadas ao tratamento de dados pessoais e aos termos de nosso
      aviso de privacidade, o Grupo Ascensus se coloca à disposição para contato por e-mail: <span ><a href="mailto:privacidade@ascensus.com.br" target="_blank">privacidade@ascensus.com.br</a></span></span><span lang="pt" style="font-size:12.0pt;line-height:150%">.</span></p>

      <p class="MsoPlainText" style="text-align:justify;line-height:150%"><span lang="PT-BR" style="font-size:12.0pt;line-height:150%;font-family:'Calibri',sans-serif">Atualizado em 01 de fevereiro de 2021.</span></p>`,

      titulo_quality: 'Política de qualidade',
      texto_quality: `
      <p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
      150%"><span lang="PT" >O Ascensus Portos, prestador de serviços para o segmento de veículos e/ou rolantes, tem como compromisso garantir que as atividades sejam conduzidas dentro dos requisitos aplicáveis a cada operação, priorizando a satisfação dos clientes com um time de alta performance, buscando a melhoria contínua dos serviços e sua qualidade.</span></p>

      <p class="MsoPlainText" style="margin-left:14.2pt;text-align:justify;text-indent:
      -14.2pt;line-height:150%"><b><span lang="PT-BR" style="font-size:14.0pt;line-height:150%;
      font-family:'Calibri',sans-serif">Nossos Objetivos:</span></b></p>

      <ul>
        <li>✓ Atender a Expectativa de Satisfazer nossos clientes</li>
        <li>✓ Promover time de Alta performance</li>
        <li>✓ Promover a Melhoria Contínua dos serviços e sua qualidade</li>
        <li>✓ Diversificar fontes de receita</li>
      </ul>`
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit(): void {
  }

}
