import { Component, OnInit } from '@angular/core';
declare var idiomaEscolhido: any;

@Component({
  selector: 'app-maiores-brasil',
  templateUrl: './maiores-brasil.component.html',
  styleUrls: ['./maiores-brasil.component.scss']
})
export class MaioresBrasilComponent implements OnInit {
  traducao: any = {};
  traducaoTermos = {
    br: {
      somosGrupo: 'Somos um grupo de alta performance para seus negócios.',
      mantemos: `
      Mantemos resultados financeiros sustentáveis e contamos com uma forte governança corporativa.
      Nosso modelo de distribuição possui uma característica empreendedora, a qual temos soluções
      flexíveis e escaláveis, aderentes aos clientes de diversos portes e segmentos.`,
      resultados: 'Nossos Resultados',
      trabalhamos: `
      Trabalhamos para impactar positivamente o mercado-alvo, contribuindo com a ampliação da
      produtividade e integração da cadeia de valor e acompanhando o crescimento e expansão de nossos
      CLIENTES.`,
      receita: 'Receita',
      bilhoes: 'R$ 3 bilhões - bruto/2019',
      geramos: `Geramos mais de USD$ 550 Milhões de economia aos nossos clientes nos últimos 10 anos`
    },
    en: {
      somosGrupo: 'We are a high performance group for your business.',
      mantemos: `
      We maintain sustainable financial results and have strong corporate governance.
       Our distribution model has an entrepreneurial characteristic, which we have solutions
       flexible and scalable, adhering to customers of different sizes and segments.`,
      resultados: 'Our results',
      trabalhamos: `
      We work to positively impact the target market, contributing to the expansion of
       productivity and integration of the value chain and following the growth and expansion of our
       CUSTOMERS.`,
      receita: 'Recipe',
      bilhoes: 'R $3 billion - gross / 2019',
      geramos: `We have generated over USD$ 550 million in savings for our customers over the past 10 years`
    },
    es: {
      somosGrupo: 'Somos un grupo de alto desempeño para su negocio.',
      mantemos: `
      Mantenemos resultados financieros sostenibles y tenemos un sólido gobierno corporativo.
       Nuestro modelo de distribución tiene una característica empresarial, del cual tenemos soluciones
       flexible y escalable, adhiriéndose a clientes de diferentes tamaños y segmentos.`,
      resultados: 'Nuestros resultados',
      trabalhamos: `
      Trabajamos para impactar positivamente en el mercado objetivo, contribuyendo a la expansión de
       productividad e integración de la cadena de valor y siguiendo el crecimiento y expansión de nuestra
       CLIENTES.`,
      receita: 'Receta',
      bilhoes: 'R$ 3 mil millones - brutos / 2019',
      geramos: `Hemos generado más de USD$ 550 millones de ahorros para nuestros clientes durante los últimos 10 años`
    },
    all: {
      somosGrupo: 'Somos um grupo de alta performance para seus negócios.',
      mantemos: `
      Mantemos resultados financeiros sustentáveis e contamos com uma forte governança corporativa.
      Nosso modelo de distribuição possui uma característica empreendedora, a qual temos soluções
      flexíveis e escaláveis, aderentes aos clientes de diversos portes e segmentos.`,
      resultados: 'Nossos Resultados',
      trabalhamos: `
      Trabalhamos para impactar positivamente o mercado-alvo, contribuindo com a ampliação da
      produtividade e integração da cadeia de valor e acompanhando o crescimento e expansão de nossos
      CLIENTES.`,
      receita: 'Receita',
      bilhoes: 'R$ 3 bilhões - bruto/2019',
      geramos: `Geramos mais de USD$ 550 Milhões de economia aos nossos clientes nos últimos 10 anos`
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
  }

}
