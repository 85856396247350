import { Component, OnInit } from '@angular/core';
import { CountUp } from 'countup.js';

// tslint:disable-next-line:one-variable-per-declaration
declare var $, idiomaEscolhido: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  animate: boolean = false;
  traducao: any = {};
  traducaoTermos = {
    br: {
      conheca: 'CONHEÇA A ASCENSUS',
      imgEngranagem: 'assets/images/banner/engrenagem-2.jpg'
    },
    en: {
      conheca: 'MEET ASCENSUS',
      imgEngranagem: 'assets/images/banner/engrenagem-2-en.jpg'
    },
    es: {
      conheca: 'CONOZCA ASCENSUS',
      imgEngranagem: 'assets/images/banner/engrenagem-2-es.jpg'
    },
    all: {
      conheca: 'CONHEÇA A ASCENSUS',
      imgEngranagem: 'assets/images/banner/engrenagem-2.jpg'
    }
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  // ngOnInit(): void {
  //   $("html, body").animate({ scrollTop: 0 }, "slow");

  //   this.owlCarousel();

  //   $.getScript("https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit", function () {
  //     setTimeout(() => {
  //       const idiomaAtualG = $('html').attr('lang');
  //       setInterval(() => {
  //         const idiomaNovoG = $('html').attr('lang');
  //         if (idiomaAtualG != idiomaNovoG && idiomaEscolhido != 'en') {
  //           localStorage.setItem('idioma', 'en');
  //         }
  //       }, 1000);
  //     }, 1500);
  //   });
  // }

  onInViewportChange(ev) {
    const countUp = new CountUp('countUp', 91);
    if (!countUp.error) {
      countUp.start();
    } else {
      console.error(countUp.error);
    }

    const countUp2 = new CountUp('countUp2', 79);
    if (!countUp2.error) {
      countUp2.start();
    } else {
      console.error(countUp2.error);
    }

    const countUp3 = new CountUp('countUp3', 78);
    if (!countUp3.error) {
      countUp3.start();
    } else {
      console.error(countUp3.error);
    }



  }

  owlCarousel() {
    $('.owl-importacao').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        }
      }
    });
  }

  abrirDetalhe() {
    $('#conheca-ascensus').toggleClass('active-rotate');
    $('.acordeon').animate({height: 'toggle'});
    this.scroll('#conheca-ascensus');
  }

  fecharDetalhe() {
    $('#conheca-ascensus').removeClass('active-rotate');
    $('.acordeon').slideUp();
    this.scroll('#conheca-ascensus');
  }

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }


}
