import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-real-state',
  templateUrl: './real-state.component.html',
  styleUrls: ['./real-state.component.scss']
})
export class RealStateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  abrirDetalhe() {
    $('.acordeon-real').slideDown();
    this.scroll('#real-state');
  }

  fecharDetalhe() {
    $('.acordeon-real').slideUp();
    this.scroll('#real-state');
  }

  scroll(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 117
    }, 500);
  }
}
