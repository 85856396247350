import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  traducao: any = {};
  idioma = idiomaEscolhido;
  traducaoTermos = {
    br: {
      videoinstitucional: 'VÍDEO INSTITUCIONAL'
    },
    en: {
      videoinstitucional: 'INSTITUTIONAL VIDEO'
    },
    es: {
      videoinstitucional: 'VIDEO INSTITUCIONAL'
    },
    all: {
      videoinstitucional: 'VIDEO INSTITUCIONAL'
    }
  };
  idioma_video = 'br';

  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
    if(this.idioma=='pt-br'){
      this.idioma_video = 'br';
    }else if(this.idioma=='es'){
      this.idioma_video = 'es';
    }else{
      this.idioma_video = 'en';
    }
  }

  ngOnInit() {
  }

}
