import { Component, OnInit, ElementRef } from '@angular/core';
declare var $, idiomaEscolhido: any;

@Component({
  selector: 'app-unidades',
  templateUrl: './unidades.component.html',
  styleUrls: ['./unidades.component.scss']
})
export class UnidadesComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      unidades: `UNIDADES`,
      estrategia: 'Estamos estrategicamente nas principais capitais do Brasil.',
      temos: `Temos 10 mil m² de sede e 400 mil m² agregados em todo país. Santa Catarina, 
              São Paulo, Paraná, Pernambuco, Espírito Santo, Rio de Janeiro, Rio Grande do Sul, Rondônia, EUA e Europa.`,
      sede: 'Sede <h4>Joinville/SC +55 (47) 3025-4050</h4>',
      matriz: 'Nossa Matriz',
      local: `Localizada na zona industrial de Joinville (SC) conta com 10.000 m²
              de área construída e agrega algumas das principais atividades do nosso Grupo.`,
      novamatriz: 'Nova Matriz',
      investimento: 'Investimento de R$ 100 milhões.',
      perspectivas: `Devido às nossas perspectivas para o futuro, estamos projetando em Joinville/SC,
                     uma nova casa para o grupo, com 25.000 m².`,
      filial: 'Nova filial Miami',
      filiais: 'Filiais'
    },
    en: {
      unidades: `UNITS`,
      estrategia: 'We are strategically located in the main capitals of Brazil.',
      temos: `We have 10 thousand m² of headquarters and 400 thousand m² aggregated throughout the country. Santa Catarina,
      São Paulo, Paraná, Pernambuco, Espírito Santo, Rio de Janeiro, Rio Grande do Sul, Rondônia, USA and Europe.`,
      sede: 'Head office <h4>Joinville/SC +55 (47) 3025-4050</h4>',
      matriz: 'Our Matrix',
      local: `Located in the industrial area of Joinville (SC), it has 10,000 m²
      of built area and aggregates some of the main activities of our Group.`,
      novamatriz: 'New Matrix',
      investimento: 'Investment of R $ 100 million.',
      perspectivas: `Due to our perspectives for the future, we are projecting in Joinville / SC,
      a new house for the group, with 25,000 m².`,
      filial: ' New Miami Branch',
      filiais: 'Affiliates'
    },
    es: {
      unidades: `UNIDADES`,
      estrategia: 'Estamos ubicados estratégicamente en las principales capitales de Brasil.',
      temos: `Contamos con 10 mil m² de sede y 400 mil m² agregados en todo el país. Santa Catarina,
      São Paulo, Paraná, Pernambuco, Espírito Santo, Rio de Janeiro, Rio Grande do Sul, Rondônia, EUA y Europa.`,
      sede: 'Sed <h4>Joinville/SC +55 (47) 3025-4050</h4>',
      matriz: 'Nuestra matriz',
      local: `Ubicado en el polígono industrial de Joinville (SC), cuenta con 10.000 m²
      de área construida y agrega algunas de las principales actividades de nuestro Grupo.`,
      novamatriz: 'Nueva matriz',
      investimento: 'Inversión de R $ 100 millones.',
      perspectivas: `Debido a nuestras perspectivas de futuro, estamos proyectando en Joinville / SC,
      una casa nueva para el grupo, con 25.000 m².`,
      filial: 'Nueva sucursal de Miami',
      filiais: 'Afiliados'
    },
    all: {
      unidades: `UNIDADES`,
      estrategia: 'Estamos estrategicamente nas principais capitais do Brasil.',
      temos: `Temos 10 mil m² de sede e 400 mil m² agregados em todo país. Santa Catarina, 
              São Paulo, Paraná, Pernambuco, Espírito Santo, Rio de Janeiro, Rio Grande do Sul, Rondônia, EUA e Europa.`,
      sede: 'Sede <h4>Joinville/SC +55 (47) 3025-4050</h4>',
      matriz: 'Nossa Matriz',
      local: `Localizada na zona industrial de Joinville (SC) conta com 10.000 m²
              de área construída e agrega algumas das principais atividades do nosso Grupo.`,
      novamatriz: 'Nova Matriz',
      investimento: 'Investimento de R$ 100 milhões.',
      perspectivas: `Devido às nossas perspectivas para o futuro, estamos projetando em Joinville/SC,
                     uma nova casa para o grupo, com 25.000 m².`,
      filial: 'Nova filial Miami',
      filiais: 'Filiais'
    },
  };
  constructor(private elementRef: ElementRef) {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {

    $(this.elementRef.nativeElement).find('.scrollbar-external').scrollbar({
      "autoScrollSize": false,
      "scrollx": $(this.elementRef.nativeElement).find('.external-scroll_x'),
      "scrolly": $(this.elementRef.nativeElement).find('.external-scroll_y')
    });
  }
}
