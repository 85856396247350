import { Component, OnInit } from '@angular/core';
declare var  idiomaEscolhido: any;

@Component({
  selector: 'app-filiais',
  templateUrl: './filiais.component.html',
  styleUrls: ['./filiais.component.scss']
})
export class FiliaisComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      temos10mil: `Temos 10 mil m² de sede e 400 mil m² agregados em todo país. <br>
      Estamos estrategicamente localizados em Santa Catarina, São Paulo, Paraná, Pernambuco, 
      Espírito Santo, Rio de Janeiro, Rio Grande do Sul, Rondônia, EUA e Europa.`,
      sede: 'Sede', filiais: 'Filiais'
    },
    en: {
      temos10mil: `We have 10 thousand m² of headquarters and 400 thousand m² aggregated throughout the country.<br>
      We are strategically located in Santa Catarina, São Paulo, Paraná, Pernambuco,
       Espírito Santo, Rio de Janeiro, Rio Grande do Sul, Rondônia, USA and Europe.`,
      sede: 'Head office', filiais: 'Affiliates'
    },
    es: {
      temos10mil: `Tenemos 10 mil m² de sede y 400 mil m² agregados en todo el país. <br>
      Estamos ubicados estratégicamente en Santa Catarina, São Paulo, Paraná, Pernambuco,
       Espírito Santo, Rio de Janeiro, Rio Grande do Sul, Rondônia, Estados Unidos y Europa.`,
      sede: 'Sed', filiais: 'Afiliados'
    },
    all: {
      temos10mil: `Temos 10 mil m² de sede e 400 mil m² agregados em todo país. <br>
      Estamos estrategicamente localizados em Santa Catarina, São Paulo, Paraná, Pernambuco, 
      Espírito Santo, Rio de Janeiro, Rio Grande do Sul, Rondônia, EUA e Europa.`,
      sede: 'Sede', filiais: 'Filiais'
    },
  };
  constructor() {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {
  }

}
