import { Component, OnInit, ElementRef } from '@angular/core';
declare var $: any;
declare var idiomaEscolhido: any;
@Component({
  selector: 'app-segmentos',
  templateUrl: './segmentos.component.html',
  styleUrls: ['./segmentos.component.scss']
})
export class SegmentosComponent implements OnInit {

  traducao: any = {};
  traducaoTermos = {
    br: {
      segmentos: 'SEGMENTOS',
      estamosPresente: 'Estamos presentes <br>no seu dia a dia',
      oferecemos: `
      Oferecemos uma infraestrutura interconectada a modais de transporte, que proporciona facilidades
      logísticas integradas de acordo com normas globais. Somos especializados em logística multimodal
      e viabilizamos a distribuição rápida e eficaz de mercadorias em todo o território nacional.
      Otimizamos tempo e rentabilizamos os processos. <br><br><br>

      O planejamento tributário é realizado de forma exclusiva, com o intuito de identificar a melhor
      solução para cada cliente e produto. Com a ousadia do Ascensus Group, nossos clientes reconhecem
      o que entregamos, ao criar relações de sucesso, com atitude e inteligência. Atendemos empresas
      globais de diversos segmentos, como:
      `,
      tabela: `
      <table class="lista-servicos">
        <tr>
            <td>
                <!-- <div class="icons bebida"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-alimentos-e-bebidas.png">
            </td>
            <td>Alimentos <br> e bebidas</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons predio"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-infraestrutura-e-construcao-civil.png">
            </td>
            <td>Infraestrutura <br> e construção civil</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons barco"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-nauticos-aeronauticos-e-automotivos.png">
            </td>
            <td>Náuticos, <br> aeronáuticos <br> e automotivos</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons mecanico"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-siderurgicos-metalurgia-e-maquinario.png">
            </td>
            <td>Siderurgicos, <br> metalurgia <br> e maquinário</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-eletroeletronicos-e-tecnologia.png">
            </td>
            <td>Eletroeletrônica <br> e tecnologia</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-plasticos.png">
            </td>
            <td>Plásticos</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-oleo-e-gas.png">
            </td>
            <td>Óleo e Gás</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%" src="assets/images/ascensus-segmentos-energia.png">
            </td>
            <td>Energia</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%" src="assets/images/ascensus-segmentos-saude.png">
            </td>
            <td>Saúde</td>
        </tr>

      </table>
      `
    },
    en: {
      segmentos: 'SEGMENTS',
      estamosPresente: 'We are present in your daily life',
      oferecemos: `
      We offer an infrastructure interconnected to transport modes, which provides facilities
       integrated logistics according to global standards. We are specialized in multimodal logistics
       and we enable the fast and efficient distribution of goods throughout the national territory.
       We optimize time and make processes profitable. <br><br><br>

       Tax planning is carried out exclusively, in order to identify the best
       solution for each customer and product. With the boldness of the Ascensus Group, our customers recognize
       what we deliver by creating successful relationships with attitude and intelligence. We serve companies
       various segments, such as:
      `,
      tabela: `
      <table class="lista-servicos">
        <tr>
            <td>
                <!-- <div class="icons bebida"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-alimentos-e-bebidas.png">
            </td>
            <td>Foods <br> and drinks</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons predio"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-infraestrutura-e-construcao-civil.png">
            </td>
            <td>Infrastructure <br> and civil construction</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons barco"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-nauticos-aeronauticos-e-automotivos.png">
            </td>
            <td>Nautical, <br> aeronautical <br> and automotive</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons mecanico"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-siderurgicos-metalurgia-e-maquinario.png">
            </td>
            <td>Steelmakers, <br> metallurgy <br>and machinery</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-eletroeletronicos-e-tecnologia.png">
            </td>
            <td>Electronics <br> and technology</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-plasticos.png">
            </td>
            <td>Plastics</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-oleo-e-gas.png">
            </td>
            <td>Oil and gas</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%" src="assets/images/ascensus-segmentos-energia.png">
            </td>
            <td>Energy</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%" src="assets/images/ascensus-segmentos-saude.png">
            </td>
            <td>Cheers</td>
        </tr>

      </table>
      `
    },
    es: {
      segmentos: 'SEGMENTOS',
      estamosPresente: 'Estamos presentes <br>en tu dia a dia',
      oferecemos: `
      Ofrecemos una infraestructura interconectada a modos de transporte, que brinda facilidades
       Logística integrada de acuerdo a estándares globales. Estamos especializados en logística multimodal
       y posibilitamos la distribución rápida y eficiente de mercancías en todo el territorio nacional.
       Optimizamos el tiempo y rentabilizamos los procesos.<br><br><br>

       La planificación fiscal se realiza exclusivamente, con el fin de identificar los mejores
       solución para cada cliente y producto. Con la audacia de Ascensus Group, nuestros clientes reconocen
       lo que entregamos al crear relaciones exitosas con actitud e inteligencia. Atendemos empresas
       varios segmentos, tales como:
      `,
      tabela: `
      <table class="lista-servicos">
        <tr>
            <td>
                <!-- <div class="icons bebida"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-alimentos-e-bebidas.png">
            </td>
            <td>Alimentos <br>y bebidas</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons predio"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-infraestrutura-e-construcao-civil.png">
            </td>
            <td>Infraestructura <br> y construccion civil</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons barco"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-nauticos-aeronauticos-e-automotivos.png">
            </td>
            <td>Náutico, <br> aeronáutico <br> y automotriz</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons mecanico"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-siderurgicos-metalurgia-e-maquinario.png">
            </td>
            <td>Acerías, <br> metalurgia <br> y maquinaria</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-eletroeletronicos-e-tecnologia.png">
            </td>
            <td>Electrónica <br>y Tecnología</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-plasticos.png">
            </td>
            <td>Plástica</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-oleo-e-gas.png">
            </td>
            <td>Petróleo y gas</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%" src="assets/images/ascensus-segmentos-energia.png">
            </td>
            <td>Energía</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%" src="assets/images/ascensus-segmentos-saude.png">
            </td>
            <td>Salud</td>
        </tr>

      </table>
      `
    },
    all: {
      segmentos: 'SEGMENTOS',
      estamosPresente: 'Estamos presentes <br>no seu dia a dia',
      oferecemos: `
      Oferecemos uma infraestrutura interconectada a modais de transporte, que proporciona facilidades
      logísticas integradas de acordo com normas globais. Somos especializados em logística multimodal
      e viabilizamos a distribuição rápida e eficaz de mercadorias em todo o território nacional.
      Otimizamos tempo e rentabilizamos os processos. <br><br><br>

      O planejamento tributário é realizado de forma exclusiva, com o intuito de identificar a melhor
      solução para cada cliente e produto. Com a ousadia do Ascensus Group, nossos clientes reconhecem
      o que entregamos, ao criar relações de sucesso, com atitude e inteligência. Atendemos empresas
      globais de diversos segmentos, como:
      `,
      tabela: `
      <table class="lista-servicos">
        <tr>
            <td>
                <!-- <div class="icons bebida"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-alimentos-e-bebidas.png">
            </td>
            <td>Alimentos <br> e bebidas</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons predio"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-infraestrutura-e-construcao-civil.png">
            </td>
            <td>Infraestrutura <br> e construção civil</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons barco"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-nauticos-aeronauticos-e-automotivos.png">
            </td>
            <td>Náuticos, <br> aeronáuticos <br> e automotivos</td>
        </tr>
        <tr>
            <td>
                <!-- <div class="icons mecanico"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-siderurgicos-metalurgia-e-maquinario.png">
            </td>
            <td>Siderurgicos, <br> metalurgia <br> e maquinário</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-eletroeletronicos-e-tecnologia.png">
            </td>
            <td>Eletroeletrônica <br> e tecnologia</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-plasticos.png">
            </td>
            <td>Plásticos</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%"
                    src="assets/images/ascensus-segmentos-oleo-e-gas.png">
            </td>
            <td>Óleo e Gás</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%" src="assets/images/ascensus-segmentos-energia.png">
            </td>
            <td>Energia</td>
        </tr>

        <tr>
            <td>
                <!-- <div class="icons eletronicos"></div> -->
                <img style="width: 100%" src="assets/images/ascensus-segmentos-saude.png">
            </td>
            <td>Saúde</td>
        </tr>

      </table>
      `
    },
  };

  constructor(private elementRef: ElementRef) {
    if(idiomaEscolhido=='br' || idiomaEscolhido=='en' || idiomaEscolhido=='es'){
      this.traducao = this.traducaoTermos[idiomaEscolhido];
    }else{
      this.traducao = this.traducaoTermos['all'];
    }
  }

  ngOnInit() {

    $(this.elementRef.nativeElement).find('.scrollbar-external').scrollbar({
      "autoScrollSize": false,
      "scrollx": $(this.elementRef.nativeElement).find('.external-scroll_x'),
      "scrolly": $(this.elementRef.nativeElement).find('.external-scroll_y')
    });
  }

}
